import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const SQUAD_BASE_PATH = "/api/squad";
export const useSquadStore = defineStore("squad", {
  state: () => {
    return {
      squadData: {},
    };

  },
  actions: {
    async loadSquadsByClubId(clubId) {
      const squadData = await ApiClient.getRequest(SQUAD_BASE_PATH + '/club?clubId=' + clubId);
      if (squadData?.data) {
        this.squadData.squads = squadData.data;
        this.squadData.clubId = clubId;
      }
    },
    async saveOrUpdateSquadData(payload) {
      if (payload.No) {
        return await ApiClient.putRequest(SQUAD_BASE_PATH + '/club', payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(SQUAD_BASE_PATH + '/club', payload);
      }
      return null;
    },
    async deleteSquad(clubNo, squadNo) {
      return await ApiClient.deleteRequest(SQUAD_BASE_PATH + '/club?clubNo=' + clubNo + '&squadNo=' + squadNo);
    },
  },
  getters: {
    getSquadData: (state) => {
      return state.squadData;
    }
  },
  persist: {
    storage: sessionStorage,
  }

});
