<template>
  <div class="modal modal-xl fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          {{ $t('club.functionsAndActivities.activity.titleEdit') }}
        </div>

        <div class="modal-body">
          <div class="row" :hidden="!props.createNew">
            <div class="col-6">
              <label for="contactNo" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.contactNo') }}</label>
              <Select input-id="contactNo" v-model="contactNo"
                      appendTo="self"
                      :options="clubMemberList"
                      option-value="memberId"
                      :option-label="getMemberName"
                      filter
                      :placeholder="$t('institutes.functions.selectMember')"
                      class="w-100"/>
            </div>
            <div class="col-6">
              <label for="code" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.activityName') }}</label>
              <Select input-id="code" v-model="activityCode"
                      appendTo="self"
                      :options="clubActivities"
                      option-value="code"
                      option-label="description"
                      filter
                      :placeholder="$t('club.functionsAndActivities.activity.selectActivity')"
                      class="w-100"/>
            </div>
          </div>
          <div class="row">
            <div class="col-6" >
              <label for="firstYear" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.yearStart') }}</label>
              <DatePicker id="firstYear"  :showButtonBar="true" v-model="firstYear" class="form-control no-border" inline
                          :baseZIndex="9999" :minDate="mindate"  view="year" showIcon dateFormat="yy"></DatePicker>
            </div>
            <div class="col-6" >
              <label for="lastYear" class="form-label mt-2">{{ $t('club.functionsAndActivities.activity.yearEnd') }}</label>
              <DatePicker id="lastYear"  :showButtonBar="true" v-model="lastYear" class="form-control no-border" inline
                          :baseZIndex="9999" :minDate="minLastYear" view="year" showIcon dateFormat="yy"></DatePicker>
            </div>
          </div>

        </div>
        <div class="modal-footer d-flex justify-content-between">
          <DefaultButton  class="btn mt-3 btn-outline-primary" :disabled="props.createNew || firstYear === null" :hidden="props.createNew" @click="saveChanges"
                          :label="$t('club.functionsAndActivities.activity.save')" />
          <DefaultButton  class="btn mt-3 btn-outline-primary" :disabled="!requiredCreateFieldsFilled" :hidden="!props.createNew" @click="createNewMemberActivity"
                          :label="$t('club.functionsAndActivities.activity.create')" />
          <DefaultButton id="member-activity-modal-cancel-button" class="btn mt-3 btn-outline-secondary " :label="$t('cancel')" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref, watch} from "vue";
import DatePicker from "primevue/datepicker";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import ApiClient from "@/api/ApiClient";
import Message from "@/helpers/message";
import {useMessagesStore} from "@/store/modules/messages/messages";
import {useClubStore} from "@/store/modules/club/club";
import {useI18n} from "vue-i18n";
import Select from "primevue/select";

const mindate = new Date();
mindate.setFullYear(1950, 0, 1);

const firstYear = ref();
const lastYear = ref();
const contactNo = ref();
const activityCode = ref();

const props = defineProps(['activity', 'createNew', 'clubId']);

const clubStore = useClubStore();

const clubMemberList = computed(() => {
  return clubStore.getClubMembers;
});

const getMemberName = (member) => {
  return member.firstName + ' ' + member.surname;
};

const clubActivities = ref([]);


async function reloadClubActivities() {
  await loadClubActivities(props.clubId);
}

async function loadClubActivities(clubId) {
  const request = "/api/activity/club?clubId=" + clubId;

  const response = await ApiClient.getRequest(request);

  if(response && response.status === 200) {
    clubActivities.value = response.data;
  }
}

onMounted(() => {
  reloadClubActivities();
  if(props.activity) {
    if(props.activity.firstYear === 0) { // in Navision wird kein Wert als 0 gespeichert.
      firstYear.value = null;
    } else {
      const newYear = new Date();
      newYear.setFullYear(props.activity.firstYear, 0, 1);
      firstYear.value = newYear;
    }
    if(props.activity.lastYear === 0) {
      lastYear.value = null;
    } else {
      const newYear = new Date();
      newYear.setFullYear(props.activity.lastYear, 0, 1);
      lastYear.value = newYear;
    }
  }
})

watch(
  props, () => {
    contactNo.value = null;
    activityCode.value = null;
    if(props.activity) {
      if(props.activity.firstYear === 0) { // in Navision wird kein Wert als 0 gespeichert.
        firstYear.value = null;
      } else {
        const newYear = new Date();
        newYear.setFullYear(props.activity.firstYear, 0, 1);
        firstYear.value = newYear;
      }
      if(props.activity.lastYear === 0) {
        lastYear.value = null;
      } else {
        const newYear = new Date();
        newYear.setFullYear(props.activity.lastYear, 0, 1);
        lastYear.value = newYear;
      }
    }
  }
)

const emit = defineEmits(['memberActivitiesChanged']);
const messagesStore = useMessagesStore();
const {t} = useI18n();

const minLastYear = computed(() => {
  if(firstYear.value === null) {
    return mindate.value;
  }
  return firstYear.value;
})

const requiredCreateFieldsFilled = computed(() => {
  return contactNo.value !== null && activityCode.value !== null && firstYear.value !== null;
})

async function createNewMemberActivity() {
  if(!props.activity || !props.createNew || firstYear.value === null || activityCode.value === null || contactNo.value === null ) {
    return;
  }

  const requestPath = "/api/activity/member";


  const firstYearInt = firstYear.value.getFullYear();

  let lastYearInt = 0;
  if(lastYear.value !== null) {
    lastYearInt = lastYear.value.getFullYear();
  }

  const requestBody = {
    contactNo: contactNo.value,
    code: activityCode.value,
    firstYear: firstYearInt,
    clubNo: props.clubId,
    lastYear: lastYearInt
  }

  const response = await ApiClient.postRequestWithAuthorization(requestPath, requestBody);

  if(response.status && response.status === 201) {
    messagesStore.addInfo(new Message('info', true, false, t('club.functionsAndActivities.activity.successCreatingMembershipActivityHeader'), "",
      t('club.functionsAndActivities.activity.successCreatingMembershipActivity')));
    document.getElementById('member-activity-modal-cancel-button').click();
    emit('memberActivitiesChanged');
  } else if(response.status && response.status >= 400 && response.data) {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.errorCreatingMembershipActivityHeader'), "",
      response.data));
  } else {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.errorCreatingMembershipActivityHeader'), "", t('errorMessages.generic_error')));
  }

}

async function saveChanges() {
  if(!props.activity || props.createNew) {
    return;
  }
  const requestPath = "/api/activity/member";

  let firstYearInt = 0;
  let lastYearInt = 0;

  if(firstYear.value !== null) {
    firstYearInt = firstYear.value.getFullYear();
  }

  if(lastYear.value !== null) {
    lastYearInt = lastYear.value.getFullYear();
  }

  const requestBody = {
    vvaId: props.activity.vvaId,
    firstYear: firstYearInt,
    lastYear: lastYearInt,
    odataEtag: props.activity.odataEtag
  }

  const response = await ApiClient.putRequest(requestPath, requestBody);

  if(response.status && response.status === 200) {
    messagesStore.addInfo(new Message('info', true, false, t('club.functionsAndActivities.activity.successEditingHeader'), "",
      t('club.functionsAndActivities.activity.successEditing')));
    document.getElementById('member-activity-modal-cancel-button').click();
    emit('memberActivitiesChanged');
  } else if(response.status && response.status === 404) {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.editNotFoundHeader'), "",
      t('club.functionsAndActivities.activity.editNotFound')));
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "",
      t('club.functionsAndActivities.activity.other_error_message')));
  }

}

</script>

<style scoped>
/* Versteckt den Datepicker Titel auf Wunsch STV */
.p-datepicker :deep(.p-datepicker-title)
{
  display: none;
}

.no-border {
  border: none !important;
  padding: 0;
}

</style>
