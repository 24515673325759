import Keycloak from "keycloak-js";
import store from "@/store/index.js";
import logger from "@/logger/index.js";
import Message from "@/helpers/message";
import {Buffer} from 'buffer';
import i18n from "@/i18n.js";

import {useLoginStore} from "@/store/modules/login/login.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";

const kc_token = "kc_token";
const kc_refresh_token = "kc_refresh_token";
const kc_id_token = "kc_id_token";

const initConfig = {
  url: window.VUE_APP_KEYCLOAK_URL,
  realm: window.VUE_APP_KEYCLOAK_REALM,
  clientId: window.VUE_APP_KEYCLOAK_CLIENT_ID
}

// eslint-disable-next-line no-unused-vars
const {global: {locale, t},} = i18n;

let keycloak = new Keycloak(initConfig);

keycloak.onTokenExpired = function () {
  logger.debug("Token expired...");
  useLoginStore().resetSession()
  useMessagesStore().addWarning(new Message('warning', true, true, t("errorMessages.invalid_session_header"), '', t("errorMessages.invalid_session_text"), true, 'KEYCLOAK'))
}

keycloak.onAuthRefreshError = function () {
  logger.error("Error while trying to refresh Keycloak auth");
  useLoginStore().resetSession()
  useMessagesStore().addWarning(new Message('warning', true, true, t("errorMessages.invalid_session_header"), '', t("errorMessages.invalid_session_text"), true, 'KEYCLOAK'))
}

function decodeToken(str) {
  str = str.split('.')[1];

  str = str.replace(/-/g, '+');
  str = str.replace(/_/g, '/');
  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += '==';
      break;
    case 3:
      str += '=';
      break;
    default:
      throw 'Invalid token';
  }
  str = decodeURIComponent(encodeURI(Buffer.from(str, 'base64')));
  str = JSON.parse(str);
  return str;
}

export default async () => {
  return new Promise((resolve) => {
    const loginStore = useLoginStore()

    loginStore.setClientInitialized(false)
    loginStore.setKeycloak(keycloak)
    keycloak.init({token: localStorage.getItem(kc_token), refreshToken: localStorage.getItem(kc_refresh_token), idToken: localStorage.getItem(kc_id_token), checkLoginIframe: false,}).then(async (auth) => {
      let username = null;
      if (keycloak.token) {
        username = decodeToken(keycloak.token).preferred_username;
      }

      if (auth) {
        logger.debug("Keycloak Client initialization successfully for user '" + username + "'");
        loginStore.setData(keycloak.token, keycloak.refreshToken, keycloak.idToken, username)
        loginStore.initRefresh()
        logger.debug("auth successful");
      } else if (keycloak.token === null) {
        logger.debug("Keycloak Client initialization failed for user '" + username + "'");
        if (store.loggedIn) {
          loginStore.resetSession()
        }
        logger.debug("auth failed");
      }
    }).catch(() => {
      logger.debug("Keycloak client initialization failed for unknown reasons");
      loginStore.resetSession()
      if (store.loggedIn) {
        useMessagesStore().addWarning(new Message('warning', true, true, t("errorMessages.invalid_session_header"), '', t("errorMessages.invalid_session_text"), true, 'KEYCLOAK'))
      }
    })

    resolve()
  })

}
