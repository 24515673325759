<template>
  <club-layout :is-loading="false" :title="clubData.status === 200 ? $t('club.squads.title') + ' bearbeiten' : $t('club.notFound.title')">
    <template #content v-if="clubData.status === 200">
      <h6 class="mb-4 mx-5">{{ $t('club.squads.title') + ': ' + clubData.data.Name }} </h6>
      <div class="row">
        <div class="col">

          <DataTable :value="squadData.squads"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     data-key="No"
                     v-model:filters="filters" filter-display="row"
                     ref="dt" :export-filename="$t('institutes.list.title').replaceAll(' ', '_')"
                     row-hover
                     :paginator="squadData.squads.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                     class="border-bottom">
            <template #empty>
              <div class="alert alert-info">{{ $t('club.squads.list.notFound') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column>
              <template #body="{data}">
                <div class="btn-group btn-group-sm" v-if="data.Club_No">
                  <Button :title="$t('club.squads.edit', {groupName: data.Description})" unstyled class="btn btn-outline-dark" @click="openEditSquadModal(data)">
                    <i class="bi bi-pencil"></i>
                  </Button>
                  <Button :title="$t('club.squads.delete.title', {groupName: data.Description})" unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data)">
                    <i class="bi bi-trash"></i>
                  </Button>
                </div>
              </template>
            </Column>

            <Column v-for="col in columns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                {{ data[col.field] }}
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>
      <div class="row w-100 flex-center mb-3 mt-3">
        <Button title="Export" label="Export" @click="exportCSV($event)" class="datatable-export me-1 width-auto" severity="secondary" outlined>
          <i class="bi bi-filetype-csv"></i>
        </Button>
        <Button :title="$t('club.squads.add')" class="me-1 width-auto" severity="secondary" outlined @click="openEditSquadModal">
          <i class="bi bi-plus-circle"></i>
        </Button>
      </div>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>

  </club-layout>

  <Dialog v-model:visible="showSquadModal" modal block-scroll :header="selectedSquad.No ? $t('club.squads.edit', {squadName: selectedSquad.Description}) : $t('club.squads.add')">
    <div class="form-row mb-3" v-if="selectedSquad.No">
      <label class="form-label" for="squad-no">{{ $t('club.squads.no') }}</label>
      <InputText input-id="squad-no" class="w-100" v-model="selectedSquad.No" disabled/>
    </div>
    <div class="form-row mb-3">
      <label class="form-label" for="squad-name-de">{{ $t('club.squads.description') + ' ' + $t('languages.de') }}</label>
      <InputText input-id="squad-name-de" class="w-100" v-model="selectedSquad.Description" @update:modelValue="analyzeSquadData" :invalid="!squadDataValid.Description" :placeholder="$t('club.squads.description') + ' ' + $t('languages.de')"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-2" v-show="!squadDataValid.Description">{{ $t('validation.emptyOrExceeded', {field: $t('club.squads.description') + ' ' + $t('languages.de'), maxlength: 30}) }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label class="form-label" for="squad-name-fr">{{ $t('club.squads.description') + ' ' + $t('languages.fr') }}</label>
      <InputText input-id="squad-name-de" class="w-100" v-model="selectedSquad.DescriptionFR" @update:modelValue="analyzeSquadData" :invalid="!squadDataValid.DescriptionFR" :placeholder="$t('club.squads.description') + ' ' + $t('languages.fr')"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-2" v-show="!squadDataValid.DescriptionFR">{{ $t('validation.nameExceeded', {field: $t('club.squads.description') + ' ' + $t('languages.fr'), maxlength: 30}) }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label class="form-label" for="squad-name-it">{{ $t('club.squads.description') + ' ' + $t('languages.it') }}</label>
      <InputText input-id="squad-name-de" class="w-100" v-model="selectedSquad.DescriptionIT" @update:modelValue="analyzeSquadData" :invalid="!squadDataValid.DescriptionIT" :placeholder="$t('club.squads.description') + ' ' + $t('languages.it')"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-2" v-show="!squadDataValid.DescriptionIT">{{ $t('validation.nameExceeded', {field: $t('club.squads.description') + ' ' + $t('languages.it'), maxlength: 30}) }}</PrimeMessage>
    </div>
    <div class="d-flex justify-content-between">
      <Button :label="$t('save')" unstyled class="btn btn-outline-primary" @click="saveSquadChanges" :disabled="!allTrue(squadDataValid) || isSubmitted"/>
      <Button :label="$t('cancel')" unstyled class="btn btn-outline-dark" @click="showSquadModal = false"/>
    </div>
  </Dialog>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>


<script setup>
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import InputText from "primevue/inputtext";
import PrimeMessage from "primevue/message";
import Button from "primevue/button";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import ClubLayout from "@/components/club/ClubLayout";
import {useSquadStore} from "@/store/modules/squad/squad.js";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import {useClubStore} from "@/store/modules/club/club";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {FilterMatchMode} from "@primevue/core/api";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import {useConfirm} from "primevue/useconfirm";

const {t} = useI18n();

const clubStore = useClubStore();
const clubData = computed(() => clubStore.getClubData);

const squadStore = useSquadStore();
const squadData = computed(() => squadStore.getSquadData);

const messageStore = useMessagesStore();
const confirm = useConfirm();

const sortColumn = ref('No');
const dt = ref();
const onSort = (event) => {
  return sortColumn.value = event.sortField;
};

const isSubmitted = ref(false);
const showSuccess = ref(false);
const showFailure = ref(false);

const selectedSquad = ref({});
const squadDataValid = ref({
  Description: false,
  DescriptionFR: true,
  DescriptionIT: true,
});
const showSquadModal = ref(false);

const columns = [
  {field: 'No', header: t('club.squads.no'), sortable: true, filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'Description', header: t('club.squads.description') + ' ' + t('languages.de'), sortable: true, filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'DescriptionFR', header: t('club.squads.description') + ' ' + t('languages.fr'), sortable: true, filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'DescriptionIT', header: t('club.squads.description') + ' ' + t('languages.it'), sortable: true, filterable: true, exportable: true, visible: true, type: 'String'},
];

const filters = ref({
  Description: {value: null, matchMode: FilterMatchMode.CONTAINS},
  DescriptionFR: {value: null, matchMode: FilterMatchMode.CONTAINS},
  DescriptionIT: {value: null, matchMode: FilterMatchMode.CONTAINS},
});

const exportCSV = () => {
  dt.value.exportCSV();
}

const openEditSquadModal = (squad) => {
  if (squad) {
    selectedSquad.value = JSON.parse(JSON.stringify(squad));
  }
  analyzeSquadData();
  showSquadModal.value = true;
}

const analyzeSquadData = () => {
  checkProperties(selectedSquad);
  isSubmitted.value = false;
}

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Description') {
      squadDataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 30);
    } else if (key === 'DescriptionFR') {
      squadDataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], 30);
    } else if (key === 'DescriptionIT') {
      squadDataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], 30);
    }
  }
}

const saveSquadChanges = () => {
  if (allTrue(squadDataValid.value)) {
    isSubmitted.value = true;
    selectedSquad.value.Club_No = clubData.value.data.No;
    squadStore.saveOrUpdateSquadData(selectedSquad.value).then(async response => {
      if (response.status >= 200 && response.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('save', {object: t('club.squads.title')}), '', t('club.squads.save.success'), false, 'COMPONENT'));
        await squadStore.loadSquadsByClubId(response.data.Club_No);
        showSquadModal.value = false;
      } else {
        showFailure.value = true;
        messageStore.addError(new Message('error', true, false, t('save', {object: t('club.squads.title')}), '', t('club.squads.save.failure'), false, 'COMPONENT'));
      }
    }).catch(error => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('save', {object: t('club.squads.title')}), t('club.squads.save.failure'), error, false, 'COMPONENT'));
    }).finally(() => {
      isSubmitted.value = false;
    });
  }
}

const confirmDelete = (event, squad) => {
  confirm.require({
    target: event.currentTarget,
    header: t('club.squads.delete.title'),
    message: t('club.squads.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      deleteSquad(squad.Club_No, squad.No);
    },
  });
};

const deleteSquad = (clubNo, squadNo) => {
  squadStore.deleteSquad(clubNo, squadNo).then(async response => {
    if (response.status >= 200 && response.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('club.squads.delete.title'), '', t('club.squads.delete.success'), false, 'COMPONENT'));
      await squadStore.loadSquadsByClubId(clubNo);
      showSquadModal.value = false;
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.squads.delete.title'), '', response.data, false, 'COMPONENT'));
    }
  }).catch(error => {
    showFailure.value = true
    messageStore.addError(new Message('error', true, false, t('club.squads.delete.title'), '', error, false, 'COMPONENT'));
  });
}

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && (value?.trim() !== '' && value?.length <= maxLength);
};

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return value?.trim() === '' || value?.trim().length <= maxLength;
};

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
}
</script>


