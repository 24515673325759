<template>
  <div class="modal modal-xl fade" tabindex="-1" v-on="{ 'hidden.bs.modal': resetToDefault }">
    <!-- Workaround, normales v-on funktioniert nicht mit Events mit '.' im Namen-->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          {{ $t('lspa.createNew') }}
        </div>

        <div class="modal-body">

          <div>
            <div class="row">
              <div class="col-6">
                <label for="firstName" class="form-label mt-2">{{ $t('createNewMember.firstName') }}</label>
                <input type="text" id="firstName" class="form-control" :placeholder="$t('createNewMember.firstName')"
                       v-model="firstName" :disabled="newMemberOk" autofocus/>

              </div>
              <div class="col-6">

                <label for="lastName" class="form-label mt-2">{{ $t('createNewMember.lastName') }}</label>
                <input type="text" id="lastName" class="form-control" :placeholder="$t('createNewMember.lastName')"
                       v-model="lastName" :disabled="newMemberOk"/>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="membershipNumber" class="form-label mt-2">{{ $t('lspa.membershipNr') }}</label>
                <input type="text" id="membershipNumber" class="form-control" :placeholder="$t('lspa.membershipNr')"
                       v-model="membershipNumber" :disabled="newMemberOk"/>
              </div>
              <div class="col-6">

                <label for="birthday" class="form-label mt-2">{{ $t('createNewMember.birthday') }}</label>
                <input type="date" :placeholder="$t('createNewMember.birthday')" id="birthday" class="form-control"
                       v-model="birthday" :disabled="newMemberOk" min="1900-01-01" />
              </div>
            </div>

            <DefaultButton class="btn mt-3 btn-outline-primary " :label="$t('lspa.searchForMember')"
                           @click="findLSPACandidates" :disabled="$v.$invalid || newMemberOk" v-if="!$v.$invalid"/>
          </div>


        </div>
        <div class="modal-footer">
          <DefaultButton v-if="newMemberOk" class="btn mt-3 btn-outline-primary "
                         :label="$t('lspa.confirmCreateNew', { currentYear: currentYear })" @click="createLSPAForNewPerson"
                         :disabled="!newMemberOk"/>
          <DefaultButton id="modal-cancel-button" class="btn mt-3 btn-outline-secondary " :label="$t('cancel')" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import DefaultButton from "@/components/ui/DefaultButton";
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ApiClient from "@/api/ApiClient";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import Message from "@/helpers/message";

const messagesStore = useMessagesStore();

const props = defineProps(['lspaClubId'])
const {t} = useI18n();

const firstName = ref("");
const lastName = ref("");
const birthday = ref(null);
const membershipNumber = ref(null);

const emit = defineEmits(['lspaClubMemberChanged']);

const newMemberOk = ref(false);

const currentYear = ref(new Date().getFullYear());

const rules = computed(() => {
  return {
    firstName: {required},
    lastName: {required},
    birthday: {required},
    membershipNumber: {required}
  }
})

const $v = useVuelidate(rules, {firstName, lastName, birthday, membershipNumber});

function resetToDefault() {
  firstName.value = "";
  lastName.value = "";
  birthday.value = null;
  membershipNumber.value = null;
  newMemberOk.value = false;

}

async function findLSPACandidates() {
  if ($v.value.$invalid) {
    return;
  }

  const request = "/api/lspa/find_potential"
    + "?membershipNr=" + membershipNumber.value
    + "&firstName=" + firstName.value
    + "&lastName=" + lastName.value
    + "&birthday=" + birthday.value

  const response = await ApiClient.getRequest(request);

  if (response && response.status === 200) {
    const result = response.data;


    switch (result) {
      case "ACTIVE":
        newMemberOk.value = true;
        break;
      case "INACTIVE":
        newMemberOk.value = false;
        messagesStore.addError(new Message('error', true, false, t('lspa.memberInactiveHeader'), "",  t('lspa.memberInactive')));
        break;
      default:
        newMemberOk.value = false;
        messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
    }
  } else {
    newMemberOk.value = false;
    if(response.status && response.status === 404) {
      messagesStore.addError(new Message('error', true, false, t('lspa.memberNotFoundHeader'), "", t('lspa.memberNotFound')));
    } else {
      messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
    }
  }
}

function closeSelf() {
  document.getElementById('modal-cancel-button').click();
}

async function createLSPAForNewPerson() {
  if ($v.value.$invalid) {
    return;
  }

  const request = "/api/lspa/create"
    + "?membershipNr=" + membershipNumber.value
    + "&lspaClubId=" + props.lspaClubId;


  const response = await ApiClient.postRequestWithAuthorization(request, "");


  if (response.data.error) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", response.data.error.message));
  } else if (response && response.status === 200) {
    messagesStore.addInfo(new Message('info', true, false, t('lspa.successCreatingNewHeader'), "", t('lspa.successCreatingNew')))
    emit('lspaClubMemberChanged');
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }
  closeSelf()
}


</script>
