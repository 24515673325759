import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const MEMBER_BASE_PATH = "/api/club/membership";

export const useMemberShipStore = defineStore("member_ship", {
  state: () => {
    return {
      membershipData: null,
      clubId: null,
      squadsByClub: null,
      categories: null,
      additions:null,
      memberId: null
    };
  },
  actions: {
    async loadMembershipData(clubId, memberId) {
      const membershipData = await ApiClient.getRequest(MEMBER_BASE_PATH + '?memberId=' + memberId + '&clubId=' + clubId);
      this.clubId = clubId;
      this.memberId = memberId;
      if (membershipData?.status < 400 && membershipData?.data) {
        this.membershipData = membershipData.data;
      }
    },
    async loadMembershipDataForAllClubs(memberId) {
      const membershipData = await ApiClient.getRequest(MEMBER_BASE_PATH + "/all" + '?memberId=' + memberId );
      this.memberId = memberId;
      if (membershipData?.status < 400 && membershipData?.data) {
        this.membershipData = membershipData.data;
      }
    },
    async loadCategories() {
      const categories = await ApiClient.getRequest(MEMBER_BASE_PATH + '/categories');
      if (categories?.status < 400 && categories?.data) {
        this.categories = categories.data;
      }
    },
    async loadSquadsByClub(clubId) {
      const squads = await ApiClient.getRequest(MEMBER_BASE_PATH + '/squads?clubId=' + clubId);
      if (squads?.status < 400 && squads?.data) {
        this.squadsByClub = squads.data;
      }
    },
    async loadAdditions() {
      const additions = await ApiClient.getRequest(MEMBER_BASE_PATH + '/additions');
      if (additions?.status < 400 && additions?.data) {
        this.additions = additions.data;
      }
    },
    async updateMembershipData(data) {
      const response = await ApiClient.putRequest(MEMBER_BASE_PATH, data);
      if (response) {
        return response;
      }
      return null;
    },
    async saveNewMembershipData(data) {
      const response = await ApiClient.postRequestWithAuthorization(MEMBER_BASE_PATH,data);
      if (response) {
        return response;
      }
      return null;
    },
    async deleteMembership(memberId, lineNumber){
      const response = await ApiClient.deleteRequest(MEMBER_BASE_PATH+'?memberId='+ memberId + '&lineNumber=' + lineNumber);
      if (response) {
        return response;
      }
      return null;
    }




  },
  getters: {
    getMembershipData: (state) => {
      if (state.membershipData) {
        return state.membershipData;
      }
      return null;
    },
    getSquadsByClub: (state) => {
      if(state.squadsByClub) {
        return state.squadsByClub;
      }
      else {
        return null;
      }
    },
    getContactId: (state) => {
      if (state.memberId) {
        return state.memberId
      }
      return null;
    },
    getClubId: (state) => {
      if (state.clubId) {
        return state.clubId;
      }
      return null;
    },
    getAdditions: (state) => {
      if (state.additions) {
        return state.additions;
      }
      return null;
    },
    getCategories: (state) => {
      if (state.categories) {
        return state.categories;
      }
      return null;
    },
  },
  persist: {
    storage: sessionStorage
  }
});
