<template>
  <association-layout :title="clubData.status === 200 ? $t('association.clubs.title') + ' ' + clubData.data.Name : $t('association.notFound.title')" :is-loading="isLoading">
    <template #content>
      <div class="row">
        <div class="col-12">
          <DataTable :value="associationClubs"
                     scrollable scroll-height="60vh"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     v-model:selection="selection"
                     data-key="entryNo"
                     v-model:filters="filters" filter-display="row"
                     ref="dt" :export-filename="$t('association.clubs.title').replaceAll(' ', '_')" :export-function="csvExportTransformationFunction"
                     :loading="isLoading"
                     :paginator="associationClubs?.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                     csv-separator=";"
                     row-hover
                     class="border-bottom">

            <template #empty>
              <div class="alert alert-info">{{ $t('association.functionsAndActivities.list.notFound') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column v-for="col in clubColumns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                <router-link v-if="col.link" :to="{name: (String(data.No).startsWith('2') ? ASSOC_SHOW_LSPA_MEMBERS.name : ASSOC_SHOW_CLUB_MEMBERS.name),
                query: {associationId: clubData.data.No, clubId: data.No}}" class="table-link"
                             @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                  {{ getDescendantProp(data, col.field) }}
                </router-link>
                <div v-else>
                  {{ getDescendantProp(data, col.field) }}
                </div>
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                           :placeholder="$t('table.filter.header', {filter: col.header})" class="form-control input-filter"/>
              </template>
            </Column>

          </DataTable>

          <div class="row w-100 flex-center mb-3 mt-3">
            <Button :title="$t('export')" :label="$t('export')" @click="exportCSV()" class="datatable-export me-1 width-auto" severity="secondary" outlined>
              <i class="bi bi-filetype-csv"></i>
            </Button>
          </div>

        </div>
      </div>

      <AssociationClubFunctionTable :association-id="clubData.data.No"/>
    </template>
  </association-layout>
</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import Button from 'primevue/button';
import {useClubStore} from "@/store/modules/club/club";
import {computed, onMounted, ref} from "vue";
import {FilterMatchMode} from "@primevue/core/api";
import {useI18n} from "vue-i18n";
import {useAssociationStore} from "@/store/modules/association/association";
import {ASSOC_SHOW_CLUB_MEMBERS, ASSOC_SHOW_LSPA_MEMBERS} from "@/router/urls_association";
import AssociationClubFunctionTable from "@/components/function/AssociationClubFunctionTable.vue";

const {t} = useI18n();
const clubStore = useClubStore();
const associationStore = useAssociationStore();

const clubData = computed(() => clubStore.getClubData);
const associationClubs = computed(() => associationStore.getAllClubs);

const isLoading = ref(false);
const dt = ref();
const sortColumn = ref('No');
const filters = ref({
  No: {value: null, matchMode: FilterMatchMode.CONTAINS},
  Name: {value: null, matchMode: FilterMatchMode.CONTAINS},
  DisplayName: {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const selection = ref([]);
const clubColumns = [
  {field: 'No', header: t('club.nr'), filterable: true, sortable: true, exportable: true, visible: true, link: true, type: 'String'},
  {field: 'Name', header: t('club.stvName'), filterable: true, sortable: true, exportable: true, visible: true, link: true, type: 'String'},
  {field: 'DisplayName', header: t('club.name'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'Year_Established', header: t('club.foundingYear'), filterable: false, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'Home_Page', header: t('club.communication.homepage'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'E_Mail', header: t('club.communication.email'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'Instagram', header: t('club.communication.instagram'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'Facebook', header: t('club.communication.facebook'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'TikTok', header: t('club.communication.tikTok'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'LinkedIn', header: t('club.communication.linkedIn'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
  {field: 'X', header: t('club.communication.x'), filterable: false, sortable: false, exportable: true, visible: true, type: 'String'},
];

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const exportCSV = () => {
  dt.value.exportCSV({selectionOnly: false})
};

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    default:
      return String(record.data)
  }
}

onMounted(async () => {
  isLoading.value = true;
  associationStore.loadAllClubs(clubData.value.data.No).then(() => {
    isLoading.value = false
  });
});
</script>

<style scoped lang="scss">
.table-link {
  display: inline-block;
  width: 100%;
}
</style>
