<template>
  <div class="modal fade" id="imageCropperVereinModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isAssociation ? $t('association.logo') : $t('club.logo') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="vereinLogo && !avatarUploaded">
          <VereinAvatar avatar-class="profile img-fluid" >
            <div class="img-profile-overlay" @click="$refs.vereinImgUpload.click()">
              <p>
                <span class="display-3"><i class="bi bi-camera"></i></span><br>
                {{ isAssociation ? $t('association.uploadNewPicture') : $t('club.uploadNewPicture') }}
              </p>
            </div>
          </VereinAvatar>
        </div>
        <ImageCropper v-if="avatarUploaded" :image="avatarImage" :is-uploading="isSubmitted" cropper-width="300px"
                      :objectName="isAssociation ? 'association.logo' : 'club.logo'"  cropper-height="300px" @crop="saveAvatar" @cancel="cancelCropping"/>
        <div class="modal-footer" v-if="!avatarUploaded">
          <label for="profileImg" class="btn btn-outline-secondary">
            <i class="bi bi-upload me-2"></i>
            {{ isAssociation ? $t('association.uploadNewPicture') : $t('club.uploadNewPicture') }}
            <input type="file" accept=".jpg,.jpeg,.png,.bmp,.gif" name="profileImg" id="profileImg" hidden @change="onFileSelected" ref="vereinImgUpload">
          </label>
          <DefaultButton class="btn-outline-primary" :label="isAssociation ? $t('association.deletePicture') : $t('club.deletePicture')"
                         icon="bi bi-trash3" data-bs-toggle="modal" data-bs-target="#deleteModal" v-if="vereinLogo"/>
          <DefaultButton class="btn-outline-secondary" :label="$t('cancel')" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="deleteModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isAssociation ? $t('association.deletePicture') : $t('club.deletePicture') }}</h5>
        </div>
        <div class="modal-body">
          <p>{{ isAssociation ? $t('association.deletePictureQuestion') : $t('club.deletePictureQuestion') }}</p>
        </div>
        <div class="modal-footer">
          <DefaultButton class="btn-outline-primary" :label="$t('profile.security.device.delete.confirm')"
                         @click="deleteAvatar" @keyup.enter="deleteAvatar" data-bs-dismiss="modal"/>
          <DefaultButton class="btn-outline-secondary" :label="$t('cancel')" @click="cancelCropping" data-bs-dismiss="modal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VereinAvatar from "@/components/personal-data/VereinAvatar.vue";
import ImageCropper from "@/components/ui/ImageCropper.vue";
import {mapActions, mapState} from "pinia";
import {useClubStore} from "@/store/modules/club/club";
import {Modal} from "bootstrap";
import DefaultButton from "@/components/ui/DefaultButton.vue";

export default {
  emits: [ 'toastShow' ],
  data() {
    return {
      avatarImage: null,
      avatarUploaded: false,
      isSubmitted: false,
      showSuccess: false,
      showFileSizeError: false,
      toast: null,
      modal: null,
      msg: '',
    };
  },
  props: {
    clubId: {
      type: String,
      required: true,
    },
    isAssociation: {
      type: Boolean,
      required: false,
    }
  },
  components: {DefaultButton, ImageCropper, VereinAvatar},
  computed: {
    ...mapState(useClubStore, {vereinLogo: "clubLogo"})
  },
  methods: {
    onFileSelected(event) {
      this.showFileSizeError = false;
      this.avatarImage = '';
      this.avatarUploaded = false;
      const [ file ] = event.target.files;
      if (file) {
        if (file.size / (1024 * 1024) > 16) {
          this.showFileSizeError = true;
          this.$emit('toastShow', false, this.$t('profile.pictureSizeExceeded'));
        } else {
          this.avatarImage = URL.createObjectURL(file);
          this.avatarUploaded = true;
        }
      }
    },
    deleteAvatar() {
      this.deleteVereinLogo(this.clubId).then(async response => {
        if (response.status === 200) {
          await this.getVereinLogoAsBase64(this.clubId);
          this.msg = this.$t('profile.save.success');
          this.avatarImage = null;
          this.showSuccess = true;
          this.showFileSizeError = false;
        }
      }).catch(error => {
        this.showFileSizeError = false;
        this.showSuccess = false;
        this.msg = this.$t('profile.save.failure');
        console.error(error);
      }).finally(() => {
        this.modal.hide();
        this.$emit('toastShow', this.showSuccess, this.msg);
      });
    },
    saveAvatar(img) {
      this.isSubmitted = true;
      this.saveVereinLogo(img, this.clubId).then(async response => {
        if (response && response.status === 200) {
          await this.getVereinLogoAsBase64(this.clubId);
          window.scrollTo(0, 0);
          this.showSuccess = true;
          this.msg = this.$t('profile.save.success');
          this.showFileSizeError = false;
          this.avatarUploaded = false;
        }
      }).catch(error => {
        this.showSuccess = false;
        this.showFileSizeError = false;
        this.msg = this.$t('profile.save.failure');
        console.log('error saving image', error);
      }).finally(() => {
        this.isSubmitted = false;
        this.modal.hide();
        this.$emit('toastShow', this.showSuccess, this.msg);
      });
    },
    cancelCropping() {
      this.isSubmitted = false;
      this.avatarUploaded = false;
    },
    ...mapActions(useClubStore, {saveVereinLogo: "saveVereinLogo", getVereinLogoAsBase64: "getVereinLogoAsBase64", deleteVereinLogo: "deleteVereinLogo"})
  },
  mounted() {
    this.modal = new Modal('#imageCropperVereinModal');
    document.querySelector('#imageCropperVereinModal').addEventListener('hide.bs.modal', () => {
      this.cancelCropping();
    });
  }
}
</script>

<style scoped lang="scss">
.modal-body {
  .img-profile {
    margin: 0 auto;
    max-width: 300px;
  }
}
</style>
