<template>
  <div class="row mb-3">
    <div class="col">
      <DataTable :value="clubAddressRelations"
                 scrollable scroll-height="60vh"
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 data-key="function.Code"
                 v-model:filters="filters" filter-display="row"
                 ref="dtFunctions"
                 :loading="isLoadingFunctions"
                 paginator :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 csv-separator=";" :rowHover="true" :alwaysShowPaginator="false"
                 exportFilename="functions">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.member.functions.notFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column :hidden="!props.associationMode" :exportable="props.associationMode" field="clubName" filter-field="clubName"
                filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true"
                :header-class="(sortColumn === 'clubName' ? 'tableHeader text-primary' : 'tableHeader')"
                :header="$t('section.function.organisation')" >
          <template #body="{data}">
            {{ data.clubName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('section.clubMembers.filter', {filter: $t('section.function.organisation')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="function.Description" filter-field="function.Description" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'function.Description' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.functions.function')" v-if="$i18n.locale === 'de'">
          <template #body="{data}">
            {{ data.function.Description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.functions.function')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="function.DescriptionFR" filter-field="function.DescriptionFR" filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true" :header-class="(sortColumn === 'function.DescriptionFR' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.functions.function')" v-if="$i18n.locale === 'fr'">
          <template #body="{data}">
            {{ data.function.DescriptionFR }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.functions.function')})" class="form-control input-filter"/>
          </template>
        </Column>

      </DataTable>
    </div>
  </div>

  <div class="row w-100 flex-center mb-3 mt-3">
    <Button :title="$t('export')" :label="$t('export')" @click="exportFunctionsCSV($event)" class="btn btn-outline-primary datatable-export me-1 width-auto" unstyled>
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>

  <div class="row">
    <div class="col">
      <DataTable :value="clubInstituteAddresses"
                 scrollable scroll-height="60vh"
                 :sort-field="instituteSortColumn" :sort-order="1" @sort="onSortInstituteFunction"
                 data-key="function.Code"
                 v-model:filters="filtersInstitute" filter-display="row"
                 ref="dtInstituteFunctions"
                 :loading="isLoadingInstituteFunctions"
                 paginator :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 csv-separator=";" :rowHover="true" :alwaysShowPaginator="false"
                 exportFilename="exportFunctions" :exportFunction="exportInstituteFunctions">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.member.functions.notFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column :hidden="!props.associationMode" :exportable="props.associationMode" field="clubName" filter-field="clubName"
                filter-header-class="table-header-filter" :show-filter-menu="false" :sortable="true"
                :header-class="(instituteSortColumn === 'clubName' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('section.function.organisation')" >
          <template #body="{data}">
            {{ data.clubName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter',
            {filter: $t('club.member.functions.groups')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="instituteCode" filter-field="instituteCode" filter-header-class="table-header-filter" :show-filter-menu="false"
                :sortable="true" :header-class="(instituteSortColumn === 'instituteCode' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.functions.groups')">
          <template #body="{data}">
            {{ getInstituteDescription(data.instituteCode) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.functions.groups')})" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="functionDescription" filter-field="functionDescription" filter-header-class="table-header-filter" :show-filter-menu="false"
                :sortable="true" :header-class="(instituteSortColumn === 'functionDescription' ? 'tableHeader text-primary' : 'tableHeader')" :header="$t('club.member.functions.function')">
          <template #body="{data}">
            {{ data.functionDescription }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.member.functions.function')})" class="form-control input-filter"/>
          </template>
        </Column>

      </DataTable>
    </div>
  </div>

  <div class="row w-100 flex-center mb-3 mt-3">
    <Button :title="$t('export')" :label="$t('export')" @click="exportInstituteFunctionsCSV($event)" class="datatable-export me-1 width-auto btn btn-outline-primary" unstyled>
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>

</template>

<script setup>

import Button from "primevue/button";

import {computed, ref} from "vue";
import {useClubMemberStore} from "@/store/modules/clubMember/clubMember";
import {useInstituteStore} from "@/store/modules/institute/institute";
import {FilterMatchMode} from '@primevue/core/api';

const clubMemberStore = useClubMemberStore();
const instituteStore = useInstituteStore();


const props = defineProps({
  associationMode: {
    default: false,
    type: Boolean,
    required: false
  }
});

const dtFunctions = ref();
const sortColumn = ref('function.Description');
const filters = ref({
  'clubName': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.Description': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.DescriptionFR': {value: null, matchMode: FilterMatchMode.CONTAINS},
});

const dtInstituteFunctions = ref();
const instituteSortColumn = ref('instituteCode');
const filtersInstitute = ref({
  'clubName': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'functionDescription': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'instituteCode': {value: null, matchMode: FilterMatchMode.CONTAINS},
});

const isLoadingFunctions = computed(() => {
  return !(clubAddressRelations.value);
});

const isLoadingInstituteFunctions = computed(() => {
  return !(clubInstituteAddresses.value);
});

const clubAddressRelations = computed(() => {
  return clubMemberStore.getClubAddressRelations;
});

const clubInstituteAddresses = computed(() => {
  return clubMemberStore.getClubInstituteAddresses;
})

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const onSortInstituteFunction = (event) => {
  instituteSortColumn.value = event.sortField;
}

const exportFunctionsCSV = () => {
  dtFunctions.value.exportCSV({selectionOnly: false})
};

const exportInstituteFunctionsCSV = () => {
  dtInstituteFunctions.value.exportCSV({selectionOnly: false})
}

const getInstituteDescription = (instituteNo) => {
  return instituteStore.getInstituteDescription(instituteNo);
}

const exportInstituteFunctions = (data) => {
  switch (data.field) {
    case 'instituteCode':
      return getInstituteDescription(data.data)
    default:
      return String(data.data)
  }
}

</script>

