import {defineStore} from "pinia";
import ApiClient from "@/api/ApiClient";

const AUTOCOMPLETE_BASE_PATH = '/api/address';

export const useAutocompleteStore = defineStore('autocomplete', {
  state: () => {
    return {
      searchObject: {
        "request": {
          "ONRP": 0,
          "ZipCode": "",
          "ZipAddition": "",
          "TownName": "",
          "STRID": 0,
          "StreetName": "",
          "HouseKey": 0,
          "HouseNo": "",
          "HouseNoAddition": ""
        },
        "zipOrderMode":0,
        "zipFilterMode":0
      }
    };
  },

  actions: {
    async autocompleteAddress(streetName, streetNumber, streetNumberAddition, zipCode, place) {
      this.searchObject.request.StreetName = streetName;
      this.searchObject.request.HouseNo = streetNumber;
      this.searchObject.request.HouseNoAddition = streetNumberAddition;
      this.searchObject.request.ZipCode = zipCode;
      this.searchObject.request.TownName = place;

      let response = await ApiClient.postRequestWithAuthorization(`${AUTOCOMPLETE_BASE_PATH}/autocomplete`, this.searchObject);
      if (response) {
        return response;
      }
      return null;
    },
    resetSearchObject() {
        this.searchObject.request.StreetName = "";
        this.searchObject.request.HouseNo = "";
        this.searchObject.request.HouseNoAddition = "";
        this.searchObject.request.ZipCode = "";
        this.searchObject.request.TownName = "";
    }
  },
});