export const getFullDateAsString = (fulldate) => {
  if (fulldate instanceof Date && !isNaN(fulldate)) {
    return `${getDateOnlyAsString(fulldate)} ${getTimeAsString(fulldate)}`;
  }
  return "";
};

export const getDateOnlyAsString = (fulldate) => {
  if (fulldate instanceof Date && !isNaN(fulldate)) {
    const day = fulldate.getDate();
    const month = fulldate.getMonth() + 1;

    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${fulldate.getFullYear()}`;
  }
  return "";
};

export const getTimeAsString = (fulldate) => {
  if (fulldate instanceof Date && !isNaN(fulldate)) {
    const hours = fulldate.getHours().toString().padStart(2, "0");
    const minutes = fulldate.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }
  return "";
};

export const getDateCorrectedForTimezoneOffset = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export const getDateCorrectedForTimezoneOffsetAsIsoString = (date) => {
  return getDateCorrectedForTimezoneOffset(date).toISOString();
}

export const getSquadByKey = (key) => {
  const iconType = key.toUpperCase();
  if (iconType.indexOf('KUTU') > -1 || iconType.indexOf('KUNSTTURN') > -1) {
    return 'kunstturnen';
  } else if (iconType.indexOf('RHYTHM') > -1) {
    return 'rhythmischeGymnastik';
  } else if (iconType.indexOf('TRAMP') > -1) {
    return 'trampolin';
  } else if (iconType.indexOf('AEROB') > -1) {
    return 'aerobic';
  } else if (iconType.indexOf('NATION') > -1 || iconType.indexOf('NATIONAL') > -1 || iconType.indexOf('NATI') > -1 || iconType.indexOf('NATU') > -1) {
    return 'nationalturnen';
  } else if (iconType.indexOf('GYMNAS') > -1) {
    return 'gymnastik';
  } else if (iconType.indexOf('GERAET') > -1 || iconType.indexOf('GETU') > -1) {
    return 'geraeteturnen';
  } else if (iconType.indexOf('FITNESS') > -1) {
    return 'fitness_gesundheitssport';
  } else if (iconType.indexOf('TURNEN') > -1) {
    return 'turnen';
  } else if (iconType.indexOf('FAUST') > -1) {
    return 'faustball';
  } else if (iconType.indexOf('KORB') > -1 || iconType.indexOf('BASKET') > -1) {
    return 'korbball';
  } else if (iconType.indexOf('LEICHT') > -1) {
    return 'leichtathletik';
  } else if (iconType.indexOf('HAND') > -1) {
    return 'handball';
  } else if (iconType.indexOf('VOLLEY') > -1) {
    return 'volley';
  } else if (iconType.indexOf('KINDER') > -1 || iconType.indexOf('KITU') > -1 || iconType.indexOf('MUKI') > -1) {
    return 'kinderturnen';
  } else if (iconType.indexOf('BADMIN') > -1) {
    return 'badminton';
  } else if (iconType.indexOf('INDIA') > -1) {
    return 'indiaca';
  } else if (iconType.indexOf('NETZ') > -1) {
    return 'netzball';
  } else if (iconType.indexOf('PARK') > -1) {
    return 'parkour';
  }
  return '';
};

export const getSquadByCode = (code) => {
  switch (code) {
    case '1': // Kutu Männer
    case '2': // Kutu Frauen
      return 'kunstturnen';
    case '3':
      return 'rhythmischeGymnastik';
    case '4':
      return 'trampolin';
    case '5': // Sport-Aerobic
    case '9': // Aerobic
      return 'aerobic';
    case'6': // Nationalturnen/Ringen
      return 'nationalturnen';
    case '7':
      return 'gymnastik';
    case '8':
      return 'geraeteturnen';
    case '10': // Fitness diverse
      return 'turnen';
    case '11': // Tanz diverse
      return '';
    case '12':
      return 'faustball';
    case '13':
      return 'korbball';
    case '14':
      return 'leichtathletik';
    case '15': // Handball
      return '';
    case '16':
      return 'volley';
    case '17': // Unihockey
      return '';
    case '19': // Basketball
      return '';
    case '20': // Ski
      return '';
    case '21': // Vereinsturnen
      return '';
    case '22': // Muki/Vaki/Elki
    case '23': // Kinderturnen
    case '24': // Knaben
    case '25': // Mädchen
    case '37': // Jugend
      return 'kinderturnen';
    case '26': // Damen
    case '27': // Frauen
    case '28': // Männer
    case '29': // Seniorinnen
    case '30': // Senioren
      return 'turnen';
    case '40':
      return 'rhoenrad';
    case '41': // Badminton
      return '';
    case '42': // Breakdance
    case '48': // Hip-Hop
      return '';
    case '43': // aktiver Turner
    case '45': // aktive Turnerin
      return '';
    case '46':
      return 'indiaca';
    case '47': // Walking/Nordic walking
      return '';
    case '49': // Yoga/Power Yoga
    case '50': // Pilates
      return '';
    case '51': // Spiel
      return '';
    case '52': // Zumba
      return '';
    case '53': // Netzball
      return '';
    case '54': // Parkour
    case '3808': // Parkour
      return 'parkour';
    case '55': // Vereinsmanagement
    case '3809': // Vereinsmanagement
      return '';
    default:
      return '';
  }
};


export const removeEmpty = (obj) => {
  Object.entries(obj).forEach(([ key, val ]) =>
    (val && typeof val === 'object') && removeEmpty(val) ||
    (val === null) && delete obj[key]
  );
  return obj;
};
