<template>
  <a id="language-navigation"
     class="header-item"
     :class="{active: locale === selectedLocale}"
     v-for="locale in locales"
     :key="locale"
     @click="changeLocale(locale)">
      <span class="d-flex justify-content-center align-self-center">
        <small>{{ locale }}</small>
      </span>
  </a>
</template>

<script setup>
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n'
import {useRouter} from 'vue-router';

const router = useRouter();
const i18nLocale = ref(useI18n().locale.value);

const changeLocale = (locale) => {
  if (selectedLocale.value !== locale) {
    localStorage.setItem('lang', locale);
    i18nLocale.value = locale;
   router.go();
  }
}

const browserLocale = computed(() => {
  return window.navigator.language.substring(0, 2);
})

const selectedLocale = computed(() => {
  if (!i18nLocale.value) {
    return browserLocale.value;
  }
  return i18nLocale.value;
})

const locales = computed(() => {
  return window.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
})
</script>

