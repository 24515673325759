<template>
  <div class="modal-body">

    <div class="cropper-wrapper">
      <cropper
        class="cropper mb-3"
        ref="cropper"
        :src="image"
        :auto-zoom="true"
        :stencil-component="$options.components.CircleStencil"
        :stencil-size="{
        width: 250,
        height: 250,
      }"
        :stencil-props="{
        handlers: {},
        aspectRatio: 1,
        movable: false,
        resizable: false,
      }"
        :resize-image="{
      adjustStencil: false,
      }"
        image-restriction="stencil"
        :style="cssProps"
        :canvas="{
        maxArea: 500 * 500
      }"
      >
        <CircleStencil/>
      </cropper>
      <div class="vertical-buttons">
        <square-button :title="$t('cropper.zoomIn')" @click="zoom(1.5)" v-if="cropperButtons.zoomIn">
          <i class="bi bi-zoom-in"></i>
        </square-button>
        <square-button :title="$t('cropper.zoomOut')" @click="zoom(.5)" v-if="cropperButtons.zoomOut">
          <i class="bi bi-zoom-out"></i>
        </square-button>
        <square-button title="Vertikal spiegeln" @click="flip(true, false)" v-if="cropperButtons.flipVertical">
          <i class="bi bi-symmetry-vertical"></i>
        </square-button>
        <square-button title="Horizontal spiegeln" @click="flip(false, true)" v-if="cropperButtons.flipHorizontal">
          <i class="bi bi-symmetry-horizontal"></i>
        </square-button>
        <square-button title="nach links drehen" @click="rotate(-90)" v-if="cropperButtons.rotateLeft">
          <i class="bi bi-arrow-counterclockwise"></i>
        </square-button>
        <square-button title="nach rechts drehen" @click="rotate(90)" v-if="cropperButtons.rotateRight">
          <i class="bi bi-arrow-clockwise"></i>
        </square-button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <DefaultButton class="btn-outline-success me-2" :label="$t('save', {object: $t(objectName), count: 2})" @click="crop" :disabled="isUploading"/>
    <DefaultButton class="btn-outline-dark" :label="$t('cancel')" @click="cancel" :disabled="isUploading"/>
  </div>
</template>

<script>
import {CircleStencil, Cropper} from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';
import SquareButton from '@/components/ui/wrapper/SquareButton';

export default {
  components: {
    Cropper,
    CircleStencil,
    SquareButton
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    objectName: {
      type: String,
      required: false,
      default: 'profile.picture',
    },
    cropperBgColor: {
      type: String,
      required: false,
      default: '#333'
    },
    cropperWidth: {
      type: String,
      required: false,
      default: '300px',
    },
    cropperHeight: {
      type: String,
      required: false,
      default: '300px',
    },
    isUploading: {
      type: Boolean,
      required: false,
      default: false
    },
    cropperButtons: {
      type: Object,
      required: false,
      default() {
        return {
          zoomIn: true,
          zoomOut: true,
          flipHorizontal: true,
          flipVertical: true,
          rotateLeft: false,
          rotateRight: false,
        }
      }
    }
  },
  data() {
    return {
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      croppedImage: null
    }
  },
  emits: [ 'crop', 'cancel' ],
  methods: {
    crop() {
      const {coordinates, canvas} = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.croppedImage = canvas.toDataURL();
      this.$emit('crop', this.croppedImage);
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    cancel() {
      this.$emit('cancel', true);
    },
    getBase64StringFromDataURL(dataURL) {
      return dataURL.replace('data:', '').replace(/^.+,/, '');
    }
  },
  computed: {
    cssProps() {
      return {
        '--cropper-width': this.cropperWidth,
        '--cropper-height': this.cropperHeight,
        '--cropper-bg-color': this.cropperBgColor,
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.cropper-wrapper {
  position: relative;

  .cropper {
    margin: 0 auto;
    width: var(--cropper-width);
    height: var(--cropper-height);
    background-color: var(--cropper-bg-color);
  }
}

.vertical-buttons {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
