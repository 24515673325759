import ApiClient from "@/api/ApiClient";
import QrCodeApiClient from "@/api/QrCodeApiClient";

import {defineStore} from "pinia";

const MEMBER_BASE_PATH = "/api/member";
const QRCODE_BASE_PATH = "/api/qrcode";
const WIZARD_BASE_PATH = '/api/wizard';
const PROFILE_PHOTO_BASE_PATH = '/api/profile';
const TWO_FACTOR_BASE_PATH = '/api/user/2fa';
const CLUB_NUMBER_LENGTH_CUTOFF = 13; // Wie lange eine Vereins/Verbandsnummer sein muss, um als Vereinsnummer zu gelten

export const useMemberStore = defineStore("member", {
  state: () => {
    return {
      memberData: null,
      wizardIds: null,
      wizardData: null,
      useCache: true,
      googleWalletLink: '',
      emulatingMember: false,
      wizardsDone: [],
      showWizardsAfterLogin: true,
      clubAdmin: null,
      releaseMemberData: null,
      singleMemberData: null,
      countries: [],
      country: null,
      counties: [],
      county: null,
      twoFactorActive: false,
      twoFactorDevices: [],
    };
  },

  actions: {
    async loadMemberData(useCache, overwrite) {
      const memberData = await ApiClient.getRequest(MEMBER_BASE_PATH + '/byid/principal?useCache=' + useCache + '&overwrite=' + overwrite);
      if (memberData?.data) {
        this.memberData = memberData.data;
      }
      this.useCache = useCache;
    },

    async loadReleaseMemberData(payload) {
      const memberData = await ApiClient.getRequest(MEMBER_BASE_PATH + '/byid/principal?useCache=' + payload.useCache + '&releaseMemberId=' + payload.releaseMemberId + '&overwrite=' + payload.overwrite);

      this.emulatingMember = true;
      this.releaseMemberData = memberData.data.stvMember;
    },

    async sendOnboardingRequest(payload) {
      return await ApiClient.postRequestWithoutAuthorization(MEMBER_BASE_PATH + '/self-onboarding?id=' + payload.stvMemberId, {"apikey": window.VUE_APP_BACKEND_API_KEY}).then(response => {
        return response;
      });
    },

    async loadPhotoForReleaseMember(payload) {
      const response = await ApiClient.getRequest(PROFILE_PHOTO_BASE_PATH + '/photo?releaseMemberId=' + payload.releaseMemberId, payload);
      this.releaseMemberData.photo = response.data.photo;
    },
    async loadCountries(languageId) {
      const response = await ApiClient.getRequest(MEMBER_BASE_PATH + '/countryCodes/' + languageId)
      this.countries = response.data
    },
    async loadCountry(countryCode) {
      if (this.country === null || (this.country.code !== countryCode)) {
        const response = await ApiClient.getRequest(MEMBER_BASE_PATH + '/country/' + countryCode)
        this.country = response.data
      }
    },
    async loadCounties(languageId) {
      const response = await ApiClient.getRequest(MEMBER_BASE_PATH + '/countyCodes/' + languageId)
      this.counties = response.data
    },
    async loadCounty(countyCode) {
      if (this.county === null || (this.county.code !== countyCode)) {
        const response = await ApiClient.getRequest(MEMBER_BASE_PATH + '/county/' + countyCode)
        this.county = response.data
      }
    },
    clearReleaseMemberData() {
      this.emulatingMember = false;
      this.releaseMemberData = {};
    },
    async saveMemberBaseData(payload) {
      const response = await ApiClient.postRequestWithAuthorization(MEMBER_BASE_PATH + "/mutation/masterdata", payload);
      if (response) {
        return response;
      }
      return null;
    },
    async saveReleaseMemberBaseData(payload) {
      const response = await ApiClient.postRequestWithAuthorization(MEMBER_BASE_PATH + "/mutation/masterdata?releaseMemberId=" + payload.releaseMemberId, payload.baseData);
      if (response) {
        return response;
      }
      return null;
    },
    async saveMemberBirthday(payload) {
      const releaseMemberIdParam = payload.releaseMemberId ? '&releaseMemberId=' + payload.releaseMemberId : '';
      const response = await ApiClient.postRequestWithAuthorization(MEMBER_BASE_PATH + '/mutation/birthdate?newDate=' + payload.newDate + releaseMemberIdParam, payload);
      if (response) {
        return response;
      }
      return null;
    },
    async loadSingleMemberData(memberId, clubId) {
      const response = await ApiClient.getRequest(MEMBER_BASE_PATH + '/byid?id=' + memberId + '&clubId=' + clubId);
      if (response?.data) {
        this.singleMemberData = response.data
        return response.data;
      }
      this.singleMemberData = null;
      return null;
    },
    async clearSingleMemberData() {
      this.singleMemberData = null;
    },
    async loadQrCodeBySize(payload) {
      return QrCodeApiClient.getRequest(QRCODE_BASE_PATH + '/picture/' + payload.size, payload);
    },
    async loadQrCodeForMember(payload) {
      return QrCodeApiClient.getRequest(QRCODE_BASE_PATH + '/picture/1000', payload);
    },
    setCurrentClubId(clubId) {
      this.memberData.currentClubId = clubId;
    },
    setCurrentClubName(clubName) {
      this.memberData.currentClubName = clubName;
    },
    async clearMemberData() {
      this.memberData = {};
    },
    loadMemberDataFromCache(useCache) {
      this.useCache = useCache;
    },
    updateEmail(payload) {
      this.memberData.stvMember.email = payload.newEmail;
      this.memberData.stvMember.emailPrivate = payload.newEmail;
    },
    async setWizardDone(wizardId) {
      this.wizardsDone.push(wizardId)
    },
    async clearWizardData() {
      this.wizardData = null
    },

    // Wallet Stuff
    async loadGoogleWalletLink(payload) {
      const uri = MEMBER_BASE_PATH + "/wallet/google-link" + this.generateWalletQueryString(payload);
      const response = await ApiClient.getRequest(uri);
      if (response.status === 200) {
        this.googleWalletLink = response.data;
      }
    },
    async updateGoogleWalletPass(payload) {
      const uri = MEMBER_BASE_PATH + "/wallet/google-link" + this.generateWalletQueryString(payload);
      await ApiClient.putRequest(uri);
    },
    resetGoogleWalletPass() {
      this.googleWalletLink = "";
    },
    async loadAppleWalletPass(payload) {
      const uri = MEMBER_BASE_PATH + "/wallet/apple-pass" + this.generateWalletQueryString(payload);
      return ApiClient.getApplePass(uri);
    },
    generateWalletQueryString(payload) {
      let queryString = '?&memberClass=' + payload.memberClass;
      if (payload.releaseMemberId) {
        queryString += '&releaseMemberId=' + payload.releaseMemberId;
      }
      if (payload.reducedMemberId) {
        queryString += '&reducedMemberId=' + payload.reducedMemberId;
      }
      if (payload.clubId) {
        queryString += '&clubId=' + payload.clubId;
      }
      return queryString;
    },

    // Wizard Methods
    // eslint-disable-next-line no-unused-vars
    async loadActiveWizards(payload) {
      const response = await ApiClient.getRequest(WIZARD_BASE_PATH + '/activeMemberWizards?showAfterLogin=' + payload);
      if (response?.data) {
        return response.data;
      }
      return null
    },

    async loadWizardData(wizardId) {
      const response = await ApiClient.getRequest(WIZARD_BASE_PATH + '/data/' + wizardId);
      if (response) {
        this.wizardData = response.data;
      }
    },

    async loadWizardDataByName(wizardName) {
      const response = await ApiClient.getRequest(WIZARD_BASE_PATH + '/dataByName/' + wizardName);
      if (response?.data) {
        this.wizardData = response.data;
        return response.data;
      }
      return null
    },

    async postWizardData(payload) {
      const response = await ApiClient.postRequestWithAuthorization(WIZARD_BASE_PATH + '/update', payload);
      // Invalid/No Wizard ID, Status, Data
      if (response) {
        return response;
      }
      return null;
    },

    async setWizardData(payload) {
      this.wizardData = payload.wizardData;
    },

    async setShowWizardsAfterLogin(payload) {
      this.showWizardsAfterLogin = payload
    },

    // 2FA
    async load2FAInfo(payload) {
      const response = await ApiClient.getRequest(TWO_FACTOR_BASE_PATH + "/check?memberId=" + payload);
      if (response?.data) {
        this.twoFactorActive = response.data;
      }
    },

    async load2FADevices(payload) {
      const response = await ApiClient.getRequest(TWO_FACTOR_BASE_PATH + "/devices?memberId=" + payload);
      if (response?.data) {
        this.twoFactorDevices = response.data
      }
    },

    async requestNewDevice() {
      const response = await ApiClient.getRequest(TWO_FACTOR_BASE_PATH + "/totp");
      if (response?.data) {
        return response.data;
      }
    },

    async submitNewDevice(payload) {
      return ApiClient.postRequestWithAuthorization(TWO_FACTOR_BASE_PATH + "/totp", payload);
    },

    async deleteDevice(deviceId) {
      return ApiClient.deleteRequest(TWO_FACTOR_BASE_PATH + "/totp?deviceId=" + deviceId);
    }
  },


  getters: {
    getMemberData: (state) => {
      if (state.memberData) {
        return state.memberData.stvMember;
      }
      return null
    },
    getReleaseMemberData: (state) => {
      return state.releaseMemberData;
    },
    isEmulatingMember: (state) => {
      return state.emulatingMember;
    },
    getSingleMemberData: (state) => {
      if (state.singleMemberData) {
        return state.singleMemberData;
      }
      return {};
    },
    getQrCodeSvg: (state) => {
      if (state.memberQrCode) {
        return state.memberQrCode;
      }
      return null;
    },
    getCurrentClubId: (state) => {
      return state.memberData.currentClubId;
    },
    getCurrentClubName: (state) => {
      return state.memberData.currentClubName;
    },
    getGoogleWalletLink: (state) => {
      return state.googleWalletLink;
    },
    getWizardIds: (state) => {
      if (state.wizardIds && !state.wizardsDone) {
        return state.wizardIds;
      }
      return [];
    },
    getWizardData: (state) => {
      if (state.wizardData) {
        return state.wizardData;
      }
      return [];
    },
    getSportsData: (state) => {
      if (state.memberData) {
        let sports = new Set([]);
        new Map(Object.entries(state.memberData.sportsAmateur)).forEach(function (value, key) {
          sports.add(key)
        });
        new Map(Object.entries(state.memberData.sportsCompetition)).forEach(function (value, key) {
          sports.add(key)
        });
        return [ ...sports ].sort();
      }
      return [];
    },
    getReleaseMemberSportsData: (state) => {
      if (state.releaseMemberData && (state.releaseMemberData.sportsAmateur || state.releaseMemberData.sportsCompetition)) {
        let sports = new Set([]);
        new Map(Object.entries(state.releaseMemberData.sportsAmateur)).forEach((v, k) => {
          sports.add(k);
        });
        new Map(Object.entries(state.releaseMemberData.sportsCompetition)).forEach((v, k) => {
          sports.add(k);
        });
        return [ ...sports ].sort();
      }
      return [];
    },
    getSingleMemberSportsData: (state) => {
      if (state.singleMemberData && (state.singleMemberData.sportsAmateur || state.singleMemberData.sportsCompetition)) {
        let sports = new Set([]);
        new Map(Object.entries(state.singleMemberData.sportsAmateur)).forEach((v, k) => {
          sports.add(k);
        });
        new Map(Object.entries(state.singleMemberData.sportsCompetition)).forEach((v, k) => {
          sports.add(k);
        });
        return [ ...sports ].sort();
      }
      return [];
    },
    getWizardsDone: (state) => {
      return state.wizardsDone
    },
    isShowWizardsAfterLogin: (state) => {
      return state.showWizardsAfterLogin
    },
    isClubAdminEnabled: (state) => {
      return state.clubAdmin.enabled;
    },
    getCountries: (state) => {
      return state.countries
    },
    getCountry: (state) => {
      return state.country
    },
    getCounties: (state) => {
      return state.counties
    },
    getCounty: (state) => {
      return state.county
    },

    // 2FA
    isTwoFactorActive: (state) => {
      return state.twoFactorActive
    },
    getTwoFactorDevices: (state) => {
      return state.twoFactorDevices
    },

    getAssociationFunctions() { //Verbandsadmin
      if (this.memberData?.stvMember.functions) {
        return this.memberData.stvMember.functions
          .filter(f => {
            return f.vereinAdmin && f.organId?.length <= CLUB_NUMBER_LENGTH_CUTOFF;
          })
          .filter((f, idx, self) => { //Filtert, um duplikate im gleichen Verein/Verband zu entfernen
            return idx === self.findIndex(f2 => (f.organId === f2.organId));
          });
      }
      return [];
    },
    // Club
    getClubFunctions() {
      if (this.memberData?.stvMember.functions) {
        return this.memberData.stvMember.functions
          .filter(f => {
          return f.vereinAdmin && f.organId?.length > CLUB_NUMBER_LENGTH_CUTOFF;
        })
          .filter((f, idx, self) => { //Filtert, um duplikate im gleichen Verein/Verband zu entfernen
          return idx === self.findIndex(f2 => (f.organId === f2.organId));
        });
      }
      return [];
    },
    getClubFunctionsLight() {
      if (this.memberData?.stvMember.functions) {
        return this.memberData.stvMember.functions.filter(f => {
          if(this.getClubFunctions.filter(f2 => f2.organId === f.organId).length === 0) {
            return f.vereinAdminLight
          }
        }).filter((f, idx, self) => { //Filtert, um duplikate im gleichen Verein/Verband zu entfernen
          return idx === self.findIndex(f2 => (f.organId === f2.organId));
        });
      }
      return [];
    },
    getClubFunctionsLSPA() {
      if (this.memberData?.stvMember.functions) {
        return this.memberData.stvMember.functions.filter(f => {
          return f.vereinAdminLSPA
        })
          .filter((f, idx, self) => { //Filtert, um duplikate im gleichen Verein/Verband zu entfernen
          return idx === self.findIndex(f2 => (f.organId === f2.organId));
        });
      }
      return [];
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
