<template>
  <div :class="'img-'+ avatarClass" v-if="clubLogo">
    <img :src="clubLogo" alt="Avatar" :id="avatarId">
    <slot></slot>
  </div>
  <div v-else-if="!clubLogo && isNavigation" class="d-none d-lg-block d-xl-none">
    <i class="bi bi-person-circle"></i>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useClubStore } from "@/store/modules/club/club";

export default {
  props: {
    avatarClass: {
      type: String,
      required: false,
      default: 'portrait-small'
    },
    avatarId: {
      type: String,
      required: false
    },
    isNavigation: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    ...mapState(useClubStore, {clubLogo: "clubLogo"})
  },
}
</script>
