<template>
  <ul id="association-menu" class="nav flex-column profile-menu mb-5" v-if="isAssociationAdmin">
    <li class="nav-item mb-2">
      <strong>{{ $t('breadcrumb.association.association') }}</strong>
    </li>
    <li class="nav-item">
      <router-link :to="$router.resolve({name: urls_association.ASSOC_EDIT_PROFILE.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_EDIT_PROFILE.name}">
          {{ $t('breadcrumb.association.editProfile') }}
        </a>
      </router-link>
    </li>
  </ul>

  <ul class="nav flex-column profile-menu mb-5" v-if="getClub.status === 200">
    <li class="nav-item mb-2">
      <strong>{{ $t('breadcrumb.club.club') }}</strong>
    </li>
    <li class="nav-item">
      <router-link :to="$router.resolve({name:'club-profile', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'club-profile'}">
          {{ $t('breadcrumb.club.editProfile') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="$router.resolve({name:'club-squads', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'club-squads'}">
          {{ $t('breadcrumb.club.editSquads') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser && !isEmulatingMember">
      <router-link :to="$router.resolve({name:'club-groups', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isGroupsActive}">
          {{ $t('breadcrumb.club.editGroups') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser && !isEmulatingMember">
      <router-link :to="$router.resolve({name:'club-payment', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'change-password'}">
          {{ $t('breadcrumb.club.editPaymentMethods') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-sync', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'club-sync'}">
          {{ $t('breadcrumb.club.sync') }}
        </a>
      </router-link>
    </li>

    <li class="nav-item mt-5 mb-2">
      <strong>{{ $t('breadcrumb.club.members') }}</strong>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-members', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'club-members'}">
          {{ $t('breadcrumb.club.editMembers') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-membercards', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isSecurityActive}">
          {{ $t('breadcrumb.club.membercards') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-sponsors-partners', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isSecurityActive}">
          {{ $t('breadcrumb.club.partnersAndSponsors') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-honors', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isHonorsActive}">
          {{ $t('breadcrumb.club.honors') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-functions', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === 'club-functions'}">
          {{ $t('breadcrumb.club.functionsAndActivities') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" v-if="isUser">
      <router-link :to="$router.resolve({name:'club-membership-figures', query: {clubId: getClub.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: isSecurityActive}">
          {{ $t('breadcrumb.club.membershipFigures') }}
        </a>
      </router-link>
    </li>
  </ul>

</template>

<script>
import {mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import urls_association from "@/router/urls_association";

export default {
  computed: {
    urls_association() {
      return urls_association
    },
    isSecurityActive() {
      const securityRoutes = [
        'change-two-factor-settings',
        'add-device',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return securityRoutes.indexOf(currentRoute) > -1;
    },
    isEmailActive() {
      const emailRoutes = [
        'change-email',
        'confirm-email',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return emailRoutes.indexOf(currentRoute) > -1;
    },
    isGroupsActive() {
      const groupRoutes = [
        'club-groups',
        'institute-members',
        'institute-functions',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return groupRoutes.indexOf(currentRoute) > -1;
    },
    isHonorsActive() {
      const honorRoutes = [
        'club-honors',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return honorRoutes.indexOf(currentRoute) > -1;
    },
    ...mapState(
      useLoginStore, {isLoggedIn: "isLoggedIn", isUser: "isUser", isClubAdmin: "isClubAdmin", isAssociationAdmin: "isAssociationAdmin"},
    ),
    ...mapState(
      useMemberStore, {
        memberData: "getMemberData",
        isEmulatingMember: "isEmulatingMember",
      }
    ),
    ...mapState(
      useClubStore, {
        getClub: "getClubData"
      }
    )
  },
};
</script>

