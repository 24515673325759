<template>
  <div class="club-container">
    <div class="row">
      <div class="col-12">
        <h1 class="fs-5 fs-md-3 mb-5">{{ props.title }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3" v-if="props.showMenu">
        <AssociationMenu v-if="isLoggedIn"/>
        <slot name="menu"/>
      </div>
      <div class="col-12" :class="{
        'col-md-5': $slots.aside,
        'col-md-9': !$slots.aside && props.showMenu
      }" v-if="$slots.content">
        <slot name="content"/>
      </div>
      <div class="col-12 col-md-4" v-if="$slots.aside">
        <slot name="aside"/>
      </div>
    </div>
    <div class="row" v-if="$slots.default">
      <slot/>
    </div>
  </div>
  <LoaderOverlay v-if="props.isLoading"/>
</template>

<script setup>
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import AssociationMenu from "@/components/navigation/AssociationMenu.vue";

import {useLoginStore} from "@/store/modules/login/login";
import {computed, onBeforeMount, onBeforeUnmount} from "vue";

const loginStore = useLoginStore();
const isLoggedIn = computed(() => loginStore.isLoggedIn);


const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  showMenu: {
    type: Boolean,
    required: false,
    default: true,
  },
});

onBeforeMount(() => {
  document.body.classList.add('bg-white');
});

onBeforeUnmount(() => {
  document.body.classList.remove('bg-white');
});
</script>
