import {createRouter, createWebHistory} from "vue-router";
import urls from "./urls";
import urls_association from "./urls_association";
import logger from "@/logger/index.js";
import LoginView from "@/views/LoginView.vue";
import StartView from "@/views/StartView.vue";
import MembercardView from "@/views/membercard/MembercardView.vue";
import ShowProfileView from "@/views/profile/ShowProfileView";
import ChangeProfileView from "@/views/profile/ChangeProfileView";
import ChangePasswordView from "@/views/profile/ChangePasswordView";
import ChangeEmailView from "@/views/profile/ChangeEmailView";
import ConfirmEmailView from "@/views/profile/ConfirmEmailView";
import ChangeBirthdayView from "@/views/profile/ChangeBirthdayView";
import ForgotPasswordView from "@/views/password/ForgotPasswordView.vue";
import PasswordResetView from "@/views/password/PasswordResetView.vue";
import OnboardingView from "@/views/OnboardingView";
import CreateAccountView from "@/views/profile/CreateAccountView.vue";
import ClubMembersView from "@/views/ClubMembersView";
import SingleMemberView from "@/views/membercard/SingleMemberView.vue";
import FormWizardView from "@/views/FormWizardView";
import ChangeSportsView from "@/views/profile/ChangeSportsView";
import Change2FAView from "@/views/profile/Change2FAView";
import AddTwoFactorDeviceView from "@/views/profile/AddTwoFactorDeviceView";
import MemberOffersView from "@/views/offers/MemberOffersView.vue";

/* Imports Stufe Verein */
import EditProfileView from "@/views/club/EditProfileView.vue";
import ShowFiguresView from "@/views/club/ShowFiguresView.vue";
import ShowFunctionsAndActivitiesView from "@/views/club/ShowFunctionsAndActivitiesView.vue";
import ShowInstitutesView from "@/views/club/institutes/ShowInstitutesView.vue";
import ShowInstituteMembersView from "@/views/club/institutes/ShowInstituteMembersView.vue";
import ShowInstituteFunctionsView from "@/views/club/institutes/ShowInstituteFunctionsView.vue";
import ShowHonorsView from "@/views/club/honors/ShowHonorsView.vue";
import ShowMembercardsView from "@/views/club/ShowMembercardsView.vue";
import ShowMembersView from "@/views/club/ShowMembersView.vue";
import ClubPaymentView from "@/views/club/ClubPaymentView.vue";
import ShowSponsorsAndPartnersView from "@/views/club/ShowSponsorsAndPartnersView.vue";
import ShowSquadsView from "@/views/club/ShowSquadsView.vue";
import ShowSyncView from "@/views/club/ShowSyncView.vue";
import ShowLSPAView from "@/views/club/ShowLSPAView.vue";
import EditMemberView from "@/views/club/EditMemberView.vue";
import EditSponsorView from "@/views/club/EditSponsorView.vue";

import NotFound from "@/views/NotFound";

import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useProfileStore} from "@/store/modules/profile/profile.js";
import {useClubStore} from "@/store/modules/club/club.js";
import {useContactStore} from "@/store/modules/contact/contact";
import {useSquadStore} from "@/store/modules/squad/squad";
import {useInstituteStore} from "@/store/modules/institute/institute";
import {useClubMemberStore} from "@/store/modules/clubMember/clubMember.js";
import {useMemberShipStore} from "@/store/modules/membership/membership";
import {useHonorsStore} from "@/store/modules/honors/honors";

const routes = [
  {
    path: urls.ROOT_PAGE,
    name: "rootPage",
    component: StartView,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  },
  {
    path: urls.LOGIN_PAGE,
    name: "login",
    component: LoginView,
  },
  {
    path: urls.FORGOT_PASSWORD,
    name: 'forgot-password',
    component: ForgotPasswordView,
  },
  {
    path: urls.PASSWORD_RESET,
    name: 'password-reset',
    component: PasswordResetView,
  },
  {
    path: urls.ONBOARDING,
    name: 'onboarding',
    component: OnboardingView,
  },
  {
    path: urls.CREATE_ACCOUNT,
    name: 'create-account',
    component: CreateAccountView,
  },
  {
    path: urls.FORM_WIZARD,
    name: "wizard",
    component: FormWizardView,
    meta: {
      requiresAuth: true,
      keepAlive: false
    }
  },
  {
    path: urls.START_PAGE,
    name: "start",
    component: StartView,
    meta: {
      requiresAuth: true,
      keepAlive: false
    },
  },
  {
    path: urls.MEMBERCARD_PAGE,
    name: "membercard",
    component: MembercardView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_MEMBERS_PAGE,
    name: 'clubMembers',
    component: ClubMembersView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.SINGLE_MEMBER_PAGE,
    name: 'singleMember',
    component: SingleMemberView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.USER_PROFILE,
    name: 'profile',
    component: ShowProfileView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_ADDRESS,
    name: 'change-address',
    component: ChangeProfileView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_PASSWORD,
    name: 'change-password',
    component: ChangePasswordView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_EMAIL,
    name: 'change-email',
    component: ChangeEmailView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CONFIRM_EMAIL,
    name: 'confirm-email',
    component: ConfirmEmailView,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_BIRTHDAY,
    name: 'change-birthday',
    component: ChangeBirthdayView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_SPORTS,
    name: 'change-sports',
    component: ChangeSportsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.USER_PROFILE_CHANGE_2FA,
    name: 'change-two-factor-settings',
    component: Change2FAView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.ADD_2FA_DEVICE,
    name: 'add-device',
    component: AddTwoFactorDeviceView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.MEMBER_OFFERS,
    name: 'member-offers',
    component: MemberOffersView,
    meta: {
      requiresAuth: true,
    }
  },

  /* Routen für Verein */
  {
    path: urls.CLUB_EDIT_PROFILE,
    name: "club-profile",
    component: EditProfileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_FIGURES,
    name: "club-membership-figures",
    component: ShowFiguresView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_FUNCTIONS_AND_ACTIVITIES,
    name: "club-functions",
    component: ShowFunctionsAndActivitiesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_GROUPS,
    name: "club-groups",
    component: ShowInstitutesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_GROUP_MEMBERS,
    name: "institute-members",
    component: ShowInstituteMembersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_GROUP_FUNCTIONS,
    name: 'institute-functions',
    component: ShowInstituteFunctionsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls.CLUB_SHOW_HONORS.path,
    name: urls.CLUB_SHOW_HONORS.name,
    component: ShowHonorsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_MEMBERCARDS,
    name: "club-membercards",
    component: ShowMembercardsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_MEMBERS,
    name: "club-members",
    component: ShowMembersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_PAYMENTS,
    name: "club-payment",
    component: ClubPaymentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_SPONSORS,
    name: "club-sponsors-partners",
    component: ShowSponsorsAndPartnersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_SQUADS,
    name: "club-squads",
    component: ShowSquadsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: urls.CLUB_SHOW_SYNC,
    name: "club-sync",
    component: ShowSyncView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: urls.CLUB_EDIT_MEMBER,
    name: "club-edit-member",
    component: EditMemberView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: urls.CLUB_EDIT_SPONSOR,
    name: "club-edit-sponsor",
    component: EditSponsorView,
    meta: {
      requiresAuth: true,
    },
  },

  /* Routen für Verein LSPA */
  {
    path: urls.CLUB_SHOW_LSPA,
    name: "club-lspa",
    component: ShowLSPAView,
    meta: {
      requiresAuth: true,
    },
  },

  /* Routen für Verband */
  {
    path: urls_association.ASSOC_EDIT_PROFILE.path,
    name: urls_association.ASSOC_EDIT_PROFILE.name,
    component: urls_association.ASSOC_EDIT_PROFILE.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_PAYMENTS.path,
    name: urls_association.ASSOC_SHOW_PAYMENTS.name,
    component: urls_association.ASSOC_SHOW_PAYMENTS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_GROUP_MEMBERS.path,
    name: urls_association.ASSOC_SHOW_GROUP_MEMBERS.name,
    component: urls_association.ASSOC_SHOW_GROUP_MEMBERS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_GROUP_FUNCTIONS.path,
    name: urls_association.ASSOC_SHOW_GROUP_FUNCTIONS.name,
    component: urls_association.ASSOC_SHOW_GROUP_FUNCTIONS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_GROUPS.path,
    name: urls_association.ASSOC_SHOW_GROUPS.name,
    component: urls_association.ASSOC_SHOW_GROUPS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_SYNC.path,
    name: urls_association.ASSOC_SHOW_SYNC.name,
    component: urls_association.ASSOC_SHOW_SYNC.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_CLUBS.path,
    name: urls_association.ASSOC_SHOW_CLUBS.name,
    component: urls_association.ASSOC_SHOW_CLUBS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_CLUB_MEMBERS.path,
    name: urls_association.ASSOC_SHOW_CLUB_MEMBERS.name,
    component: urls_association.ASSOC_SHOW_CLUB_MEMBERS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_LSPA_MEMBERS.path,
    name: urls_association.ASSOC_SHOW_LSPA_MEMBERS.name,
    component: urls_association.ASSOC_SHOW_LSPA_MEMBERS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_ETAT.path,
    name: urls_association.ASSOC_SHOW_ETAT.name,
    component: urls_association.ASSOC_SHOW_ETAT.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_MEMBERS.path,
    name: urls_association.ASSOC_SHOW_MEMBERS.name,
    component: urls_association.ASSOC_SHOW_MEMBERS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_EDIT_MEMBER.path,
    name: urls_association.ASSOC_EDIT_MEMBER.name,
    component: urls_association.ASSOC_EDIT_MEMBER.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_MEMBER_NUMBERS.path,
    name: urls_association.ASSOC_SHOW_MEMBER_NUMBERS.name,
    component: urls_association.ASSOC_SHOW_MEMBER_NUMBERS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_LSPA.path,
    name: urls_association.ASSOC_SHOW_LSPA.name,
    component: urls_association.ASSOC_SHOW_LSPA.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_HONORS.path,
    name: urls_association.ASSOC_SHOW_HONORS.name,
    component: urls_association.ASSOC_SHOW_HONORS.component,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: urls_association.ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES.path,
    name: urls_association.ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES.name,
    component: urls_association.ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES.component,
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, _, next) => {
  const profileStore = useProfileStore();
  const memberStore = useMemberStore();
  const clubStore = useClubStore();
  const clubMemberStore = useClubMemberStore();
  const instituteStore = useInstituteStore();
  const contactStore = useContactStore();
  const loginStore = useLoginStore();
  const squadStore = useSquadStore();
  const membershipStore = useMemberShipStore();
  const honorsStore = useHonorsStore();
  await loginStore.init().then(async () => {

    // if navigating to login, check if logged in, if yes, redirect
    if (to.matched.some((record) => record.path === urls.LOGIN_PAGE)) {
      if (loginStore.isLoggedIn) {
        _.fullPath = '/';
        next({path: urls.START_PAGE});
        return;
      }
    }

    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!loginStore.isLoggedIn) {
        next({path: urls.LOGIN_PAGE});
        return;
      }
        if (memberStore.getMemberData === undefined || memberStore.getMemberData === null) {
          await memberStore.loadMemberData(true, false);
        }
        if (profileStore.getProfileImage === undefined || profileStore.getProfileImage === null) {
          await profileStore.getPhotoAsBase64(memberStore.getMemberData);
        }

        if (to.path.startsWith(urls_association.ASSOC_BASE_PATH) && to.query.associationId !== undefined && to.query.associationId !== null) {
          await clubStore.loadClubData(to.query.associationId);


          if (to.name === urls_association.ASSOC_SHOW_PAYMENTS.name) {
            await contactStore.loadContactBankAccount(to.query.associationId, false, true);
          }

          if (to.name === urls_association.ASSOC_SHOW_SYNC.name) {
            await contactStore.loadClubInterface(to.query.associationId);
          }

          if (to.name === urls_association.ASSOC_EDIT_MEMBER.name) {
            await memberStore.loadSingleMemberData(to.query.memberId, to.query.associationId);
            await Promise.allSettled([
              clubMemberStore.loadHonorsAssociation(to.query.associationId, to.query.memberId),
              clubMemberStore.loadAssociationAddressRelations(to.query.associationId, to.query.memberId),
              clubMemberStore.loadAssociationInstituteAddresses(to.query.associationId, to.query.memberId),
              clubMemberStore.loadAssociationAddressActions(to.query.associationId, to.query.memberId),
              instituteStore.loadInstitutesForAssociation(to.query.associationId),
              clubStore.loadAssociationClubs(to.query.associationId),
              membershipStore.loadMembershipDataForAllClubs(to.query.memberId),
              membershipStore.loadCategories(),
              membershipStore.loadAdditions(),
              membershipStore.loadSquadsByClub(to.query.associationId) ])
          }

          if (to.name === urls_association.ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES.name) {
            await Promise.allSettled([
              clubStore.loadClubAddressRelations(to.query.associationId)
            ]);
          }

          if (to.name === urls_association.ASSOC_SHOW_HONORS.name) {
            await honorsStore.loadClubHonors(to.query.associationId);
            await honorsStore.loadMemberHonorsByClub(to.query.associationId);
          }
        }

        if (to.path.startsWith('/club') && to.query.clubId !== undefined && to.query.clubId !== null) {
          await clubStore.loadClubData(to.query.clubId);

          if (to.path.startsWith('/club/payment')) {
            await contactStore.loadContactBankAccount(to.query.clubId, false, true);
          } else if (to.path.startsWith('/club/squads')) {
            await squadStore.loadSquadsByClubId(to.query.clubId);
          } else if (to.path.startsWith('/club/editProfile')) {
            await clubStore.getVereinLogoAsBase64(to.query.clubId);
          } else if (to.path.startsWith('/club/edit-member') || to.path.startsWith('/club/edit-sponsor')) {
            if (to.query.memberId !== 'new') {
              await memberStore.loadSingleMemberData(to.query.memberId, to.query.clubId);
              await Promise.allSettled([
                clubMemberStore.loadHonors(to.query.clubId, to.query.memberId),
                clubMemberStore.loadClubAddressRelations(to.query.clubId, to.query.memberId),
                clubMemberStore.loadClubInstituteAddresses(to.query.clubId, to.query.memberId),
                clubMemberStore.loadAddressActions(to.query.clubId, to.query.memberId),
                instituteStore.loadInstitutesForClub(to.query.clubId),
                membershipStore.loadMembershipData(to.query.clubId, to.query.memberId),
                membershipStore.loadCategories(),
                membershipStore.loadAdditions(),
                membershipStore.loadSquadsByClub(to.query.clubId) ])
            } else {
              await memberStore.clearSingleMemberData();
              await clubMemberStore.clearClubData();
            }
          } else if (to.name === 'club-sync') {
            await contactStore.loadClubInterface(to.query.clubId);
          }

          if (to.name === 'club-groups') {
            await instituteStore.loadInstitutesForClub(to.query.clubId);
          }

          if (to.name === 'institute-members') {
            await Promise.allSettled([
              instituteStore.loadInstitute(to.query.code, to.query.clubId),
              instituteStore.loadInstituteFunctionsForClub(to.query.clubId),
              instituteStore.loadInstitueAddressesForClub(to.query.clubId, to.query.code),
              clubStore.loadClubMembers(to.query.clubId, true),
            ]);
          }

          if (to.name === 'institute-functions') {
            await instituteStore.loadInstituteFunctionsForClub(to.query.clubId);
          }

          if (to.name === 'club-functions') {
            await clubStore.loadClubAddressRelations(to.query.clubId);
          }

          if (to.name === urls.CLUB_SHOW_HONORS.name) {
            await honorsStore.loadClubHonors(to.query.clubId);
            await honorsStore.loadMemberHonorsByClub(to.query.clubId);
          }

          next();
          return;
        }
        if (to.path === '/wizard' && to.query?.id) {
          await memberStore.loadWizardData(to.query.id);
        } else {
          const wizardIds = await memberStore.loadActiveWizards(memberStore.isShowWizardsAfterLogin);
          if (wizardIds && wizardIds.length > 0) {
            let wizardId = null;
            for (let id in wizardIds) {
              if (!memberStore.wizardsDone.includes(wizardIds[id])) {
                wizardId = wizardIds[id];
                break
              }
            }
            if (wizardId !== null) {
              next({name: 'wizard', query: {id: wizardId}});
              return;
            } else {
              await memberStore.setShowWizardsAfterLogin(false);
            }
          } else if (to.fullPath === '/wizard') {
            _.fullPath = '/';
            next({path: urls.START_PAGE});
            return;
          }
        }

        if (to.fullPath === '/change-sports') {
          await memberStore.loadWizardDataByName('WIZARD_NAME_MEMBER_SPORTS')
        }

        next();
        return;

    } else if (to.matched.some((record) => !record.meta.requiresAuth)) {
      if ((to.path === '/password-initial' || to.path === '/password-reset') && loginStore.isLoggedIn) {
        await loginStore.logout()
      }

      if (loginStore.isLoggedIn) {
        if (memberStore.memberData == null || memberStore.memberData.memberId === undefined) {
          await memberStore.loadMemberData(true, false)
          await profileStore.getPhotoAsBase64(memberStore.memberData.memberId)
        }
      }
    }
    next();
  }).catch((e) => {
    logger.error("Error during routing: " + e)
    next();
  });

  // scroll to top
  window.scrollTo(0, 0);

});

export default router;
