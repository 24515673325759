<template>
  <input type="email" :id="id" class="form-control"
    :class="getClass"
    autocomplete="off"
    :placeholder="placeholder"
    :value="modelValue"
    @input="updateValue"
    @keyup="analyzeEmail"
    @change="analyzeEmail"
    :disabled="disabled"
    :required="required">
  <label :for="id" :class="labelClass" v-if="label !== null">{{ label }}</label>
  <div class="invalid-feedback" v-if="!lengthValid">{{ $t('profile.email.wrongEmail') }}</div>
  <div class="invalid-feedback" v-if="!emailValid">{{ $t('profile.email.wrongEmail') }}</div>
</template>
  
<script>
  export default {
    data() {
      return {
        emailValid: true,
        lengthValid: true
       };
    },
    emits: ["onChange", "update:modelValue"],
    props: {
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      id: {
        type: String,
        required: false,
        default: null
      },
      label: {
        type: String,
        required: false,
        default: null
      },
      labelClass: {
        type: String,
        required: false,
        default: 'form-label'
      },
      placeholder: {
        type: String,
        required: false,
        default: ''
      },
      maxLength: {
        type: Number,
        required: false,
        default: 80
      },
      modelValue: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      modelModifiers: {
        default: () => ({})
      }
    },

    methods: {
      analyzeEmail() {
        this.lengthValid = this.modelValue !== undefined && this.modelValue.length <= this.maxLength;

        if(this.required && (this.modelValue === undefined || this.modelValue === '')) {
          this.email = false;
        } else if(!this.required && (this.modelValue === undefined || this.modelValue === '')) {
          this.emailValid = true;
        } else {
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
          this.emailValid = emailRegex.test(this.modelValue);
        }

        this.$emit("onChange", this.emailValid);
      },
      updateValue(event) {
        let value = event.target.value
        if (this.modelModifiers.lowercase) {
          value = value.toLowerCase()
        }
        this.$emit('update:modelValue',value);
      }
    },

    computed: {
      getClass() {

        if(this.required) {
          return this.lengthValid && this.emailValid ? 'is-valid' : 'is-invalid'
        } else{
          if(this.modelValue === undefined || this.modelValue === '') {
            return '';
          } else {
            return this.lengthValid && this.emailValid ? 'is-valid' : 'is-invalid'
          }
        }

      },
    }
  };
</script>
  
<style scoped>
  
</style>
  