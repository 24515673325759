<template>
  <div class="mystv-container">
    <nav class="mx-2 mx-lg-3 meta-nav bg-white" style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 p-2 flex-nowrap justify-content-center align-items-center">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'start'}">{{ $t('menu.overview') }}</router-link>
        </li>
        <li class="breadcrumb-item" v-if="$route.name === 'club-membercards' && isClubAdmin">
          <router-link :to="{name: 'club-profile', query: {clubId: currentClubId}}">{{ $t('menu.manageClub') }}: {{ currentClubName }}</router-link>
        </li>
        <li class="breadcrumb-item" v-if="$route.name === 'singleMember' && isClubAdminLight">
          <router-link :to="{name: 'clubMembers', query: {clubId: currentClubId}}">{{ $t('menu.manageClub') }}: {{ currentClubName }}</router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>


<script>
import {mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";

export default {
  computed: {
    clubMemberships() {
      if (this.memberData.memberships) {
        return [...new Set(this.memberData.memberships.filter(m => {
          return (!!m.verein) && m.category && m.category.code === '17'
        }).flatMap(m => {
          return m.verein;
        }))].sort();
      }
      return [];
    },
    ...mapState(useMemberStore, {
      memberData: 'getMemberData',
      releaseMemberData: "getReleaseMemberData",
    }),
    ...mapState(useClubStore, {
      currentClubName: "getCurrentClubName",
      currentClubId: "getCurrentClubId"
    }),
    ...mapState(useLoginStore, {isClubAdmin: "isClubAdmin", isClubAdminLight: "isClubAdminLight"}),
    ...mapState(useMessagesStore, {isShowGlobalError: 'isShowGlobalError'})
  },
};
</script>
