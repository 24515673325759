<template>
  <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="isShowGlobalErrorMessages">
    <div v-for="error in getGlobalErrorMessages" :key="error.id">
      <i class="bi bi-exclamation-triangle-fill me-2"></i>
      <strong>{{ error.header }}</strong>: {{ error.detailText }}
    </div>
  </div>
  <div class="alert alert-warning d-flex align-items-center" role="alert" v-if="isShowGlobalWarningMessages">
    <div v-for="warning in getGlobalWarningMessages" :key="warning.id">
      <i class="bi bi-exclamation-triangle-fill me-2"></i>
      <strong>{{ warning.header }}</strong>: {{ warning.detailText }}
    </div>
  </div>
</template>

<script>
import {mapState} from "pinia";
import { useMessagesStore } from "@/store/modules/messages/messages.js";


export default {
  data() {
    return {
      showError: false,

    };
  },
  computed: {
    ...mapState(useMessagesStore, ["getGlobalErrorMessages", "isShowGlobalErrorMessages", "isShowGlobalWarningMessages", "getGlobalWarningMessages"]),
  },
  components: {},
};
</script>

<style>
.alert {
  margin-top: .5em;
  padding: .5em 1em;
}
</style>
