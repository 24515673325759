<template>
  <club-layout :title="clubData.status === 200 ? $t('club.functionsAndActivities.title') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="false">

    <template #content v-if="clubData.status === 200">
      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('club.functionsAndActivities.listTitle') }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <DataTable :value="addressRelations"
                     scrollable scroll-height="60vh"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     v-model:selection="selection"
                     data-key="entryNo"
                     v-model:filters="filters" filter-display="row"
                     ref="dt" :export-filename="$t('club.functionsAndActivities.title').replaceAll(' ', '_')" :export-function="csvExportTransformationFunction"
                     :loading="isLoading"
                     :paginator="addressRelations.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                     csv-separator=";"
                     row-hover
                     class="border-bottom">

            <template #empty>
              <div class="alert alert-info">{{ $t('club.functionsAndActivities.list.notFound') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column>
              <template #body="{data}">
                <div class="btn-group btn-group-sm pe-2">
                  <Button :title="$t('club.functionsAndActivities.edit.title')" :aria-label="$t('club.functionsAndActivities.edit.title')"
                          :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm"
                          unstyled class="btn btn-outline-dark" @click="showEditAddressRelationModal(data)">
                    <i class="bi bi-pencil"></i>
                  </Button>
                  <Button :title="$t('club.functionsAndActivities.delete.title')" :aria-label="$t('club.functionsAndActivities.delete.title')"
                          unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data)">
                    <i class="bi bi-trash"></i>
                  </Button>
                </div>
              </template>
            </Column>

            <Column v-for="col in columns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    sortable :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>

      <div class="row w-100 flex-center gap-2 mb-3 mt-3">
        <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
          <i class="bi bi-filetype-csv"></i>
        </Button>
        <Button :title="$t('club.functionsAndActivities.add.title')" :label="$t('club.functionsAndActivities.add.title')" :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm me-2" unstyled class="btn btn-outline-dark width-auto" icon="bi bi-plus-circle me-2" @click="showAddAddressRelationModal"/>
      </div>

      <!-- Tätigkeiten -->
      <div v-if="clubData.data.No">
        <ClubActivityTable :club-id="clubData.data.No"></ClubActivityTable>
        <MemberActivityTable :club-id="clubData.data.No"></MemberActivityTable>
      </div>

    </template>

    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>

  <Dialog v-model:visible="visibleAddAddressRelationModal" modal block-scroll :header="addressRelationData.Entry_No ? $t('club.functionsAndActivities.edit.title') : $t('club.functionsAndActivities.add.title')" :style="{width: '30rem'}" @hide="resetAddressRelationData">
    <div class="form-row mb-3" v-focustrap>
      <label for="functionCode" class="form-label">{{ $t('club.functionsAndActivities.add.selectFunction') }}</label>
      <Select input-id="functionCode" v-model="addressRelationData.Function_Code"
              :options="functions"
              option-value="Code"
              :option-label="$i18n.locale === 'fr' ? 'DescriptionFR' : 'Description'"
              filter
              :placeholder="$t('club.functionsAndActivities.add.selectFunction')"
              class="w-100"
              autofocus
              @update:modelValue="analyzeAddressRelationData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!dataValid.Function_Code">{{ $t('club.functionsAndActivities.errors.addFunctionCode') }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label for="contactNo" class="form-label">{{ $t('institutes.functions.selectMember') }}</label>
      <Select input-id="contactNo" v-model="addressRelationData.Contact_No"
              :options="clubMemberList"
              option-value="memberId"
              :option-label="getMemberName"
              filter
              :placeholder="$t('institutes.functions.selectMember')"
              :disabled="addressRelationData.Entry_No"
              class="w-100"
              @update:modelValue="analyzeAddressRelationData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!dataValid.Contact_No">{{ $t('club.functionsAndActivities.errors.addContact') }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label for="byDate" class="form-label">{{ $t('from') }}</label>
      <DatePicker input-id="byDate" v-model="addressRelationData.By_Date" :model-value="addressRelationData.By_Date ? new Date(addressRelationData.By_Date) : null" date-format="dd.mm.yy" showIcon class="w-100" @update:modelValue="analyzeAddressRelationData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!dataValid.By_Date">{{ $t('club.functionsAndActivities.errors.byDateInvalid') }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label for="toDate" class="form-label">{{ $t('to') }}</label>
      <DatePicker input-id="toDate" v-model="addressRelationData.To_Date" :model-value="addressRelationData.To_Date ? new Date(addressRelationData.To_Date) : null" date-format="dd.mm.yy" showIcon class="w-100" @update:modelValue="analyzeAddressRelationData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!dataValid.To_Date">{{ $t('club.functionsAndActivities.errors.toDateInvalid') }}</PrimeMessage>
    </div>
    <div class="d-flex justify-content-between">
      <Button :title="$t('club.functionsAndActivities.add.save')" :label="$t('club.functionsAndActivities.add.save')"
              unstyled class="btn btn-outline-primary"
              :disabled="!allDataValid || isSubmitted"
              @keyup.enter="submitAddressRelationData"
              @click="submitAddressRelationData"/>
      <div class="d-flex justify-content-end">
        <Button :title="$t('delete')" :label="$t('delete')" unstyled class="btn btn-outline-primary me-2" @click="confirmDelete($event, addressRelationData)" v-if="addressRelationData.Entry_No"/>
        <Button :title="$t('cancel')" :label="$t('cancel')" unstyled @click="visibleAddAddressRelationModal = false" class="btn btn-outline-secondary"/>
      </div>
    </div>
  </Dialog>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import ClubLayout from "@/components/club/ClubLayout.vue";

import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import Select from "primevue/select";
import DatePicker from "primevue/datepicker";
import PrimeMessage from "primevue/message";
import {useConfirm} from "primevue/useconfirm";
import {FilterMatchMode} from '@primevue/core/api';

import {computed, onMounted, ref} from "vue";
import {useClubStore} from "@/store/modules/club/club";
import {useI18n} from "vue-i18n";
import moment from "moment/moment";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";

import MemberActivityTable from "@/components/activity/MemberActivityTable.vue";
import ClubActivityTable from "@/components/activity/ClubActivityTable.vue";

const {locale, t} = useI18n();
const clubStore = useClubStore();
const messageStore = useMessagesStore();
const confirm = useConfirm();
const isSubmitted = ref(false);
const showSuccess = ref(false);
const showFailure = ref(false);
const visibleAddAddressRelationModal = ref(false);
const isModalLoading = ref(false);

const dt = ref();
const sortColumn = ref('function.Code');
const filters = ref({
  'function.Code': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.Description': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.DescriptionFR': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.memberId': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.salutation': {value: null, matchMode: FilterMatchMode.EQUALS},
  'stvMember.surname': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.firstName': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.birthday': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.address': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.postCode': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.city': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.emailPrivate': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.emailVerein': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.phoneMobile': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.phonePrivate': {value: null, matchMode: FilterMatchMode.CONTAINS},
  byDate: {value: null, matchMode: FilterMatchMode.CONTAINS},
  toDate: {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const isSelectable = ref(false);
const selection = ref([]);
const columns = [
  {field: 'function.Code', header: t('club.functionsAndActivities.list.code'), filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'function.Description', header: t('club.functionsAndActivities.list.function'), filterable: true, exportable: locale.value === 'de', visible: locale.value === 'de', type: 'String'},
  {field: 'function.DescriptionFR', header: t('club.functionsAndActivities.list.function'), filterable: true, exportable: locale.value === 'fr', visible: locale.value === 'fr', type: 'String'},
  {field: 'byDate', header: t('from'), filterable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'toDate', header: t('to'), filterable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'stvMember.memberId', header: t('club.member.stvNumber'), filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'stvMember.salutation', header: t('club.member.salutation'), filterable: true, exportable: true, visible: false, type: 'Salutation'},
  {field: 'stvMember.surname', header: t('personalData.person.surname'), filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'stvMember.firstName', header: t('personalData.person.firstName'), filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'stvMember.birthday', header: t('personalData.person.birthday'), filterable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'stvMember.address', header: t('club.member.address'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.postCode', header: t('club.member.postCode'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.city', header: t('club.member.city'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.emailPrivate', header: t('club.member.emailPrivate'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.emailVerein', header: t('club.member.emailVerein'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.phoneMobile', header: t('club.member.phoneMobile'), filterable: true, exportable: true, visible: false, type: 'String'},
  {field: 'stvMember.phonePrivate', header: t('club.member.phonePrivate'), filterable: true, exportable: true, visible: false, type: 'String'},
];
const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'byDate':
    case 'toDate':
    case 'stvMember.birthday':
      return formatEntry('Date', record.data);
    case 'stvMember.salutation':
      return formatEntry('Salutation', record.data);
    default:
      return String(record.data)
  }
}

const isLoading = computed(() => {
  return !(addressRelations.value && addressRelations.value.length);
});

const clubData = computed(() => {
  return clubStore.getClubData;
});

clubStore.loadClubMembers(clubData.value.data.No, false);
clubStore.loadFunctions(clubData.value.data.No);

const addressRelations = computed(() => {
  const clubAddressRelations = clubStore.getClubAddressRelations;
  return clubAddressRelations.sort((a, b) => a.function.Code - b.function.Code);
});

const functions = computed(() => {
  return clubStore.getFunctions;
});

const clubMemberList = computed(() => {
  return clubStore.getClubMembers;
});

const addressRelationData = ref({
  Entry_No: null,
  Contact_No: null,
  Function_Code: null,
  Relation_to_No: clubData.value.data.No,
  By_Date: null,
  To_Date: null,
});

const dataValid = ref({
  Entry_No: true,
  Contact_No: false,
  Function_Code: false,
  Relation_to_No: false,
  By_Date: true,
  To_Date: true,
});

const allDataValid = computed(() => {
  return allTrue(dataValid.value);
});

const resetAddressRelationData = () => {
  addressRelationData.value = {
    Entry_No: null,
    Contact_No: null,
    Function_Code: null,
    Relation_to_No: clubData.value.data.No,
    By_Date: null,
    To_Date: null,
  };
};

const analyzeAddressRelationData = () => {
  checkProperties(addressRelationData);
  isSubmitted.value = false;
};

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Contact_No') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'Function_Code') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'Relation_to_No') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'By_Date') {
      dataValid.value[key] = (!obj.value[key]) || dateValid(obj.value[key]);
    } else if (key === 'To_Date') {
      dataValid.value[key] = (!obj.value[key]) || dateValid(obj.value[key]);
    }
  }
};

const getMemberName = (member) => {
  return member.firstName + ' ' + member.surname;
};

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      return t('club.member.salutation' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const exportCSV = () => {
  dt.value.exportCSV({selectionOnly: selection.value.length > 0})
};

const confirmDelete = (event, addressRelationData) => {
  confirm.require({
    target: event.currentTarget,
    header: t('club.functionsAndActivities.delete.title'),
    message: t('club.functionsAndActivities.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      deleteAddressRelation(clubData.value.data.No, addressRelationData.entryNo);
    },
  });
};

const showEditAddressRelationModal = async (data) => {
  isModalLoading.value = true;
  addressRelationData.value = {
    Entry_No: data.entryNo,
    Contact_No: data.stvMember.memberId,
    Function_Code: data.function.Code,
    Relation_to_No: clubData.value.data.No,
    By_Date: dateValid(data.byDate) ? new Date(data.byDate) : null,
    To_Date: dateValid(data.toDate) ? new Date(data.toDate) : null,
  };
  analyzeAddressRelationData();
  await loadFunctionsAndMembersAndShowModal();
};

const showAddAddressRelationModal = () => {
  isModalLoading.value = true;
  analyzeAddressRelationData();
  loadFunctionsAndMembersAndShowModal();
};

const loadFunctionsAndMembersAndShowModal = async () => {
  if(clubMemberList.value.length === 0){
    await clubStore.loadClubMembers(clubData.value.data.No, false);
  }
  if(clubStore.getFunctions.length === 0) {
    await clubStore.loadFunctions(clubData.value.data.No);
  }
  visibleAddAddressRelationModal.value = true;
  isModalLoading.value = false;
};

const submitAddressRelationData = () => {
  if (allDataValid.value) {
    isSubmitted.value = true;
    saveAddressRelation().then(async response => {
      if (response?.status >= 200 && response?.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('club.functionsAndActivities.add.title'), '', t('club.functionsAndActivities.add.success'), false, 'COMPONENT'));
        visibleAddAddressRelationModal.value = false;
        await reloadAddressRelations(clubData.value.data.No);
      } else {
        showFailure.value = true;
        let combinedMessage = '';
        if (response.data) {
          combinedMessage = response.data;
        } else if (response.response.data.errors) {
          response.response.data.errors.forEach(e => {
            combinedMessage += (t(e) + '\r\n');
          });
        }
        messageStore.addError(new Message('error', true, false, t('club.functionsAndActivities.add.title'), '', t(combinedMessage), false, 'COMPONENT'));
      }
    }).catch(error => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.functionsAndActivities.add.title'), '', error, false, 'COMPONENT'));
    }).finally(async () => {
      isSubmitted.value = false;
    });
  }
};

const saveAddressRelation = async () => {
  return clubStore.saveAddressRelation(addressRelationData.value).then(response => {
    return response;
  });
}

const deleteAddressRelation = async (clubId, entryNo) => {
  clubStore.deleteAddressRelations(clubId, entryNo).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('club.functionsAndActivities.delete.title'), '', t('club.functionsAndActivities.delete.success'), false, 'COMPONENT'));
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.functionsAndActivities.delete.title'), '', t(r.data), false, 'COMPONENT'));
    }
  }).catch(e => {
    showFailure.value = true
    messageStore.addError(new Message('error', true, false, t('club.functionsAndActivities.delete.title'), '', e, false, 'COMPONENT'));
  }).finally(async () => {
    await reloadAddressRelations(clubData.value.data.No);
  });
};

const reloadAddressRelations = async (clubNo) => {
  await clubStore.loadClubAddressRelations(clubNo);
};

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && (value !== '' && value?.length < maxLength);
}

const dateValid = (value) => {
  const dateValue = moment(String(value));
  return dateValue.isValid() && dateValue.isAfter('1900-01-01');
};

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
};

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
});
</script>

