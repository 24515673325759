<template>
  <div class="row">
    <div class="col">
      <h5 class="mb-3">{{ $t('association.club.functions.title') }}</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <DataTable :value="associationClubAddressRelations"
                 scrollable scroll-height="60vh"
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 data-key="entryNo"
                 v-model:filters="filters" filter-display="row"
                 ref="dt" :export-filename="$t('association.club.functions.title').replaceAll(' ', '_')" :export-function="csvExportTransformationFunction"
                 :loading="isLoading"
                 :paginator="associationClubAddressRelations.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 csv-separator=";"
                 row-hover
                 class="border-bottom">

        <Column v-for="col in columns" :key="col.field" :field="col.field"
                :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header="col.header"
                :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                :exportable="col.exportable"
                :hidden="!col.visible">
          <template #body="{data}">
            {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
          </template>
          <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <div class="row w-100 flex-center gap-2 mb-3 mt-3">
    <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>

</template>

<script setup>
import {onBeforeMount, ref, watch} from "vue";
import {FilterMatchMode} from "@primevue/core/api";
import {useI18n} from "vue-i18n";
import ApiClient from "@/api/ApiClient";
import moment from "moment";
import Button from "primevue/button";

const {t, locale} = useI18n();

const props = defineProps({
  associationId: String,
});

const isLoading = ref(false);
const associationClubAddressRelations = ref([]);

const dt = ref();
const sortColumn = ref('function.code');
const filters = ref({
  'clubNo': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'clubName': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.Code': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.Description': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'function.DescriptionFR': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'stvMember.surname': {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const columns = [
  {field: 'clubNo', header: t('club.nr'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'clubName', header: t('club.name'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'function.Code', header: t('club.functionsAndActivities.list.code'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'function.Description', header: t('club.functionsAndActivities.list.function'), exportable: locale.value === 'de',
    visible: locale.value === 'de', sortable: true, filterable: true, type: 'String'},
  {field: 'function.DescriptionFR', header: t('club.functionsAndActivities.list.function'), exportable: locale.value === 'fr',
    visible: locale.value === 'fr', sortable: true, filterable: true, type: 'String'},
  {field: 'byDate', header: t('from'), exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'toDate', header: t('to'), exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'stvMember.memberId', header: t('club.member.stvNumber'), exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'stvMember.salutation', header: t('club.member.salutation'), exportable: true, visible: false, sortable: true, type: 'Salutation'},
  {field: 'stvMember.surname', header: t('personalData.person.surname'), exportable: true, visible: true, sortable: true, filterable: true, type: 'String'},
  {field: 'stvMember.firstName', header: t('personalData.person.firstName'), exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'stvMember.birthday', header: t('personalData.person.birthday'), exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'stvMember.address', header: t('club.member.address'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.postCode', header: t('club.member.postCode'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.city', header: t('club.member.city'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.emailPrivate', header: t('club.member.emailPrivate'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.emailVerein', header: t('club.member.emailVerein'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.phoneMobile', header: t('club.member.phoneMobile'), exportable: true, visible: false, sortable: true, type: 'String'},
  {field: 'stvMember.phonePrivate', header: t('club.member.phonePrivate'), exportable: true, visible: false, sortable: true, type: 'String'},
];

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'byDate':
    case 'toDate':
    case 'stvMember.birthday':
      return formatEntry('Date', record.data);
    case 'stvMember.salutation':
      return formatEntry('Salutation', record.data);
    default:
      return String(record.data)
  }
};

const exportCSV = () => {
  dt.value.exportCSV();
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      return t('club.member.salutation' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const loadAssociationClubActivities = async () => {
  isLoading.value = true;
  const request = '/api/association/list-function-members?associationId=' + props.associationId;
  ApiClient.getRequest(request).then(response => {
    if (response?.status === 200) {
      isLoading.value = false;
      associationClubAddressRelations.value = response.data;
    }
  }).catch(error => {
    console.log('error getting club activities', error);
  });
};

watch(props, () => {
  loadAssociationClubActivities();
});

onBeforeMount(() => {
  loadAssociationClubActivities();
});
</script>

<style scoped>

</style>
