import { uuid } from "vue-uuid";

export default class Message {

    constructor(type, showToast, showMessage, header, smallText, detailText, global, category) {
        this.id = uuid.v4();
        this.type = type;
        this.showToast = showToast;
        this.showMessage = showMessage;
        this.header = header;
        this.smallText = smallText;
        this.detailText = detailText;
        this.global = global;
        this.category = category;
    }
}
