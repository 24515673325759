import axios from "axios";
import logger from "@/logger/index.js";
import Message from "../../../helpers/message";
import i18n from "@/i18n.js";
import ApiClient from "@/api/ApiClient";
import {Buffer} from 'buffer';
import {defineStore} from "pinia";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useProfileStore} from "@/store/modules/profile/profile.js";
import {useLocalStorage} from "@vueuse/core"

const kc_token = "kc_token";
const kc_refresh_token = "kc_refresh_token";
const kc_id_token = "kc_id_token";

const USER_BASE_PATH = '/api/user';
const TOTP_PATH = USER_BASE_PATH + '/2fa';

const KEYCLOAK_SEARCH_PARAMS = {
  "client_id": window.VUE_APP_KEYCLOAK_CLIENT_ID,
  "grant_type": "password",
  "scope": "openid",
};
const KEYCLOAK_HEADERS = {
  "Content-Type": "application/x-www-form-urlencoded"
};

// eslint-disable-next-line no-unused-vars
const {global: {locale, t},} = i18n;

let kcToken = useLocalStorage(kc_token, localStorage.getItem(kc_token))
let kcRefreshToken = useLocalStorage(kc_refresh_token, localStorage.getItem(kc_refresh_token))
let kcIdToken = useLocalStorage(kc_id_token, localStorage.getItem(kc_id_token))

function decodeToken(str) {
  str = str.split('.')[1];

  str = str.replace(/-/g, '+');
  str = str.replace(/_/g, '/');
  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += '==';
      break;
    case 3:
      str += '=';
      break;
    default:
      throw 'Invalid token';
  }

  str = decodeURIComponent(encodeURI(Buffer.from(str, 'base64')));
  str = JSON.parse(str);
  return str;
}

export const useLoginStore = defineStore("login", {
  state: () => {
    return {
      memberData: null,
      wizardIds: null,
      wizardData: null,
      useCache: true,
      googleWalletLink: '',
      wizardsDone: [],
      showWizardsAfterLogin: true,
      loggedIn: null,
      clientInitialized: false,
      trigger: null,
      has2fa: true,
      mySTVAdmin: false,
      clubAdmin: false,
      lspaAdmin: false,
      member: false,
    };
  },

  actions: {
    async init() {
      return Promise.all([
        this.checkIfAlreadyLoggedIn()
      ]);
    },

    async login(payload) {
      const messageStore = useMessagesStore();
      KEYCLOAK_SEARCH_PARAMS.username = payload.username;
      KEYCLOAK_SEARCH_PARAMS.password = payload.password;

      if (payload.totp) {
        KEYCLOAK_SEARCH_PARAMS.totp = payload.totp;
      }
      const params = new URLSearchParams(KEYCLOAK_SEARCH_PARAMS);

      messageStore.clearMessages();
      await axios.post(
        window.VUE_APP_KEYCLOAK_URL + "realms/" + window.VUE_APP_KEYCLOAK_REALM + "/protocol/openid-connect/token",
        params,
        {headers: KEYCLOAK_HEADERS, timeout: 5000})
        .then(async (response) => {
          if (response.status !== 200) {
            messageStore.addError(new Message('error', false, true, t("errorMessages.generic_error"), '', response.statusText, true, 'LOGIN'));
            return;
          }

          this.keycloak.refreshToken = response.data.refresh_token
          this.keycloak.idTokenParsed = decodeToken(response.data.id_token);
          this.keycloak.idToken = response.data.id_token
          this.keycloak.idTokenParsed = decodeToken(response.data.id_token);

          this.keycloak.token = response.data.access_token;
          this.keycloak.tokenParsed = decodeToken(response.data.access_token);
          this.keycloak.sessionId = this.keycloak.tokenParsed.session_state;
          this.keycloak.authenticated = true;
          this.keycloak.subject = this.keycloak.tokenParsed.sub;
          this.keycloak.realmAccess = this.keycloak.tokenParsed.realm_access;
          this.keycloak.resourceAccess = this.keycloak.tokenParsed.resource_access;

          this.setData(this.keycloak.token, this.keycloak.refreshToken, this.keycloak.idToken, this.keycloak.tokenParsed.preferred_username)
          await this.initRefresh();

          messageStore.clearMessages();

        }).catch((error) => {
          if (error?.response?.data) {
            if (error.response.status === 401) {
              logger.error("Unable to login: " + error.response.data.error_description);
              if (error.response.data.error_description === 'Invalid user credentials') {
                messageStore.addError(new Message('error', false, true, t("errorMessages.invalid_credentials_header"), '', t("errorMessages.invalid_credentials_text"), false, 'LOGIN'));
                return;
              }
            }
          }

          if (error.messages) {
            messageStore.addError(new Message('error', false, true, t("errorMessages.network_error_text"), '', error.message, true, 'LOGIN'));
          } else {
            messageStore.addError(new Message('error', false, true, t("errorMessages.generic_error"), '', error.statusText, true, 'LOGIN'));
          }
        });

    },
    async logout() {
      const params = new URLSearchParams({
        "client_id": window.VUE_APP_KEYCLOAK_CLIENT_ID,
        "refresh_token": this.keycloak.refreshToken
      })

      await axios.post(
        window.VUE_APP_KEYCLOAK_URL + "realms/" + window.VUE_APP_KEYCLOAK_REALM + "/protocol/openid-connect/logout",
        params,
        {headers: KEYCLOAK_HEADERS, timeout: 5000})
        .then((response) => {
          logger.debug("Initiate logout...");
          if (response.status !== 204) {
            throw new Error('Fehler beim Abmelden');
          }
          this.resetSession();
        }).catch((error) => {
          logger.error("Logout failed: " + error);
          this.resetSession();
        })
    },
    async changePassword(payload) {
      let response = await ApiClient.postRequestWithAuthorization(USER_BASE_PATH + "/changePassword", payload);
      if (response) {
        return response;
      }
      return null;
    },
    async sendPasswordResetRequest(payload) {
      const response = await ApiClient.postRequestWithoutAuthorization(USER_BASE_PATH + "/sendResetLink", payload);
      if (response) {
        return response;
      }
      return null;
    },
    async validateResetToken(payload) {
      const response = await ApiClient.executeGetRequest(USER_BASE_PATH + '/validateResetToken?token=' + payload.token);
      if (response) {
        return response;
      }
      return null;
    },
    async resetPassword(payload) {
      const response = await ApiClient.executePutRequest(USER_BASE_PATH + '/resetPassword', payload);
      if (response) {
        return response;
      }
      return null;
    },
    checkIfAlreadyLoggedIn() {
      return this.isLoggedIn;
    },
    async check2fa(payload) {
      const response = await ApiClient.getRequestWithoutAuthorization(TOTP_PATH + '/check?memberId=' + payload.memberId);
      if (response && response.status === 200 && response.data) {
        return response.data;
      }
      return false;
    },
    async clearSession() {
      this.resetSession()
      await useMemberStore().clearMemberData()
      await useProfileStore().clearPhotoData()
    },
    async setKeycloak(keycloak) {
      this.keycloak = keycloak
    },
    async setClientInitialized(clientInitialized) {
      this.clientInitialized = clientInitialized;
    },

    setData(token, refreshToken, idToken, userId) {
      this.stvAdmin = this.keycloak.hasResourceRole('mystv-stv-admin', 'mystv')
      this.associationAdmin = this.keycloak.hasResourceRole('mystv-verband-admin', 'mystv')
      this.memberAssociationAdminMySTV = this.keycloak.hasResourceRole('mystv-mitgliedverband-admin', 'mystv')
      this.clubAdmin = this.keycloak.hasResourceRole('mystv-verein-admin', 'mystv')
      this.clubAdminLight = this.keycloak.hasResourceRole('mystv-verein-admin-light', 'mystv')
      this.clubAdminLSPA = this.keycloak.hasResourceRole('mystv-verein-admin-lspa', 'mystv')
      this.member = this.keycloak.hasResourceRole('mystv-member', 'mystv') || this.hasAnyOtherRole 
      this.loggedIn = true;
      this.userId = userId;
      this.clientInitialized = true
      kcToken.value = token;
      kcRefreshToken.value = refreshToken
      kcIdToken.value = idToken;
      localStorage.setItem(kc_token, token);
      localStorage.setItem(kc_refresh_token, refreshToken);
      localStorage.setItem(kc_id_token, idToken);
    },

    resetData() {
      this.loggedIn = false;
      this.trigger = null;
      this.userId = null;
      kcToken.value = null
      kcRefreshToken.value = null
      kcIdToken.value = null
      localStorage.removeItem(kc_token);
      localStorage.removeItem(kc_refresh_token);
      localStorage.removeItem(kc_id_token);
      if (this.keycloak != null) {
        this.keycloak.clearToken();
      }
      this.clientInitialized = false;
    },

    async initRefresh(refreshRate) {
      refreshRate = refreshRate || 70;

      this.trigger = setInterval(async () => {
        await this.updateToken(refreshRate)
      }, 10000)
    },
    async updateToken(minValidity) {
      await this.keycloak.updateToken(minValidity).then(async (refreshed) => {
        if (refreshed) {
          logger.debug('Token successfully refreshed: ' + refreshed);
          this.setData(this.keycloak.token, this.keycloak.refreshToken, this.keycloak.idToken, this.keycloak.tokenParsed.preferred_username)
        } else {
          logger.debug('Token not refreshed, still valid for '
            + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch((error) => {
        logger.debug("Unable to refresh token. Please login again...");
        logger.debug(error)
        this.resetSession()
        const messageStore = useMessagesStore();
        messageStore.addWarning(new Message('warning', true, true, t("errorMessages.invalid_session_header"), '', t("errorMessages.invalid_session_text"), true, 'KEYCLOAK'));
      });
    },
    async forceRefresh() {
      this.keycloak.tokenParsed = null
      await this.updateToken(70)
    },
    stopRefresh() {
      clearInterval(this.trigger);
    },

    resetSession() {
      this.stopRefresh();
      this.resetData();
      const messagesStore = useMessagesStore();
      messagesStore.resetErrors(null);
    },

  },


  getters: {
    isLoggedIn: (state) => {
      if (state.loggedIn === null) {
        if (!state.clientInitialized) {
          if (kcToken.value !== "undefined" && kcToken.value !== null) {
            let accessTokenParsed = decodeToken(kcToken.value);
            let now = Math.floor(Date.now() / 1000);
            return accessTokenParsed.exp >= now;
          }
          return false;
        } else {
          return state.keycloak.authenticated;
        }
      }

      if (kcToken.value === null || kcRefreshToken.value === null) {
        return false;
      }

      return state.loggedIn;
    },
    is2faEnabled: (state) => {
      return state.has2fa;
    },
    getUserId: (state) => {
      return state.userId;
    },
    // eslint-disable-next-line no-unused-vars
    getAccessToken: (state) => {
      return kcToken.value
    },
    // eslint-disable-next-line no-unused-vars
    isUser: (state) => {
      return state.member
    },

    // eslint-disable-next-line no-unused-vars
    isMySTVAdmin: (state) => {
      return state.mySTVAdmin;
    },
    isAssociationAdmin: (state) => {
      return state.associationAdmin;
    },
    // eslint-disable-next-line no-unused-vars
    isMemberAssociationAdminMySTV: (state) =>  {
      return state.memberAssociationAdminMySTV;
    },
    // eslint-disable-next-line no-unused-vars
    isClubAdmin: (state) => {
      return state.clubAdmin 
    },
    // eslint-disable-next-line no-unused-vars
    isClubAdminLight: (state) => {
      return state.clubAdminLight;
    },
    // eslint-disable-next-line no-unused-vars
    isClubAdminLSPA: (state) => {
      return state.clubAdminLSPA;
    },
    isAnyKindOfClubAdmin: (state) => {
      return state.clubAdminLSPA || state.clubAdminLight || state.clubAdmin;
    },
    isClientInitialized: (state) => {
      return state.clientInitialized;
    },
    hasAnyOtherRole: (state) => {

      return  state.clubAdmin || 
              state.clubAdminLight ||
              state.clubAdminLSPA || 
              state.memberAssociationAdminMySTV ||
              state.associationAdmin ||
              state.mySTVAdmin
    },
  },
});
