import app from "@/globals";
import i18n from "@/i18n.js";
import pinia from "@/store/index.js"
import router from "@/router/index.js";
import logger from "@/logger/index.js";
import keycloak from "@/keycloak.js"

import "bootstrap";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap-icons/font/bootstrap-icons.scss';

import "@/registerServiceWorker";
import DefaultButton from "@/components/ui/DefaultButton";
import CardContainer from "@/components/ui/CardContainer";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from "primevue/inputtext";
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';

import withUUID from "vue-uuid";

import PrimeVue from "primevue/config";
import {definePreset} from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import Ripple from 'primevue/ripple';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import FocusTrap from "primevue/focustrap";

const globalVueTelOptions = {
  mode: 'auto',
  defaultCountry: 'CH',
  onlyCountries: [ 'CH', 'DE', 'FR', 'IT', 'AT', 'LI' ],
  validCharactersOnly: true,
  inputOptions: {
    placeholder: i18n.global.t('club.member.phonePlaceholder')
  }
};
const initializedApp = app;

initializedApp.component('DefaultButton', DefaultButton);
initializedApp.component('CardContainer', CardContainer);
initializedApp.component('BootstrapToast', BootstrapToast);
// eslint-disable-next-line vue/multi-word-component-names
initializedApp.component('Column', Column);
initializedApp.component('DataTable', DataTable);
initializedApp.component('InputText', InputText);
initializedApp.component('ConfirmDialog', ConfirmDialog);

initializedApp.use(withUUID);
initializedApp.use(i18n);
initializedApp.use(router);
initializedApp.use(pinia);
initializedApp.use(keycloak);
initializedApp.use(logger);
initializedApp.use(VueTelInput, globalVueTelOptions);
initializedApp.use(ConfirmationService);

initializedApp.directive('ripple', Ripple);

const mystvPreset = definePreset(Aura, {
  primitive: {
    mystvRed: {50: '#fef2f2', 100: '#fee2e2', 200: '#fecaca', 300: '#fca5a5', 400: '#f87171', 500: '#ef4444', 600: '#dc2626', 700: '#D8232A', 800: '#991b1b', 900: '#7f1d1d', 950: '#450a0a'},
  },
  semantic: {
    secondary: {
      50: '{mystvRed.50}',
      100: '{mystvRed.100}',
      200: '{mystvRed.200}',
      300: '{mystvRed.300}',
      400: '{mystvRed.400}',
      500: '{mystvRed.500}',
      600: '{mystvRed.600}',
      700: '{mystvRed.700}',
      800: '{mystvRed.800}',
      900: '{mystvRed.900}',
      950: '{mystvRed.950}'
    },
    primary: {
      50: '{stone.50}',
      100: '{stone.100}',
      200: '{stone.200}',
      300: '{stone.300}',
      400: '{stone.400}',
      500: '{stone.500}',
      600: '{stone.600}',
      700: '{stone.700}',
      800: '{stone.800}',
      900: '{stone.900}',
      950: '{stone.950}'
    },
    colorScheme: {
      light: {
        highlight: {
          background: '',
          focusBackground: '{primary.100}',
          color: '{primary.700}',
          focusColor: '{primary.800}'
        },
      }
    }
  },
  components: {
    button: {
      colorScheme: {
        light: {
          primary: {
            hoverColor: '#fff'
          },
          secondary: {
            hoverColor: '#fff',
          },
          outlined: {
            primary: {
              borderColor: '{primary.950}',
              color: '{primary.950}',
              hoverBackground: '{primary.950}',
            },
            secondary: {
              borderColor: '{secondary.700}',
              color: '{secondary.700}',
              hoverBackground: '{secondary.500}',
            }
          }
        },
      },
    },
    toggleswitch: {
      colorScheme: {
        light: {
          root: {
            checkedBackground: '#D8232A',
            checkedHoverBackground: '#99181d',
          },
        },
        dark: {
          root: {
            checkedBackground: '#D8232A',
            checkedHoverBackground: '#99181d',
          },
        },
      },
    },
    datatable: {
      'header.cell.padding': '0.3rem',
      'body.cell.padding': '0.3rem'
    },
    message: {
      'text.font.size': '0.875rem'
    },
  }
});

initializedApp.use(PrimeVue, {
  theme: {
    preset: mystvPreset,
    options: {
      darkModeSelector: '' // TODO: Prüfen, wie sich kein Selektor auswirkt. Default: 'system'
    }
  },
  ripple: true
});

initializedApp.directive('focustrap', FocusTrap);

initializedApp.mount("#app");
