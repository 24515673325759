<template>
  <div class="modal modal-xl fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          {{ $t('createNewMember.createNewMember') }}
        </div>
        <div class="modal-body">
          <label class="form-label" for="firstName">{{ $t('createNewMember.firstName') }}</label>
          <input id="firstName" v-model="firstName" :placeholder="$t('createNewMember.firstName')" class="form-control"
                 type="text"/>

          <label class="form-label" for="lastName">{{ $t('createNewMember.lastName') }}</label>
          <input id="lastName" v-model="lastName" :placeholder="$t('createNewMember.lastName')" class="form-control"
                 type="text"/>

          <label class="form-label" for="birthday">{{ $t('createNewMember.birthday') }}</label>
          <DatePicker id="birthday" :maxDate="maxBirthday" :minDate="minDate" dateFormat="dd.mm.yy" v-model="birthday" :baseZIndex="9999"
                      :placeholder="$t('createNewMember.birthday')" class="w-100"></DatePicker>

          <label class="form-label" for="salutation">{{ $t('createNewMember.salutation') }}</label>
          <select id="salutation" v-model="salutation" class="form-select">
            <option value="M">M</option>
            <option value="W">W</option>
          </select>

          <div v-if="ifNewContact">
            <label class="form-label" for="street">{{ $t('createNewMember.street') }}</label>
            <input id="street" v-model="street" :placeholder="$t('createNewMember.street')" class="form-control"
                   type="text"/>

            <label class="form-label" for="additional">{{ $t('createNewMember.additional') }}</label>
            <input id="additional" v-model="additional" :placeholder="$t('createNewMember.additional')"
                   class="form-control"
                   type="text"/>

            <label class="form-label" for="additional2">{{ $t('createNewMember.additional2') }}</label>
            <input id="additional2" v-model="additional2" :placeholder="$t('createNewMember.additional2')"
                   class="form-control"
                   type="text"/>

            <label class="form-label" for="zipcode">{{ $t('createNewMember.zipcode') }}</label>
            <input id="zipcode" v-model="zipCode" :placeholder="$t('createNewMember.zipcode')" class="form-control"
                   inputmode="numeric" pattern="\d*" @keydown="isNumber($event)"
                   type="text"/>

            <label class="form-label" for="city">{{ $t('createNewMember.city') }}</label>
            <input id="city" v-model="city" :placeholder="$t('createNewMember.city')" class="form-control"
                   type="text"/>

            <label class="form-label" for="countyCode">{{ $t('createNewMember.county') }}</label>
            <select id="countyCode" class="form-select" v-model="county">
              <option v-for="county in counties" :key="county.code" :value="county.code">{{ $i18n.locale !== 'de' ? county.nameFr : county.nameDe }}</option>
            </select>

            <label class="form-label" for="countryCode">{{ $t('createNewMember.country') }}</label>
            <select id="countryCode" class="form-select" v-model="country">
              <option v-for="country in countries" :key="country.code" :value="country.code">{{ $i18n.locale !== 'de' ? country.nameFr : country.nameDe }}</option>
            </select>
          </div>

          <div v-if="duplicatesToShow" class="mt-3">
            {{ $t('createNewMember.duplicateMemberInfo') }}
            <DataTable v-model:selection="selectedDuplicate" :value="duplicates" class="mt-3" dataKey="id"
                       selectionMode="single" tableStyle="min-width: 50rem">
              <Column selectionMode="single"></Column>
              <Column :header="$t('createNewMember.firstName')" field="firstName"></Column>
              <Column :header="$t('createNewMember.lastName')" field="surname"></Column>
              <Column :header="$t('createNewMember.birthday')" field="birthday"></Column>
              <Column :header="$t('createNewMember.gymClub')" field="clubName"></Column>
              <Column :header="$t('createNewMember.salutation')" field="salutation">
                <template #body="{ data }">
                  {{ formatSalutation(data.salutation) }}
                </template>
              </Column>
              <Column :header="$t('createNewMember.street')" field="street"></Column>
              <Column :header="$t('createNewMember.zipcode')" field="plz"></Column>
              <Column :header="$t('createNewMember.city')" field="city"></Column>
              <Column :header="$t('createNewMember.email')" field="email"></Column>
            </DataTable>
            <DefaultButton :disabled="selectedDuplicate===undefined" :label="$t('createNewMember.mergeDuplicates')"
                           class="btn mt-3 btn-outline-primary me-2" data-bs-dismiss="modal" role="button"
                           @click="mergeWithDuplicate"/>
            <DefaultButton :label="$t('createNewMember.ignoreDuplicates')" class="btn mt-3 btn-outline-primary me-2"
                           role="button"
                           @click="continueToStepTwo"/>
          </div>

        </div>
        <div class="modal-footer">
          <DefaultButton :label="$t('cancel')" class="btn mt-3 btn-outline-secondary me-2" data-bs-dismiss="modal"
                         @click="resetParams"/>
          <div v-if="ifNewContact">
            <DefaultButton :disabled="!stepOneAllValuesNotEmpty || !stepTwoValuesNotEmpty"
                           class="btn mt-3 btn-outline-primary me-2"
                           data-bs-dismiss="modal"
                           label="Hinzufügen"
                           role="button"
                           @click="newContact"/>
          </div>
          <div v-if="!ifExist && !ifNewContact">
            <DefaultButton :disabled="!stepOneAllValuesNotEmpty" :label="$t('createNewMember.doDuplicateCheck')"
                           class="btn mt-3 btn-outline-primary me-2"
                           role="button"
                           @click="doDuplicateCheck"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import DefaultButton from "@/components/ui/DefaultButton";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ApiClient from "@/api/ApiClient";
import {useI18n} from "vue-i18n";
import {useMemberStore} from "@/store/modules/member/member";
import DatePicker from "primevue/datepicker";
import {getDateCorrectedForTimezoneOffset, getDateCorrectedForTimezoneOffsetAsIsoString} from "@/helpers/functions";
import {useRouter} from "vue-router";
import {ASSOC_EDIT_MEMBER} from "@/router/urls_association";


const duplicates = ref([]);
const selectedDuplicate = ref(undefined);
const CLUB_NUMBER_LENGTH_CUTOFF = 13; // Wie lange eine Vereins/Verbandsnummer sein muss, um als Vereinsnummer zu gelten


const firstName = ref("");
const lastName = ref("");
const birthday = ref(null);
const salutation = ref();
const street = ref("");
const additional = ref("");
const additional2 = ref("");
const zipCode = ref("");
const city = ref("");
const country = ref("CH");
const county = ref("");

const memberStore = useMemberStore();
const i18nLocale = useI18n().locale;

const countries = computed(() => memberStore.countries);
const counties = computed(() => memberStore.counties);

memberStore.loadCountries(i18nLocale.value);
memberStore.loadCounties(i18nLocale.value);

const today = new Date();
const maxBirthday = ref(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())); // Navision erlaubt keine Kontakte mit alter < 1 jahr
const minDate = ref(new Date(1920, 1, 1));


const exist = ref(false);
const contactIsNew = ref(false);


const {t} = useI18n()
const router = useRouter();

const props = defineProps(['clubId'])
const emit = defineEmits(['startLoader', 'stopLoader']);

const formatSalutation = (salutation) => {
  if (salutation === 'MS') {
    return t('club.listMember.salutationMS');
  } else if (salutation === 'MR') {
    return t('club.listMember.salutationMR');
  }
  return salutation;
}

async function mergeWithDuplicate() {
  if (selectedDuplicate.value === undefined) {
    return;
  }

  let membershipCategoryId = "1";
  const ageInYears = new Date(new Date() - new Date(birthday.value)).getFullYear() - 1970;
  if (ageInYears > 16) {
    if (salutation.value !== "M") {
      membershipCategoryId = "4";
    }
  } else {
    if (salutation.value === "M") {
      membershipCategoryId = "12";
    } else {
      membershipCategoryId = "13";
    }
  }

  const request = "/api/duplicates/merge_with_existing_member"
    + "?existingMemberId=" + selectedDuplicate.value.id
    + "&newClubId=" + props.clubId
    + "&newCategoryId=" + membershipCategoryId;

  emit('startLoader');
  const response = await ApiClient.postRequestWithAuthorization(request, "");

  if (response && response.status === 200 && response.data) {
    if(props.clubId.length > CLUB_NUMBER_LENGTH_CUTOFF) {
      await router.push({
        name: 'club-edit-member',
        query: {memberId: response.data.No, clubId: props.clubId}
      });
    } else {
      await router.push({
        name: ASSOC_EDIT_MEMBER.name,
        query: {memberId: response.data.No, associationId: props.clubId}
      });
    }
  } else {
    emit('stopLoader');
    console.error(response);
  }
}

function isNumber(event) {
  const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Tab', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
  const keyPressed = event.key;
  if(!keysAllowed.includes(keyPressed)) {
    event.preventDefault();
  }
}


async function doDuplicateCheck() {
  const request = "/api/duplicates/check_duplicates"
    + "?firstName=" + firstName.value
    + "&surname=" + lastName.value
    + "&birthday=" + getDateCorrectedForTimezoneOffsetAsIsoString(birthday.value);
  const response = await ApiClient.getRequest(request);
  if (response.status === 200) {
    duplicates.value = response.data;
    if (duplicates.value.length === 0) {
      // wir können hier gar noch keinen Kontakt erfassen, da wir dazu Strasse, Ort und PLZ brauchen
      continueToStepTwo()
    }
  }
}

async function newContact() {

  let membershipCategoryId = "1";
  const ageInYears = new Date(new Date() - getDateCorrectedForTimezoneOffset(birthday.value)).getFullYear() - 1970;
  if (ageInYears > 16) {
    if (salutation.value !== "M") {
      membershipCategoryId = "4";
    }
  } else {
    if (salutation.value === "M") {
      membershipCategoryId = "12";
    } else {
      membershipCategoryId = "13";
    }
  }
  const body = {
    firstName: firstName.value,
    lastname: lastName.value,
    birthday: getDateCorrectedForTimezoneOffsetAsIsoString(birthday.value),
    salutation: salutation.value,
    street: street.value,
    zipCode: zipCode.value,
    city: city.value,
    country: country.value,
    county: county.value,
    additional: additional.value,
    additional2: additional2.value,
    initialClub: props.clubId,
    category: membershipCategoryId
  }
  const request = "/api/duplicates/merge_with_new?&clubId=" + props.clubId;
  emit('startLoader');
  const response = await ApiClient.postRequestWithAuthorization(request, JSON.stringify(body));
  if (response && response.status === 200 && response.data) {
    if(props.clubId.length > CLUB_NUMBER_LENGTH_CUTOFF) {
      await router.push({
        name: 'club-edit-member',
        query: {memberId: response.data, clubId: props.clubId}
      });
    } else {
      await router.push({
        name: ASSOC_EDIT_MEMBER.name,
        query: {memberId: response.data, associationId: props.clubId}
      });
    }
  } else {
    emit('stopLoader');
  }
}

async function resetParams() {
  duplicates.value = [];
  selectedDuplicate.value = undefined;
  firstName.value = ""
  lastName.value = "";
  birthday.value = null;
  salutation.value = null;
  exist.value = false;
  street.value = "";
  zipCode.value = "";
  city.value = "";
  country.value = "CH";
  county.value = "";
  additional.value = "";
  additional2.value = "";
  contactIsNew.value = false;

}

const stepOneAllValuesNotEmpty = computed(() => {
  if (firstName.value.length < 1) {
    return false;
  }
  if (lastName.value.length < 1) {
    return false;
  }
  if (birthday.value == null) {
    return false;
  }

  return salutation.value !== null;
})

const stepTwoValuesNotEmpty = computed(() => {

  if (street.value.length < 1) {
    return false;
  }
  if (zipCode.value.length < 1) {
    return false;
  }
  if (city.value.length < 1) {
    return false;
  }
  if (country.value.length < 1) {
    return false
  }
  if (county.value.length < 1 && country.value === "CH") {
    return false;
  }
  return true;
})

function continueToStepTwo() {
  duplicates.value = [];
  contactIsNew.value = true;
}

const duplicatesToShow = computed(() => {
  return duplicates.value.length > 0
})

const ifExist = computed(() => {
  return exist.value;
})

const ifNewContact = computed(() => {
  return contactIsNew.value;
})

</script>
