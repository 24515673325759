export default {
  "address": {
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays "])},
    "plz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPA"])},
    "postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case postale "])},
    "row1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d’adresse 1"])},
    "row2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d’adresse 2 "])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue "])},
    "streetnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "advertisement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce"])}
  },
  "appTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myFSG"])},
  "association": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Alle Mitglieder"])},
    "association": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Association"]), _normalize(["Associations"])])},
    "changePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandslogo bearbeiten"])},
    "club": {
      "functions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglieder mit Funktionen in den Vereinen"])}
      },
      "members": {
        "reload": {
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Das erneute Laden der Mitgliederliste kann viel Zeit in Anspruch nehmen, da die Daten vom STV-System geladen werden."])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Mitglieder im Verein ", _interpolate(_named("clubName"))])}
      }
    },
    "clubs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereine im Verband"])}
    },
    "communication": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail-Addresse"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Facebook"])},
      "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Webseite"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die hier angegebene Website und E-Mail-Adresse sowie die Konten auf den Social-Media-Plattformen werden teilweise öffentlich auf den Websites des STV angezeigt"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Instagram"])},
      "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_LinkedIn"])},
      "tikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_TikTok"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kommunikation"])},
      "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])}
    },
    "deletePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandslogo löschen"])},
    "deletePictureQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du das Verbandslogo wirklich löschen?"])},
    "functionsAndActivities": {
      "add": {
        "save": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.add.save", undefined, _type)])},
        "selectFunction": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.add.selectFunction", undefined, _type)])},
        "success": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.add.success", undefined, _type)])},
        "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.add.title", undefined, _type)])}
      },
      "delete": {
        "question": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.delete.question", undefined, _type)])},
        "success": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.delete.success", undefined, _type)])},
        "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.delete.title", undefined, _type)])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.edit.title", undefined, _type)])}
      },
      "errors": {
        "addContact": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.errors.addContact", undefined, _type)])},
        "addFunctionCode": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.errors.addFunctionCode", undefined, _type)])},
        "byDateAfterToDate": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.errors.byDateAfterToDate", undefined, _type)])},
        "byDateInvalid": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.errors.byDateInvalid", undefined, _type)])},
        "toDateInvalid": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.errors.toDateInvalid", undefined, _type)])}
      },
      "exportActivitiesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglieder_mit_Tätigkeiten"])},
      "exportFunctionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglieder_mit_Funktionen"])},
      "list": {
        "notFound": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.list.notFound", undefined, _type)])}
      },
      "listTitle": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.listTitle", undefined, _type)])},
      "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("club.functionsAndActivities.title", undefined, _type)])}
    },
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandslogo"])},
    "lspa": {
      "issuingClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verein LSPG"])},
      "issuingClubNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsnummer LSPG"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Es wurden keine Mitglieder mit Leistungssportausweis gefunden."])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sportart"])},
      "validYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gültigkeit"])}
    },
    "lspaClub": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Leistungssportverein ", _interpolate(_named("clubName"))])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsname"])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Dieser Name wird unter anderem für die öffentlichen Übersicht auf der Website des STV verwendet."])},
    "notFound": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Verbandsdaten verfügbar"])}
    },
    "nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsnummer"])},
    "onlyAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nur Verbandsmitglieder"])},
    "onlyClubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nur Vereinsmitglieder"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Informationen Verband"])},
    "save": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Daten speichern"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten konnten nicht gespeichert werden."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten wurden erfolgreich gespeichert."])}
    },
    "stvName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsname STV-Datenbank"])},
    "stvNameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Dieser Name wird nur intern für die Datenbank des STV verwendet und nicht öffentlich angezeigt."])},
    "sync": {
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Wenn euer Verband zur Administration der Verbands- und Mitgliederdaten eine Vereinssoftware von Localclubs, Clubdesk oder Fairgate nutzt, könnt ihr hier die Vereinsschnittstelle des STV aktivieren, damit gewisse Daten eures Verbands und eurer Mitglieder automatisch synchronisiert werden."])}
    },
    "uploadNewPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neues Verbandslogo hochladen"])},
    "validation": {
      "emptyOrExceeded": {
        "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsname: Eingabe erforderlich (max. 50 Zeichen)"])}
      },
      "emptySelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Auswahl treffen"])},
      "invalidIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige IBAN eingeben"])},
      "invalidLength": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Facebook: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Homepage: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Instagram: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_LinkedIn: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "tikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_TikTok: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_X: Die Maximallänge von 80 Zeichen wurde überschritten"])}
      },
      "invalidMailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige E-Mail Adresse eingeben."])},
      "invalidYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige Jahreszahl eingeben"])}
    }
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "breadcrumb": {
    "association": {
      "association": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Dein Verband"])},
      "clubList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsliste"])},
      "div": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Diverses"])},
      "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsprofil bearbeiten"])},
      "etat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Etat und Vereinszahlen"])},
      "functionsAndActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktionen und Tätigkeiten im Verband"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gremien"])},
      "honors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrungen im Verband"])},
      "lspaControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kontrolle Leistungssportausweise"])},
      "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Deine Mitglieder"])},
      "memberNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliederzahlen"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zahlungsmethoden/Bankkonto"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsprofil"])},
      "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Synchronisation Vereinssoftware"])},
      "yourClubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Deine Vereine"])},
      "yourMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Deine Mitglieder und (ehrenamtlichen) Mitarbeitenden/Funktionäre"])}
    },
    "change2FA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sicherheit"])},
    "changeBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la date de naissance"])},
    "changeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l’e-mail "])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe "])},
    "changeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les données de contact"])},
    "changeSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les sports"])},
    "club": {
      "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
      "editGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen / Gremien bearbeiten"])},
      "editMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsmitglieder bearbeiten"])},
      "editPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethoden / Bankkonto bearbeiten"])},
      "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsprofil bearbeiten"])},
      "editSquads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riegen bearbeiten"])},
      "functionsAndActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen und Tätigkeiten bearbeiten"])},
      "honors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehrungen bearbeiten"])},
      "instituts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gruppen/Gremien"])},
      "membercards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliederkarten anzeigen"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "membershipFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliederzahlen bearbeiten"])},
      "partnersAndSponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoren- und Partnerkontakte bearbeiten"])},
      "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisation mit externer Vereinssoftware"])}
    },
    "showProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre "])},
  "club": {
    "changePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le logo de l'association"])},
    "club": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Société"]), _normalize(["Sociétés"])])},
    "communication": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
      "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Webseite"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die hier angegebene Website und E-Mail-Adresse sowie die Konten auf den Social-Media-Plattformen werden teilweise öffentlich auf den Websites des STV angezeigt"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
      "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
      "tikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TikTok"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kommunikation"])},
      "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])}
    },
    "createNewMember": {
      "PLZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_PLZ"])},
      "anrede": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anrede"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Geburtsdatum"])},
      "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canton"])},
      "createNewMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neues Mitglied erfassen"])},
      "doDuplicateCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Weiter"])},
      "duplicateMemberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Angaben stimmen mit einer oder mehreren Personen überein, welche bereits in unserer Datenbank erfasst ist. Bitte prüft, ob es sich um die gleiche Person handelt. \n Wenn ja, setzte bitte das Häckchen bei dieser Person und klicke danach auf den Button “Personen zusammenführen”.\nWenn es sich um eine neue Person handelt, klicke bitte auf den Button “Neue Person als Mitglied erfassen”."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vorname"])},
      "ignoreDuplicates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neue Person als Mitglied erfassen"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nachname"])},
      "mergeDuplicates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Personen zusammenführen"])},
      "ort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ort"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Strasse"])},
      "turnverein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_TV"])}
    },
    "deletePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f_Vereinslogo löschen"])},
    "deletePictureQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du das Vereinslogo wirklich löschen?"])},
    "foundingYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gründungsjahr"])},
    "functionsAndActivities": {
      "activity": {
        "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit"])},
        "contactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit zuweisen"])},
        "delete": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit löschen"])}
        },
        "deleteConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit entfernen"])},
        "deleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit entfernen?"])},
        "deleteNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit konnte nicht gelöscht werden, weil sie nicht gefunden wurde. Eventuell wurde sie schon von einem anderen Benutzer entfernt."])},
        "deleteNotFoundHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nicht gefunden"])},
        "deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du die Tätigkeit wirklich entfernen?"])},
        "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit wurde beim entsprechenden Mitglied erfolgreich entfernt."])},
        "deleteSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit entfernt"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit"])},
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit editieren"])}
        },
        "editNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit konnte nicht editiert werden, da sie nicht gefunden wurde. Eventuell hat sie ein anderer Benutzer entfernt."])},
        "editNotFoundHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nicht gefunden"])},
        "errorCreatingMembershipActivityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit konnte nicht zugewiesen werden"])},
        "errorDeleteClubActivityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Entfernen fehlgeschlagen"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Es wurden keine Tätigkeiten gefunden."])},
        "other_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Editieren fehlgeschlagen. Versuchen Sie gegebenenfalls, nur eine Jahreszahl auf einmal zu editieren."])},
        "pointsEachYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Punktzahl pro Jahr"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Speichern"])},
        "selectActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit auswählen"])},
        "successCreatingMembershipActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit wurde dieser Person erfolgreich zugewiesen."])},
        "successCreatingMembershipActivityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit erfolgreich zugewiesen"])},
        "successCreatingNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit wurde erfolgreich erstellt."])},
        "successCreatingNewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich erstellt"])},
        "successDeleteClubActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit wurde erfolgreich entfernt."])},
        "successDeleteClubActivityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit entfernt"])},
        "successEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Tätigkeit wurde erfolgreich editiert."])},
        "successEditingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich editiert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeiten"])},
        "titleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit editieren"])},
        "titleList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Übersicht Mitglieder mit Tätigkeiten"])},
        "titleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neue Tätigkeit erfassen"])},
        "titleNewMemberActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit einem Mitglied zuweisen"])},
        "yearEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ende Jahr"])},
        "yearStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Beginn Jahr"])}
      },
      "add": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion speichern"])},
        "selectFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion auswählen"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Funktionszuweisung wurde erfolgreich gespeichert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neue Funktion zuweisen"])}
      },
      "delete": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du diese Funktion wirklich entfernen?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Funktion wurde erfolgreich entfernt"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion entfernen"])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion bearbeiten"])}
      },
      "errors": {
        "addClubNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Clubnummer angeben"])},
        "addContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte einen Kontakt angeben"])},
        "addFunctionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Funktion angeben"])},
        "byDateAfterToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Das Startdatum darf nicht nach dem Enddatum sein."])},
        "byDateInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ungültiges Startdatum"])},
        "moreThanOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Diese Funktionsbeziehung (Person und Funktion) ist bereits vorhanden."])},
        "toDateInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ungültiges Enddatum"])}
      },
      "list": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Code"])},
        "contactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_STV-Nummer"])},
        "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Funktionen und Tätigkeiten vorhanden"])}
      },
      "listTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Übersicht Mitglieder mit Funktionen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktionen und Tätigkeiten"])}
    },
    "honors": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung hinzufügen"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bemerkung"])},
      "delete": {
        "hasMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung kann nicht gelöscht werden, da noch Mitglieder zugewiesen sind."])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du diese Ehrung wirklich löschen?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung erfolgreich gelöscht."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung löschen"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Diese Ehrung gibt es bereits."])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung bearbeiten"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "list": {
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Ehrungen vorhanden"])}
      },
      "member": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied mit neuer Ehrung auszeichnen"])}
      },
      "members": {
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglieder-Ehrung löschen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Übersicht Mitglieder mit Ehrungen"])}
      },
      "save": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung erfolgreich gespeichert."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vergebene Ehrungen"])},
      "validation": {
        "clubNumberError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Club: Bitte eine gültige Club-Nummer angeben."])},
        "commentMaxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Maximallänge von 250 Zeichen wurde überschritten"])},
        "descriptionEmptyOrExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung: Eingabe erforderlich (max. 30 Zeichen)"])},
        "honorIdError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Code: Bitte eine gültige Nummer angeben (max. 20 Zeichen)"])},
        "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Datum ungültig"])},
        "selectHonor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Ehrung auswählen"])},
        "selectMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte ein Mitglied auswählen"])}
      }
    },
    "listMember": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Strasse"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "eMailAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail Altern."])},
      "eMailPrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail"])},
      "eMailVerband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail Verband"])},
      "eMailVerein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail Verein"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vorname"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sprache"])},
      "lsaTypeOfSport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sportart LSA"])},
      "lsaValidYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gültigkeit LSA"])},
      "membershipCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kategorie"])},
      "membershipCategoryAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zusatz Bezeichnung"])},
      "mySTVComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bemerkung"])},
      "nameClubOrSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name Verein/Verband"])},
      "nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_STV-Nummer"])},
      "nrClubOrSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereins-/Verbandsnummer"])},
      "phoneBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Telefon Geschäft"])},
      "phoneMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mobiltelefon"])},
      "phonePrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Telefon privat"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ort"])},
      "releaseMySTV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Freigabe mySTV"])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anrede"])},
      "salutationMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Herr"])},
      "salutationMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Frau"])},
      "socialInsuranceNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sozialversicherungsnr"])},
      "squads": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["fr_Riege"]), _normalize(["fr_Riegen"])])},
      "statusMySTV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Status mySTV"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nachname"])},
      "swissAthleticsNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Swiss Athletics Nr"])},
      "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_PLZ"])}
    },
    "listSponsor": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name Unternehmen"])},
      "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zusatz Name Unternehmen"])}
    },
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de l'association"])},
    "member": {
      "actions": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeit"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Beginn"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Tätigkeiten vorhanden"])},
        "pointsInTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Punkte Total"])},
        "pointsPerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Punktzahl pro Jahr"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_ Ende"])}
      },
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Adresse"])},
      "addressAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Adresszusatz"])},
      "addressAddition2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Adresszusatz 2"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ort"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Land"])},
      "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kanton"])},
      "dateOfDeath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Todesdatum"])},
      "emailAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail Alternativ"])},
      "emailPrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail"])},
      "emailVerein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail Verein"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vorname"])},
      "functions": {
        "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion"])},
        "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gruppe/Gremium"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Funktionen vorhanden"])}
      },
      "honors": {
        "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verein"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrungsdatum"])},
        "honor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrung"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Ehrungen vorhanden"])}
      },
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sprache"])},
      "membershipsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Mitgliedschaften vorhanden"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name Unternehmen"])},
      "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zusatz Name Unternehmen"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neues Mitglied"])},
      "phoneBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Telefon Geschäft"])},
      "phoneMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mobiltelefon"])},
      "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige Telefonnummer eingeben"])},
      "phonePrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Telefon privat"])},
      "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_PLZ"])},
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Profil von ", _interpolate(_named("stvNr")), " - ", _interpolate(_named("firstName")), " ", _interpolate(_named("surname"))])},
      "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Freigabe mySTV"])},
      "releaseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Hier kann die STV-Nummer einer erziehungsberechtigten Person mit STV-Nummer eingegeben werden. Diese Person erhält dadurch Zugriff auf den persönlichen mySTV-Account des ausgewählten Mitglieds und kann auch gewisse Personendaten des Mitglieds editieren."])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bemerkungen"])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anrede"])},
      "salutationMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Herr"])},
      "salutationMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Frau"])},
      "save": {
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Eingabe speichern"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten konnten nicht gespeichert werden."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten wurden erfolgreich gespeichert."])}
      },
      "socialInsuranceNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sozialversicherungsnummer"])},
      "stvNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_STV-Nummer"])},
      "subscriptions": {
        "addressRelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Adressfreigabe"])},
        "addressReleaseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Meine Adresse darf an Sponsoren und Partner weitergegeben werden."])},
        "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Werbung"])},
        "advertisingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ja, ich möchte gerne Werbung erhalten"])},
        "alderEisenhutCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Alder + Eisenhut Katalog"])},
        "alderEisenhutCatalogLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ich möchte den Alder + Eisenhut Katalog nicht erhalten"])},
        "gymLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gym Live Abo"])},
        "gymLiveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ja, ich möchte gerne ein Gym Live Abo"])},
        "regional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Regional Abo"])},
        "regionalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ja, ich möchte gerne ein Regional Abo"])}
      },
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nachname"])},
      "swissAthleticsNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Swiss Athletics Nr"])},
      "titles": {
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Tätigkeiten"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Adresse"])},
        "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kommunikation"])},
        "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Aktuelle Funktionen"])},
        "honors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ehrungen"])},
        "memberships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliedschaften"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Profil"])},
        "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Abonnemente"])}
      },
      "validation": {
        "emptyOrExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_", _interpolate(_list(0)), ": Eingabe erforderlich (max. ", _interpolate(_list(1)), " Zeichen)"])},
        "emptyOrShortOrExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_", _interpolate(_list(0)), ": Eingabe erforderlich (min. ", _interpolate(_list(1)), ", max. ", _interpolate(_list(2)), " Zeichen)"])},
        "invalidFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_", _interpolate(_list(0)), ": Ungültiges Format"])},
        "invalidNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_", _interpolate(_list(0)), ": Ungültige Nummer"])},
        "invalidSelection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_", _interpolate(_list(0)), ": Ungültige Auswahl"])},
        "releaseMySTVFSG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_", _interpolate(_list(0)), ": Ungültiges Mitglied"])}
      }
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsname"])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Dieser Name wird unter anderem für die Anzeige auf Ranglisten und Statistiken oder im Turnverein-Finder verwendet."])},
    "notFound": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Es konnten keine Informationen zum Club gefunden werden."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Clubdaten verfügbar"])}
    },
    "nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsnummer"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Informationen Verein"])},
    "save": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Daten speichern"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten konnten nicht gespeichert werden."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten wurden erfolgreich gespeichert."])}
    },
    "sponsor": {
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Profil von ", _interpolate(_named("stvNr")), " - ", _interpolate(_named("name")), " ", _interpolate(_named("name2"))])},
      "save": {
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Eingabe speichern"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten konnten nicht gespeichert werden."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten wurden erfolgreich gespeichert."])}
      },
      "successRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich entfernt"])},
      "successRemoveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Mitgliedschaft dieses Sponsoren- oder Partnerkontaktes wurde erfolgreich entfernt."])}
    },
    "squads": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege hinzufügen"])},
      "delete": {
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Löschen der Riege"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du die Riege wirklich löschen?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege wurde erfolgreich gelöscht"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege löschen"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Riege ", _interpolate(_named("squadName")), " bearbeiten"])},
      "list": {
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe disponible"])}
      },
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "save": {
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Speichern der Riege"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege erfolgreich gespeichert"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])}
    },
    "stvName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsname STV-Datenbank"])},
    "stvNameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Dieser Name wird nur intern für die Datenbank des STV verwendet und nicht öffentlich angezeigt."])},
    "sync": {
      "dataProtection": {
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["fr_Ich bestätige, dass ich die ", _interpolate(_list(0)), " gelesen habe und akzeptiere."])}
      },
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Wenn euer Verein zur Administration der Vereins- und Mitgliederdaten eine Vereinssoftware von Localclubs, Clubdesk oder Fairgate nutzt, könnt ihr hier die Vereinsschnittstelle des STV aktivieren, damit gewisse Daten eures Vereines und eurer Mitglieder automatisch synchronisiert werden"])},
      "invalidProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Vereinssoftware auswählen"])},
      "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte einen gültigen Zugriffsschlüssel angeben"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_              Keine Informationen zur Vereinsschnittstelle vorhanden "])},
      "noProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_keine"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anbieter Vereinssoftware"])},
      "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zugriffsschlüssel Webdienst"])}
    },
    "uploadNewPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger un nouvel logo de l'association "])},
    "validation": {
      "emptyOrExceeded": {
        "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsname: Eingabe erforderlich (max. 50 Zeichen)"])}
      },
      "emptySelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Auswahl treffen"])},
      "invalidIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige IBAN eingeben"])},
      "invalidLength": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_E-Mail: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Facebook: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Homepage: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Instagram: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_LinkedIn: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "tikTok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_TikTok: Die Maximallänge von 80 Zeichen wurde überschritten"])},
        "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_X: Die Maximallänge von 80 Zeichen wurde überschritten"])}
      },
      "invalidMailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige E-Mail Adresse eingeben."])},
      "invalidYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine gültige Jahreszahl eingeben"])}
    }
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bemerkung"])},
  "contact": {
    "bankAccount": {
      "countries": {
        "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suisse"])},
        "fl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])}
      },
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bankkoordinaten"])},
      "infoTextAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte hinterlegt die gewünschte Zahlungsart sowie die Bankkoordinaten eures Verbandes, damit der STV Auszahlungen oder Rückerstattungen an euren Verband vornehmen kann."])},
      "infotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte hinterlegt die gewünschte Zahlungsart sowie die Bankkoordinaten eures Vereines, damit der STV Auszahlungen oder Rückerstattungen an euren Verein vornehmen kann."])},
      "labels": {
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ort"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Land"])},
        "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_IBAN"])},
        "nameOfInsitute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name der Bank"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Eingabe speichern"])},
        "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Postleitzahl"])}
      },
      "methods": {
        "bankPaymentDomestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bankzahlung Inland"])},
        "bankPaymentForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bankzahlung Ausland"])},
        "postPaymentDomestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Postzahlung Inland"])},
        "postPaymentForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Postzahlung Ausland"])},
        "swiftForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_SWIFT-Zahlung Ausland"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zahlungsart"])}
      },
      "save": {
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten konnten nicht gespeichert werden."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Daten wurden erfolgreich gespeichert."])}
      },
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Bankkoordinaten ", _interpolate(_named("name"))])},
      "validation": {
        "cityExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ort: Die Maximallänge von 30 Zeichen wurde überschritten"])},
        "invalidCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ungültige Länderauswahl. Zulässige Werte sind 'CH' sowie 'FL'"])},
        "invalidIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_IBAN: Bitte eine gültige IBAN eingeben."])},
        "invalidIBANCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_IBAN: Ungültiger Ländercode. Es können nur IBAN der Länder Schweiz und Liechtenstein verwendet werden."])},
        "invalidIBANCountryCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_IBAN: Unzulässiger Ländercode. Der Ländercode muss mit dem ausgewählten Land übereinstimmen"])},
        "invalidPaymentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zahlungsart: Bitte eine gültige Zahlungsart auswählen"])},
        "nameEmptyOrExeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name: Eingabe erforderlich (max. 50 Zeichen)"])},
        "postCodeExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Postleitzahl: Die Maximallänge von 20 Zeichen wurde überschritten"])}
      }
    }
  },
  "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_", _interpolate(_named("object")), " erstellen"])},
  "createMembership": {
    "choose_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Zusatz Bezeichnung auswählen"])},
    "choose_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kategorie auswählen"])},
    "choose_squads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege auswählen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neue Mitgliedschaft erstellen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliedschaft bearbeiten"])},
    "entryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Eintritt"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliedschaft entfernen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliedschaft speichern"])}
  },
  "cropper": {
    "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrandir"])},
    "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduir"])}
  },
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Modifier ", _interpolate(_named("object"))])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "errorMessages": {
    "access_denied_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d’accès "])},
    "access_denied_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction n’est malheureusement pas à votre disposition."])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue "])},
    "invalid_credentials_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion "])},
    "invalid_credentials_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la connexion. Veuillez vérifier vos données d’accès."])},
    "invalid_session_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée "])},
    "invalid_session_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre séance a expiré. Veuillez vous connecter à nouveau."])},
    "network_error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuellement l’application n’est pas disponible. Veuillez réessayer plus tard !"])}
  },
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Exportieren"])},
  "faq": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des questions sur myFSG? Consultez donc notre page d'information et notre F.A.Q."])}
  },
  "footer": {
    "dataProtection": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration sur la protection des données"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/declaration-sur-la-protection-des-donnees.html"])}
    },
    "goldPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires d'or"])},
    "impressum": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/impressum.html"])}
    },
    "platinPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires de platine"])},
    "termsConditions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/termes-et-conditions.html"])}
    }
  },
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "functions": {
    "aktivmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre actif "])},
    "ehrenmitglied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre d’honneur "])},
    "kassier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caissier "])},
    "kassierin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caissière "])},
    "mitgliedGeraeteturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre gymnastique aux agrès "])}
  },
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
  "institutes": {
    "add": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gruppe/Gremium hinzufügen"])}
    },
    "addresses": {
      "list": {
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine zugewiesenen Mitglieder gefunden"])}
      }
    },
    "delete": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe/Gremium löschen"])},
      "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Fehler beim Löschen der Gruppe/des Gremiums \"", _interpolate(_named("groupName")), "\""])},
      "hasMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Der Datensatz kann nicht gelöscht werden, da noch Organadressen vorhanden sind."])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du diese Gruppe / dieses Gremium wirklich löschen?"])},
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Gruppe/Gremium \"", _interpolate(_named("groupName")), "\" erfolgreich gelöscht."])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Willst du die Gruppe/das Gremium \"", _interpolate(_named("groupName")), "\" (Nr. ", _interpolate(_named("code")), ") wirklich löschen?"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Gruppe/Gremium \"", _interpolate(_named("groupName")), "\" löschen"])}
    },
    "edit": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name Gruppe/Gremium"])},
      "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Gruppe/Gremium ", _interpolate(_named("groupName")), " speichern"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Gruppe/Gremium ", _interpolate(_named("groupName")), " bearbeiten"])}
    },
    "filter": {
      "clubNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher par n de club"])}
    },
    "functions": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktionsbeziehung hinzufügen"])},
      "allMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglieder_mit_Funktionen"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion erstellen"])},
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion löschen"])},
        "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Fehler beim Löschen der Funktion ", _interpolate(_named("functionName"))])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion löschen"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du diese Funktion wirklich löschen?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Die Funktion ", _interpolate(_named("functionName")), " wurde erfolgreich gelöscht."])}
      },
      "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Funktion ", _interpolate(_named("functionName")), " bearbeiten"])},
      "functionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion in der Gruppe/im Gremium"])},
      "infotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bei einem bestehenden Eintrag einer Funktion kann die Funktion nicht überschrieben oder angepasst werden. In diesem Fall muss die bestehende Funktion gelöscht und ein neuer Eintrag mit der neuen Funktion erfasst werden. "])},
      "instituteCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nummer"])},
      "instituteNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nummer"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktionen verwalten"])},
      "memberId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_STV-Nummer"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktionsname"])},
      "noMembersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Es wurden keine Mitglieder mit Funktionen gefunden."])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anrede"])},
      "save": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Speichern der Funktion"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Funktion wurde erfolgreich gespeichert"])}
      },
      "selectFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion auswählen"])},
      "selectMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied auswählen"])},
      "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Stufe"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Strasse"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktionen"])},
      "validation": {
        "noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte einen Funktionsnamen angeben"])},
        "noStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Stufe angeben"])}
      }
    },
    "list": {
      "clubNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de club"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe/Comité"])},
      "instituteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gruppe/Gremium"])},
      "instituteNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
      "noInstitutesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe/comité trouvé"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Aktuelle Gruppen/Gremien"])}
    },
    "members": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neues Mitglied hinzufügen"])},
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied aus der Gruppe entfernt"])},
        "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Fehler beim Entfernen des Mitglieds ", _interpolate(_named("member")), " aus der Gruppe"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied aus der Gruppe entfernen"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du dieses Mitglied wirklich entfernen?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Mitglied ", _interpolate(_named("member")), " wurde aus der Gruppe entfernt"])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Funktion in der Gruppe"])}
      }
    },
    "modal": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gruppen/Gremien"])}
    },
    "save": {
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Diese Nummer wird bereits verwendet. Bitte wähle eine neue Nummer."])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Speichern"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gruppe/Gremium gespeichert"])}
    },
    "validation": {
      "nameEmptyOrExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Name: Eingabe erforderlich (max. 50 Zeichen)"])},
      "noClubSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Club: Bitte eine gültige Club-Nummer angeben"])},
      "noContactSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte ein Mitglied angeben"])},
      "noFunctionSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte eine Funktion angeben"])},
      "noInstituteSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte ein Gremium/eine Gruppe angeben"])},
      "noStartDateSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bitte ein Startdatum angeben"])},
      "numberEmptyOrExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nummer: Eingabe erforderlich (max. 30 Zeichen)"])},
      "stepExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Stufe: Eingabe zu lang (max. 10 Zeichen)"])}
    }
  },
  "languages": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])}
  },
  "links": {
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/membre-societe/myfsg-carte-de-membre-numerique.html"])},
    "partner": {
      "coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.coop.ch/fr/entreprise/groupe-coop.html"])},
      "jako": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/a-propos-de-la-fsg/sponsors-partenaires/partner-1.html#c20941"])},
      "localCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/a-propos-de-la-fsg/sponsors-partenaires/partner-1.html#c20939"])},
      "mobilezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/a-propos-de-la-fsg/sponsors-partenaires/partner-1.html#c20942"])},
      "ochsner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.ochsnersport.ch/fr/shop/"])},
      "swica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.swica.ch/fr/partner/sportfoerderung/stv/avantages-swica"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/declaration-sur-la-protection-des-donnees.html"])}
  },
  "login": {
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("detail"))])},
    "intro": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président central"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur myFSG, plateforme numérique des membres de la gymnastique suisse. Je vous remercie de votre affiliation à une société et de votre engagement en faveur de la gymnastique suisse !"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de votre engagement !"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "passwordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe : Saisie obligatoire"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’inscrire à myFSG "])},
    "totp": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs : Saisie obligatoire"])},
      "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Avec cette étape supplémentaire, vous confirmez que vous souhaitez vous connecter à myFSG avec ", _interpolate(_list(0)), ".\nVeuillez saisir le code de confirmation à 6 chiffres de l'application Authenticator sur votre téléphone portable."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion avec authentification à deux facteurs"])}
    },
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ou numéro de membre"])},
    "usernameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur : Saisie obligatoire "])}
  },
  "lspa": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Abbrechen"])},
    "clubName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereinsname"])},
    "confirmCreateNew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Für diese Person den Leistungssportausweis für das Jahr ", _interpolate(_named("currentYear")), " kostenpflichtig bestellen"])},
    "confirmOrderRenew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_Möchtest du bei den ausgewählten Mitgliedern den Leistungssportausweis für das Jahr ", _interpolate(_named("currentYear")), " kostenpflichtig bestellen?"])},
    "confirmOrderRenewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweise bestellen"])},
    "confirmOrderRenewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Bestätigen"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweis bei neuen Personen hinzufügen"])},
    "limitedPeriodHasPassedInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Frist für das aktuelle Jahr ist bereits abgelaufen. Bei allfälligen Fragen wende Dich bitte an spitzensport", "@", "stv-fsg.ch"])},
    "limitedPeriodInfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweise für die olympischen Sportarten Kunstturnen, Rhythmische Gymnastik und Trampolin können jeweils vom 1. Januar bis zum 30. April des jeweiligen Kalenderjahres bestellt werden."])},
    "memberInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Diese Person verfügt über keine gültige Aktivmitgliedschaft."])},
    "memberInactiveHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Keine Aktivmitgliedschaft"])},
    "memberNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Diese Mitgliedernummer gibt es nicht oder die dazugehörigen Daten stimmen nicht überein."])},
    "memberNotFoundHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied nicht gefunden"])},
    "membershipNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliedernummer"])},
    "renewOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweis erneuern"])},
    "searchForMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglied suchen und hinzufügen"])},
    "sportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sportart"])},
    "startPage": {
      "smallTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweise bearbeiten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweise bearbeiten"])}
    },
    "status": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Aktiv"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Abgelaufen"])},
      "NO_ACTIVE_MEMBERSHIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehlende Aktiv-Mitgliedschaft"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Status"])}
    },
    "successCreatingNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweis wurde erfolgreich bestellt."])},
    "successCreatingNewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich bestellt"])},
    "successRenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweise wurden erfolgreich erneuert."])},
    "successRenewHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich erneuert"])},
    "validYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Gültigkeit Leistungssportausweis"])}
  },
  "membership": {
    "eintritt": {
      "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée événements FSG"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championnats suisses de la FSG"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée gratuite"])}
    },
    "leistungsSportLizenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’attestation du sport de performance"])},
    "olympischeLizenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence Mission olympique"])},
    "richterbrevet": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Brevet de juge"]), _normalize(["Brevets de juge"])])},
    "sportsInsurance": {
      "infoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/assurance-cas.html"])},
      "infoLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos Caisse d’assurance du sport"])},
      "serviceLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.stv-fsg.ch/fr/assurance/prestations.html"])},
      "serviceLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestations "])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conformément au règlement, les membres actifs de la FSG sont assurés par la Caisse d'assurance de sport de la FSG pour les cas de responsabilité civile, les bris de lunettes et les accidents (en complément d'assurances tierces). ", _interpolate(_named("link"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse d’assurance du sport"])}
    },
    "voluntaryWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honneurs"])}
  },
  "menu": {
    "manageAssociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Verbandsverwaltung"])},
    "manageClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de la société"])},
    "manageClubAndMemberData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereins- und Mitgliederdaten bearbeiten"])},
    "manageLSPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Leistungssportausweise bearbeiten"])},
    "memberCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma carte"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu "])},
    "ownProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données personnelles "])},
    "releasedMembers": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à ton compte"])},
      "memberCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Carte de membre de ", _interpolate(_named("releaseMember"))])},
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profil de ", _interpolate(_named("releaseMember"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de la famille"])}
    },
    "singleMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre individuel"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
  },
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant "])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "notFound": {
    "checkLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Prüfe bitte, ob der Link korrekt ist."])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers la page d’accueil "])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’accès indiqué est introuvable."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introuvable "])}
  },
  "offers": {
    "clubs": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n’est pas toujours aisé de trouver des sponsors, que ce soit pour sa société ou pour une manifestation. La FSG a noué plusieurs partenariats intéressants dont vous aussi pouvez profiter en tant que société ou CO. Jetez un coup d’œil, ça en vaut la peine."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les sociétés"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirer profit des partenariats de la FSG"])}
    },
    "members": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que membre FSG, vous soutenez directement la gymnastique suisse et permettez à la FSG et à ses sociétés de gymnastique de proposer des offres variées. En guise de remerciement, tous les gymnastes actifs bénéficient d’offres et rabais spéciaux accordés par les partenaires de la FSG."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les membres"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mille mercis pour votre confiance et votre soutien!"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les membres"])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "pagination": {
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])}
  },
  "personalData": {
    "person": {
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance "])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom "])},
      "gender": {
        "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin "])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])}
      },
      "nameAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom supplémentaire "])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
      "squads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "stvNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_STV-Nummer"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
    }
  },
  "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
  "print": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Imprimer ", _interpolate(_named("object"))])},
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que nous puissions vous faire parvenir la revue de la fédération GYMLive ainsi que vos certificat."])},
    "birthday": {
      "changeBirthday": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeBirthday", undefined, _type)])},
      "changeSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta date de naissance a été corrigée avec succès."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible que ton club ait saisi une date de naissance erronée. Tu peux l'adapter ici."])},
      "saveNewBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer la date de naissance"])},
      "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("personalData.person.birthday", undefined, _type)])}
    },
    "changePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la photo du profil"])},
    "changeSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les sports"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations de contact"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays "])},
    "delete": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veux-tu vraiment supprimer ta photo de profil?"])}
    },
    "deletePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la photo de profil "])},
    "editProfile": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeProfile", undefined, _type)])},
    "email": {
      "changeEmail": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changeEmail", undefined, _type)])},
      "changeFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Votre adresse électronique n’a pas pu être modifiée."])},
      "changePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton lien est en cours de vérification ..."])},
      "changeSubmitted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>E-mail modifié: Vérifiez votre boîte de réception pour l'adresse <strong>", _interpolate(_named("email")), "</strong> et confirmez l’adresse.</p><p>Jusque-là le changement n’est pas pris en compte.</p>"])},
      "changeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre e-mail a été modifié."])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la nouvelle adresse électronique "])},
      "currentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail actuel"])},
      "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée. Veuillez saisir une nouvelle adresse e-mail valide."])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe "])},
      "invalidConfirmationPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe que tu as saisi n'est pas valide. Veuillez vérifier votre saisie"])},
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe pour modifier votre adresse électronique."])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel e-mail "])},
      "passwordInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le mot de passe pour changer votre adresse électronique."])},
      "submitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer nouvel e-mail  "])},
      "submitFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la modification de l'adresse électronique. Essayer à nouveau."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien indiqué n’est plus valable. Veuillez vérifier la barre d’adresse du navigateur ou réinitialiser votre adresse électronique."])},
      "wrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse électronique valide."])}
    },
    "emailInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pour votre login et pour les mises à jour de la FSG ainsi que de votre société de gymnastique. Vous pouvez également vous inscrire aux concours et aux cours."])},
    "etat": {
      "amountOfMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anzahl Mitglieder"])},
      "automaticCalculation10and11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Automatische Berechnung 10-11"])},
      "automaticCalculation14and15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Automatische Berechnung 14-15"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kategorie"])},
      "childrenAndMukiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kinderturnen und Muki-/Elki-Turnen"])},
      "dateOfCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Berechnungsdatum"])},
      "errorSaveHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Speichern"])},
      "errorSaveUnspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Ein unerwarteter Fehler ist aufgetreten."])},
      "gymnasticAdultsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Turnende Erwachsene"])},
      "gymnasticLicenseOtherFedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Turnende Lizenz anderer Sportverband"])},
      "kat10Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Passivmitglieder"])},
      "kat11Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nichtturnende Ehrenmitglieder"])},
      "kat12Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Knaben/Jugend"])},
      "kat13Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mädchen/Jugend"])},
      "kat14Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Kinderturnen"])},
      "kat15Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Muki/Vaki/Elki"])},
      "kat1Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Aktive, Turner"])},
      "kat28Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erwachsene PluSport"])},
      "kat29Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Jugendliche PluSport"])},
      "kat2Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Männer"])},
      "kat3Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Senioren"])},
      "kat4Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Aktive, Turnerinnen"])},
      "kat5Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Frauen"])},
      "kat6Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Seniorinnen"])},
      "kat7Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Turnende Ehrenmitglieder"])},
      "kat8Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Turner mit Lizenz Fachverband"])},
      "kat9Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Turnerinnen mit Lizenz Fachverband"])},
      "membersPluSportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitglieder PluSport"])},
      "nonGymnasticAdultsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Nichtturnende Erwachsene"])},
      "numberOfMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Anzahl Mitglieder"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Eingabe speichern"])},
      "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Änderungen wurden erfolgreich gespeichert."])},
      "saveSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Eingabe gespeichert"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliederzahlen bearbeiten"])},
      "totalChildrenAndMuki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Total Kinderturnen und Muki-/Elki-Turnen"])},
      "totalGymnasticAdults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Total Turnende Erwachsene"])},
      "totalGymnasticLicenseOtherFed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Total Turnende Lizenz anderer Sportverband"])},
      "totalMembersPluSport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Total Mitglieder PluSport"])},
      "totalNonGymnasticAdults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Total Nichtturnende Erwachsene"])},
      "totalYoungPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Total Jugendliche bis und mit 16 Jahre"])},
      "youngPeopleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Jugendliche bis und mit 16 Jahre"])}
    },
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom "])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom et nom "])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom "])},
    "mailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case postale"])},
    "memberNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de membre ou e-mail "])},
    "password": {
      "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel "])},
      "edit": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.changePassword", undefined, _type)])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe n’a pas pu être modifié. Vérifiez vos saisies et réessayer."])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 7 caractères "])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe correspond "])},
      "matchOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe ne doit pas être le même que le précédent  "])},
      "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe "])},
      "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passse rempli "])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe (confirmer)"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer nouveau mot de passe"])},
      "specialChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères spéciaux présents "])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton mot de passe a été modifié avec succès."])},
      "upperLowerCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majuscules et minuscules présentes"])}
    },
    "phone": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que tes entraîneurs*es puissent te joindre."])},
      "noPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'as pas encore indiqué de numéro de téléphone."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])}
    },
    "phoneInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que tes entraîneurs*es puissent te joindre."])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo de profil "])},
    "pictureSizeExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier sélectionné dépasse la taille autorisée de 16MB"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "save": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes données n’ont pas pu être enregistrées."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes données ont été enregistrées avec succès."])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications "])},
    "saveProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les données de contact"])},
    "security": {
      "authenticatorInfo": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Scanne den QR-Code mit einer Authenticator App (z.B. Google Authenticator, FreeOTP). Übertrage anschliessend den Code aus der App in das untenstehende Eingabefeld ein. Wähle danach eine Bezeichnung damit du das Gerät eindeutig identifizieren kannst. Um die Registration deines Geräts abzuschliessen, musst du danach noch den Button am Ende anklicken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vorgehen"])}
      },
      "device": {
        "delete": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil n'a pas pu être déconnecté."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil a été déconnecté avec succès."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Souhaitez-vous vraiment déconnecter l'appareil sélectionné \"", _interpolate(_list(0)), "\"?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter l’appareil"])}
        }
      },
      "deviceName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation de votre appareil"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation entre 3 et 100 caractères"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. Nokia 3210"])}
      },
      "devices": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore enregistré d'appareil."])},
        "register": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil n'a pas pu être enregistré. Veuillez réessayer."])},
          "failureDeviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de votre appareil ne contient pas le nombre de caractères requis (3-100)."])},
          "failureInitalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Votre code initial ne contient pas les 6 chiffres requis."])},
          "failureOneDeviceOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà enregistré un appareil. Malheureusement, un seul appareil peut être enregistré."])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre appareil a été enregistré avec succès."])}
        }
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs"])},
      "explanation": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ajoutant votre appareil, l'authentification à deux facteurs est automatiquement activée. A partir de ce moment, un code de sécurité à six chiffres vous sera demandé lors de la connexion. Vous pourrez lire le code dans l'application Authenticator. Pour désactiver ce niveau de sécurité supplémentaire, il suffit de supprimer votre appareil de la liste ci-dessous."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer/désactiver l'authentification à deux facteurs"])}
      },
      "initialCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code initial de l'application Authenticator"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 chiffres"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. 140792"])}
      },
      "qrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code QR pour configurer un nouvel appareil"])},
      "saveDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'appareil"])},
      "secret": {
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier la clé"])},
        "copyToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour copier la clé de configuration dans le presse-papiers"])},
        "keyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque : il s'agit d'une clé basée sur le temps"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé de configuration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Au cas où vous ne pourriez pas scanner le code QR, vous pouvez également procéder à la configuration manuellement. Pour cela, veuillez copier la clé d'installation et la coller dans votre application Authenticator."])}
      }
    },
    "showProfile": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("breadcrumb.showProfile", undefined, _type)])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
    "uploadNewPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une nouvelle photo de profil "])},
    "uploadPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une photo de profil "])},
    "validation": {
      "invalidCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un pays valable"])},
      "invalidFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un prénom"])},
      "invalidLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un nom"])},
      "invalidMailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un case postale valable "])},
      "invalidMemberNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de membre FSG valable ou un e-mail "])},
      "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un nom complet "])},
      "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un numéro de téléphone valable"])},
      "invalidPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un lieu valable"])},
      "invalidStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer une rue valable "])},
      "invalidZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un NPA valable "])}
    },
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPA"])}
  },
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Daten neu laden"])},
  "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques "])},
  "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enregistrer ", _interpolate(_named("object"))])},
  "search": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Filter"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Suche"])}
  },
  "section": {
    "clubMembers": {
      "deletePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Sponsoren- und Partnerkontakt im Verein löschen"])},
      "deletePartnerQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du den Sponsoren- und Partnerkontakt im Verein wirklich löschen?"])},
      "exportFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Alle Mitglieder"])},
      "filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recherche par ", _interpolate(_named("filter"))])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "noMembersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun membre actif trouvé"])},
      "noSponsorsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Es wurden keine Partner oder Sponsoren gefunden"])},
      "pagination": {
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " à ", _interpolate(_named("to")), " sur ", _interpolate(_named("total"))])},
        "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
        "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("page")), " de ", _interpolate(_named("totalPages"))])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])}
      },
      "printSingleCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer des cartes individuelles"])},
      "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pas de carte selectionnée"]), _normalize(["Une carte selectionnée"]), _normalize([_interpolate(_named("count")), " cartes selectionnées"])])},
      "status": {
        "CONDITION_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions non remplies"])},
        "EMAIL_DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double adresse e-mail"])},
        "EMAIL_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail invalide"])},
        "EMAIL_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d’e-mail"])},
        "INITIATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "OFFBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En préparation"])},
        "ONBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
        "OPT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne souhaite pas de compte myFSG"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "QUARANTINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur technique"])},
        "REMINDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut myFSG"])}
      }
    },
    "education": {
      "brevets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes brevets et mes formations"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
      "noBrevetEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de brevets ni de formations"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de cours ni d’inscriptions "])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes formations "])},
      "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité"])}
    },
    "function": {
      "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de fonctions "])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "stvMember": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Employé/e de la FSG"]), _normalize(["Employée de la FSG"]), _normalize(["Employé de la FSG"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes fonctions"])}
    },
    "member": {
      "honor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honneur"])},
      "infobox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>En cas de données et de contenus erronés concernant l'affiliation à ta sociéte, adresse-toi à l'administrateur FSG-Admin de ta sociéte. En cas de données erronées au niveau de la fédération, veuillez vous adresser à votre association cantonale ou à la FSG.</p><p>Lorsque des modifications de données et de contenus sont effectuées dans myFSG ou dans STV-Admin, il peut s'écouler jusqu'à 24 heures avant qu'elles ne soient visibles dans l'autre plateforme.</p>"])},
      "memberNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No de membre "])},
      "titleClubMember": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("section.member.titleMembercard", undefined, _type)])},
      "titleFunctionMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma carte de fonction"])},
      "titleMembercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de membre"])},
      "titleSingleMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma carte de membre "])}
    },
    "membership": {
      "association": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("association", undefined, _type)])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de membres "])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Willst du die Mitgliederkategorie wirklich entfernen?"])},
      "confirmDeleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliederkategorie entfernen?"])},
      "createErrorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Hinzufügen"])},
      "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Mitgliederkategorie wurde erfolgreich hinzugefügt."])},
      "createSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Mitgliederkategorie hinzugefügt"])},
      "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Mitgliederkategorie konnte nicht entfernt werden."])},
      "deleteErrorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Entfernen"])},
      "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Mitgliederkategorie wurde erfolgreich entfernt."])},
      "deleteSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich entfernt"])},
      "editErrorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Fehler beim Editieren"])},
      "editSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Die Mitgliedschaft wurde erfolgreich editiert."])},
      "editSuccessHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Erfolgreich editiert"])},
      "entryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’entrée "])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'historique des cours de J+S et d'esa peut être consulté sur les sites Internet de Jeunesse+Sport et d'esa."])},
      "memberNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No de membre"])},
      "memberOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre de"])},
      "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frMitgliedschaften"])},
      "membershipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’affiliation "])},
      "mutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée/Mutations"])},
      "noClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune affiliation active valable à une société de gymnastique"])},
      "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore d’affiliation "])},
      "squad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes affiliations"])}
    },
    "section": {
      "clubMembers": {
        "printCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer une feuille de collecte"])}
      }
    },
    "start": {
      "adminAssocMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["fr:Du bist Admin beim Verband"]), _normalize(["fr_Du bist Admin bei den Verbänden"])])},
      "adminLightMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["fr_Du bist Admin beim Verein"]), _normalize(["fr_Du bist Admin bei den Vereinen"])])},
      "adminMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tu es admin à la société"]), _normalize(["Tu es admin aux sociétés"])])},
      "editClubMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Vereins- und Mitgliederdaten bearbeiten"])},
      "emulatingMember": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu es actuellement sur le compte de ", _interpolate(_named("releaseMember"))])},
      "familyMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu peux gérer les membres de la famille suivants"])},
      "greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Salut ", _interpolate(_named("user")), "."])},
      "greetingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est bien que tu sois là."])},
      "headerImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headerbild"])},
      "showClubMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les membres de ma société"])},
      "showMemberCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher ma carte de membre"])},
      "showReleaseMemberCard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afficher la carte de membre de ", _interpolate(_named("releaseMember"))])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "sport": {
    "aerobic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aérobic"])},
    "akrobatikturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique acrobatique"])},
    "fachteste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de Branche"])},
    "faustball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balle au poing"])},
    "fit-fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fit+Fun"])},
    "fitness_gesundheitssport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness/Sport santé"])},
    "geraeteturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique aux agrès"])},
    "gymnastik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique et danse"])},
    "handball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handball"])},
    "indiaca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiaca"])},
    "kinderturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique enfantine"])},
    "korbball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balle à la corbeille "])},
    "kunstturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique artistique"])},
    "leichtathletik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athlétisme"])},
    "nationalturnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux nationaux"])},
    "netzball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzball"])},
    "parkour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkour"])},
    "rhoenrad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhönrad"])},
    "rhythmischeGymnastik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique rythmique"])},
    "ropeskipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rope skipping"])},
    "schnurball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnurball"])},
    "sportakrobatik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport acrobatique"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes branches sportives"])},
    "trampolin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trampoline"])},
    "turnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gymnastique"])},
    "unihockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unihockey"])},
    "volley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball"])}
  },
  "squad": {
    "createNewSquad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Neue Riege erstellen"])},
    "deleteSquad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege löschen"])},
    "editSquad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Riege bearbeiten"])}
  },
  "table": {
    "filter": {
      "header": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("section.clubMembers.filter", undefined, _type)])}
    }
  },
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "user": {
    "forgotPassword": {
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour vers le login"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur est survenue lors de la demande. Vérifiez vos données et réessayiez plus tard."])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié "])},
      "forgot_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Saisissez votre numéro de membre myFSG ou l'adresse électronique associée à votre compte. Nous vous enverrons un lien pour créer un nouveau mot de passe."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le lien "])},
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le lien demandé pour réinitialiser le mot de passe a été envoyé avec succès. Si une adresse e-mail existe pour ", _interpolate(_named("memberNumber")), ", le lien sera envoyé à cette adresse. Prüfe auch deinen Spam-Ordner."])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers le login "])}
    },
    "onboarding": {
      "createAccount": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vos données ont pu être attribuées à un compte de membre, vous avez reçu un message de notre part pour activer votre compte. Veuillez également vérifier votre dossier de spam.\nSi vous n’avez pas reçu de message, veuillez contacter votre société."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre numéro de membre FSG ou l'adresse e-mail que votre société de gymnastique a enregistrée dans la base de données des membres FSG. Si vos données répondent aux conditions d'accès à myFSG, nous vous enverrons ensuite un lien pour créer un mot de passe."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer votre compte myFSG"])}
      },
      "enterPassword": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.newPassword", undefined, _type)])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez définir ici votre mot de passe pour votre accès à myFSG."])},
      "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau chez myFSG?"])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.passwordConfirm", undefined, _type)])},
      "privacyCheck": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["J'ai lu et compris la ", _interpolate(_list(0)), "."])},
      "privacyLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déclaration de confidentialité"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le compte"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le lien"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été créé avec succès."])},
      "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
      "termsAndConditionsCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et accepté les conditions d'utilisation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le lien indiqué n’est plus valable. Vous pouvez demander ", _interpolate(_list(0)), " un nouveau lien d'activation."])}
    },
    "resetPassword": {
      "newPassword": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.newPassword", undefined, _type)])},
      "passwordConfirm": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("profile.password.passwordConfirm", undefined, _type)])},
      "reset_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant définir un nouveau mot de passe."])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer nouveau mot de passe "])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été réinitialisé avec succès."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe "])},
      "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien indiqué n’est plus valable. Veuillez vérifier la ligne d’adresse du navigateur ou demander à nouveau le lien pour réinitialiser le mot de passe."])}
    }
  },
  "validation": {
    "emailAlreadyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail Adresse wird bereits verwendet. Bitte gib eine neue, gültige E-Mail Adresse ein."])},
    "emailNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail Adresse kann nur einmal verwendet werden."])},
    "emptyOrExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_", _interpolate(_named("field")), " : Eingabe erforderlich (max. ", _interpolate(_named("maxlength")), " Zeichen)"])},
    "nameExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fr_", _interpolate(_named("field")), ": Eingabe zu lang; max. ", _interpolate(_named("maxlength")), " Zeichen"])}
  },
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr_Warnung"])},
  "wizard": {
    "button": {
      "postpone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repousser"])},
      "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
      "saveSports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les disciplines sportives"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
}