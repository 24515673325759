<template>
  <div class="row mb-3">
    <div class="col">
      <DataTable :value="memberships"
                 scrollable scroll-height="60vh"
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 :loading="isLoadingFunctions"
                 ref="membershipDt"
                 paginator :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 csv-separator=";" :rowHover="true" :alwaysShowPaginator="false"
                 :exportFilename="$t('section.membership.membership')">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.member.membershipsNotFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column>
          <template #body="{data}">
            <div class="btn-group btn-group-sm pe-2">
              <Button :title="$t('createMembership.edit')" :aria-label="$t('createMembership.edit')"
                      :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm"
                      :hidden="membershipIsReadonly(data)"
                      unstyled class="btn btn-outline-dark" @click="showAndEditMembership(data)">
                <i class="bi bi-pencil"></i>
              </Button>
              <Button :title="$t('createMembership.remove')" :aria-label="$t('createMembership.remove')"
                      :hidden="membershipIsReadonly(data)"
                      unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data)">
                <i class="bi bi-trash"></i>
              </Button>
            </div>
          </template>
        </Column>
        <Column v-for="col in columns" :key="col.field" :field="col.field"
                :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header="col.header"
                sortable :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                :exportable="col.exportable"
                :hidden="!col.visible">
          <template #body="{data}">
            {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
          </template>
          <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <div class="row w-100 flex-center mb-3 mt-3">
    <Button :title="$t('export')" :label="$t('export')" @click="exportFunctionsCSV($event)" class="btn btn-outline-primary datatable-export me-1 width-auto" unstyled>
      <i class="bi bi-filetype-csv"></i>
    </Button>
    <Button :title="$t('createMembership.create')" @click="addNewMembership" :label="$t('createMembership.create')" :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm me-2" unstyled class="btn btn-outline-dark width-auto" icon="bi bi-plus-circle me-2"/>
  </div>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
  <Dialog v-focustrap v-model:visible="visibleEditMembership" modal block-scroll
          :header="membershipDataSet.lineNumber? $t('createMembership.edit') : $t('createMembership.create')"
          :style="{width: '30rem'}" @hide="resetMembership">
    <div class="form-row mb-3">
      <label for="all_categories" class="form-label">{{ $t('createMembership.choose_category') }}</label>
      <Select input-id="all_categories" v-model="membershipDataSet.category.code"
              v-if="!membershipDataSet.lineNumber"
              :options="allowedMembershipCategories"
              option-value="Code"
              :option-label="$i18n.locale === 'fr' ? 'DescriptionFR' : 'Description'"
              filter
              :placeholder="$t('createMembership.choose_category')"
              class="w-100"
              autofocus/>
      <div v-if="membershipDataSet.lineNumber">
        <Select disabled :placeholder="$i18n.locale === 'fr' ? membershipDataSet.category.descriptionFr : membershipDataSet.category.description" class="w-100"></Select>
      </div>
    </div>
    <div class="form-row mb-3" :hidden="props.associationMode">
      <label for="all_squads" class="form-label">{{ $t('createMembership.choose_squads') }}</label>
      <Select input-id="all_squads" v-model="membershipDataSet.squad.id"
              :options="squads"
              option-value="No"
              :option-label="$i18n.locale === 'fr' ? 'DescriptionFR' : 'Description'"
              filter
              showClear
              :placeholder="$t('createMembership.choose_squads')"
              class="w-100"
              autofocus/>
    </div>
    <div class="form-row mb-3">
      <label for="all_additions" class="form-label">{{ $t('createMembership.choose_additional') }}</label>
      <Select input-id="all_additions" v-model="membershipDataSet.additional.code"
              :options="additions"
              option-value="No"
              :option-label="$i18n.locale === 'fr' ? 'DescriptionFR' : 'Description'"
              filter
              showClear
              :placeholder="$t('createMembership.choose_additional')"
              class="w-100"
              autofocus/>
    </div>
    <div class="form-row mb-3">
      <label for="entryDate" class="form-label">{{ t('createMembership.entryDate') }}</label>
      <DatePicker input_id="entryDate" v-model="membershipDataSet.entryDate" :showButtonBar="true" class="w-100"/>
    </div>
    <div class="d-flex justify-content-between">
      <Button :title="$t('createMembership.save')" :label="$t('createMembership.save')"
              unstyled class="btn btn-outline-primary"
              :disabled="!membershipDataSet?.category?.code"
              @keyup.enter="submitMembershipData"
              @click="submitMembershipData"/>
      <div class="d-flex justify-content-end">
        <Button :title="$t('cancel')" :label="$t('cancel')" unstyled @click="visibleEditMembership = false" class="btn btn-outline-secondary"/>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import {computed, ref} from "vue";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import {useMemberShipStore} from "@/store/modules/membership/membership";
import {useMemberStore} from "@/store/modules/member/member";
import {useI18n} from "vue-i18n";
import {useConfirm} from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import Select from "primevue/select";
import DataTable from "primevue/datatable";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import {categoryArray} from "@/helpers/compatibleMembershipCategories";
import DatePicker from "primevue/datepicker";
import {getDateCorrectedForTimezoneOffsetAsIsoString} from "@/helpers/functions";
import moment from "moment";
import {useClubStore} from "@/store/modules/club/club";

const props = defineProps({
  associationMode: {
    default: false,
    type: Boolean,
    required: false
  }
});

const membershipStore = useMemberShipStore();
const memberStore = useMemberStore();
const messageStore = useMessagesStore();
const clubStore = useClubStore();
const confirm = useConfirm();
const {locale, t} = useI18n();
const membershipDt = ref();
const sortColumn = ref('category.description');
const visibleEditMembership = ref(false);
const isSubmitted = ref(false);
const membershipDataSet = ref({
    category: {},
    squad: {},
    additional: {},
    lineNumber: null,
    entryDate: null
  }
);
const clubNo = computed(() => clubStore.clubData.data.No);

const resetMembership = () => {
  membershipDataSet.value = {
    category: {},
    squad: {},
    additional: {},
    lineNumber: null,
    entryDate: null
  }
};

const isModalLoading = ref(false);

const columns = [
  {field: 'verein.description', header: t('section.function.organisation'), filterable: true, exportable: props.associationMode, visible: props.associationMode, type: 'String'},
  {field: 'category.description', header: t('club.listMember.membershipCategory'), filterable: true, exportable: locale.value === 'de', visible: locale.value === 'de', type: 'String'},
  {field: 'category.descriptionFr', header: t('club.listMember.membershipCategory'), filterable: true, exportable: locale.value === 'fr', visible: locale.value === 'fr', type: 'String'},
  {field: 'squad.description', header: t('club.listMember.squads'), filterable: true, exportable: locale.value === 'de', visible: locale.value === 'de', type: 'String'},
  {field: 'squad.descriptionFr', header: t('club.listMember.squads'), filterable: true, exportable: locale.value === 'fr', visible: locale.value === 'fr', type: 'String'},
  {field: 'addition.description', header: t('club.listMember.membershipCategoryAddition'), filterable: true, exportable: locale.value === 'de', visible: locale.value === 'de', type: 'String'},
  {field: 'addition.descriptionFr', header: t('club.listMember.membershipCategoryAddition'), filterable: true, exportable: locale.value === 'fr', visible: locale.value === 'fr', type: 'String'},
  {field: 'entryDate', header: t('section.membership.entryDate'), filterable: true, exportable: true, visible: true, type: 'Date'},
];

const isLoadingFunctions = computed(() => {
  return !(memberships.value);
});

const currentMemberData = computed(() => memberStore.getSingleMemberData);

const memberships = computed(() => {
  return membershipStore.getMembershipData;
});

const categories = computed(() => {
  return membershipStore.getCategories;
});

const squads = computed(() => {
  if(props.associationMode) {
      return [];
    }if (locale.value === "de") {
    return membershipStore.getSquadsByClub.toSorted((a, b) => a.Description.toLowerCase() > b.Description.toLowerCase());
  } else {
    return membershipStore.getSquadsByClub.toSorted((a, b) => a.DescriptionFR.toLowerCase() > b.DescriptionFR.toLowerCase());
  }
});

const additions = computed(() => {
  return membershipStore.getAdditions.toSorted((a, b) => a.No > b.No);
});

const getDescendantProp = (obj, key) => {
  const split = key.split('.').reduce((a, b) => (a && a[b] !== undefined ? a[b] : null), obj);
  if (key.startsWith('category.description')) {
    return split + ' (' + obj.category.code + ')';
  }
  return split;
};

const formatEntry = (type, value) => {
  switch (type) {
    case 'String':
      return value;
    case 'Date':
      return formatDate(value);
    default:
      return value;
  }
};

function formatDate(value) {
  const dateVal = moment(String(value));
  if (dateVal && dateVal.isAfter('1900-01-01')) {
    return dateVal.format('DD.MM.YYYY');
  }
  return '-';
}

const allowedMembershipCategories = computed(() => {
  let categoryInfoArray = [ ...categoryArray ]; // Kopiert das Array zur Sicherheit
  const membershipCategoryCodes = memberships.value.map((m) => m.category.code)

    categoryInfoArray = categoryInfoArray

      .filter((category) => category.isGenderAllowed(currentMemberData.value.stvMember.sex))
      .filter((category) => category.isBirthdayAllowed(currentMemberData.value.stvMember.birthday));for(let membership of membershipCategoryCodes) {
      categoryInfoArray = categoryInfoArray
        .filter((category) => category.isCategoryCompatible(membership));
  }
  const optionArray = [];
  for (let categoryInfo of categoryInfoArray) {
    optionArray.push(categories.value.find((c) => c.Code === categoryInfo.category));
  }
  return optionArray;
})

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const exportFunctionsCSV = () => {
  membershipDt.value.exportCSV({selectionOnly: false})
};

const membershipIsReadonly = (membership) => {
  if(!props.associationMode) {
    return false;
  }
  return membership?.verein?.code !== clubNo.value;
}

const emit = defineEmits([ 'startLoader', 'stopLoader' ]);

const submitMembershipData = () => {
  const membershipData = membershipDataSet.value;
  if (membershipData.lineNumber) { //bestehender Eintrag der editiert wird
    const data = {
      Subscription_Group: membershipData.category.code,
      Additive: !membershipData.additional ? '' : membershipData.additional.code,
      Type_of_Sports: !membershipData.squad ? '' : membershipData.squad.id,
      mySTVSection: !membershipData.section ? clubNo.value : membershipData.section,
      No: membershipStore.getContactId,
      Line_No: membershipData.lineNumber,
      Entry_date: !membershipData.entryDate ? '' : getDateCorrectedForTimezoneOffsetAsIsoString(membershipData.entryDate)
    }
    emit("startLoader");
    membershipStore.updateMembershipData(data).then(r => {
      if (r.status >= 200 && r.status < 300) {
        isSubmitted.value = true;
        messageStore.addInfo(new Message('info', true, false, t('section.membership.editSuccessHeader'), '', t('section.membership.editSuccess'), false, 'COMPONENT'));
      } else {
        messageStore.addError(new Message('error', true, false, t('section.membership.editErrorHeader'), '', t(r.data), false, 'COMPONENT'));
      }
    }).catch(e => {
      messageStore.addError(new Message('error', true, false, t('section.membership.editErrorHeader'), '', e, false, 'COMPONENT'));
    }).finally(async () => {
      if (props.associationMode) {
      await membershipStore.loadMembershipDataForAllClubs(membershipStore.getContactId);
      } else {
        await membershipStore.loadMembershipData(clubNo.value, membershipStore.getContactId);
      }
      emit("stopLoader");
    });
  } else { //neue Mitgliedschaft
    const data = {
      Subscription_Group: membershipData.category.code,
      Additive: !membershipData.additional ? '' : membershipData.additional.code,
      Type_of_Sports: !membershipData.squad ? '' : membershipData.squad.id,
      mySTVSection: clubNo.value,
      No: membershipStore.getContactId,
      Entry_date: !membershipData.entryDate ? '' : getDateCorrectedForTimezoneOffsetAsIsoString(membershipData.entryDate)
    }
    emit("startLoader")
    membershipStore.saveNewMembershipData(data).then(r => {
      if (r.status >= 200 && r.status < 300) {
        isSubmitted.value = true;
        messageStore.addInfo(new Message('info', true, false, t('section.membership.createSuccessHeader'), '', t('section.membership.createSuccess'), false, 'COMPONENT'));
      } else {
        messageStore.addError(new Message('error', true, false, t('section.membership.createErrorHeader'), '', t(r.data), false, 'COMPONENT'));
      }
    }).catch(e => {
      messageStore.addError(new Message('error', true, false, t('section.membership.createErrorHeader'), '', e, false, 'COMPONENT'));
    }).finally(async () => {
      if (props.associationMode) {
      await membershipStore.loadMembershipDataForAllClubs(membershipStore.getContactId);
      } else {
        await membershipStore.loadMembershipData(clubNo.value, membershipStore.getContactId);
      }
      emit("stopLoader");
    });

  }
  isSubmitted.value = true;
  visibleEditMembership.value = false;
}

const showAndEditMembership = async (data) => {
  isModalLoading.value = true;
  membershipDataSet.value = {
    category: data.category,
    section: data.verein.code,squad: data.squad,
    additional: data.addition,
    lineNumber: data.lineNumber,
    entryDate: data.entryDate ? new Date(data.entryDate) : null
  }
  if (data.squad === null || data.squad === undefined) {
    membershipDataSet.value.squad = {};
  }
  if (data.addition === null || data.addition === undefined || data.addition.code === "") {
    membershipDataSet.value.additional = {};
  }
  if (data.category === null || data.category === undefined) {
    membershipDataSet.value.category = {};
  }
  visibleEditMembership.value = true;
  isModalLoading.value = false;
};

const addNewMembership = async () => {
  resetMembership();
  visibleEditMembership.value = true;
};

const confirmDelete = (event, membershipData) => {
  confirm.require({
    target: event.currentTarget,
    header: t('section.membership.confirmDeleteHeader'),
    message: t('section.membership.confirmDelete'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      deleteMembership(membershipData);
    },
  });
};

const deleteMembership = async (membershipData) => {
  emit("startLoader");const memberId = membershipStore.getContactId;
  const lineNumber = membershipData.lineNumber;
  membershipStore.deleteMembership(memberId, lineNumber).then(r => {
    if (r.status >= 200 && r.status < 300) {
      messageStore.addInfo(new Message('info', true, false, t('section.membership.deleteSuccessHeader'), '', t('section.membership.deleteSuccess'), false, 'COMPONENT'));
    } else if (r.data) {
      messageStore.addError(new Message('error', true, false, t('section.membership.deleteErrorHeader'), '', r.data, false, 'COMPONENT'));
    } else {
      messageStore.addError(new Message('error', true, false, t('section.membership.deleteErrorHeader'), '', t('section.membership.deleteError'), false, 'COMPONENT'));
    }
  }).catch(e => {
    messageStore.addError(new Message('error', true, false, t('section.membership.deleteErrorHeader'), '', e, false, 'COMPONENT'));
  }).finally(async () => {
    if (props.associationMode) {
      await membershipStore.loadMembershipDataForAllClubs(membershipStore.getContactId);
    } else {
      await membershipStore.loadMembershipData(clubNo.value, membershipStore.getContactId);}emit("stopLoader");
  });
}
</script>

