import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const ASSOCIATION_BASE_PATH = "/api/association";

export const useAssociationStore = defineStore("association", {
  state: () => {
    return {
      clubData: null,
      allClubs: null,
      clubMembers: null,
    };
  },

  actions: {
    async loadAllClubs(associationId) {
      const response = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-clubs?associationId=' + associationId);
      if (response?.status < 500 && response?.data) {
        this.allClubs = response.data;
      } else {
        this.allClubs = null;
      }
    },

    async loadClubListMembers(associationId, clubId, overwrite, activeMembersOnly, clubMembershipsOnly, language) {
      this.clubMembers = [];
      const payload = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-club-members?associationId=' + associationId + '&clubId=' + clubId +
        "&overwrite=" + overwrite +
        "&activeMembersOnly=" + activeMembersOnly +
        "&clubMembershipsOnly=" + clubMembershipsOnly +
        "&language=" + language);

      if (payload?.data) {
        this.clubMembers = payload.data.filter((m, i, s) => i === s.findIndex(m2 => (m2.memberId === m.memberId)));
      } else {
        this.clubMembers = [];
      }
    },

    async loadClubData(associationId, clubId) {
      const clubData = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/clubById?associationId=' + associationId + '&clubId=' + clubId);
      if (clubData?.status < 500) {
        this.clubData = clubData;
      } else {
        this.clubData = null;
      }
    },

    setCurrentClubId(clubId) {
      this.clubData.currentClubId = clubId;
    },
    setCurrentClubName(clubName) {
      this.clubData.currentClubName = clubName;
    },
  },

  getters: {
    getAllClubs: (state) => {
      if (state.allClubs) {
        return state.allClubs;
      }
      return null;
    },

    getClubMembers: (state) => {
      if (state.clubMembers) {
        return state.clubMembers;
      }
      return [];
    },

    getCurrentClubData: (state) => {
      if (state.clubData) return state.clubData;
      return null;
    },
    getCurrentClubId: (state) => {
      return state.ClubData.currentClubId;
    },
    getCurrentClubName: (state) => {
      return state.clubData.currentClubName;
    },

  },

  persist: {
    storage: sessionStorage,
  }
});
