<template>
  <div class="alert alert-info">
    {{ $t('club.notFound.text') }}<br>
    {{ $t('notFound.checkLink') }}<br>

    <div class="mt-3">
      <router-link to="/" class="btn btn-outline-secondary">{{ $t('notFound.start') }}</router-link>
    </div>
  </div>
</template>
