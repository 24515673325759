<template>
<div>
  <DataTable :value="members"

             scrollable scroll-height="60vh"
             :sort-field="sortColumn" :sort-order="1" @sort="onSort"
             data-key="composedId"
             ref="dt"
             :loading="isLoading"
             v-model:filters="filters" filter-display="row"
             :paginator="members.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
             paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
             :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
             csv-separator=";" :export-function="csvExportTransformationFunction" :export-filename="$t('institutes.functions.allMembers')"
             row-hover
             class="border-bottom">

    <template #empty>
      <div class="alert alert-info">{{ $t('institutes.functions.noMembersFound') }}</div>
    </template>
    <template #paginatorfirstpagelinkicon>
      <i class="bi bi-arrow-bar-left"/>
    </template>
    <template #paginatorprevpagelinkicon>
      <i class="bi bi-arrow-left"/>
    </template>
    <template #paginatornextpagelinkicon>
      <i class="bi bi-arrow-right"/>
    </template>
    <template #paginatorlastpagelinkicon>
      <i class="bi bi-arrow-bar-right"/>
    </template>

    <Column v-for="col in columns" :key="col.field" :field="col.field"
            :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
            :header="col.header"
            :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
            :exportable="col.exportable"
            :hidden="!col.visible">
      <template #body="{data}">
        {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
      </template>
      <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
      </template>
    </Column>
  </DataTable>

  <div class="row w-100 flex-center gap-2 mb-3 mt-3">
    <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>
</div>
</template>

<script setup>
import {FilterMatchMode} from "@primevue/core/api";
import {onBeforeMount, ref, watch} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n";
import ApiClient from "@/api/ApiClient";
import Button from "primevue/button";

const {t} = useI18n();

const props = defineProps({
  clubId: String,
  institutes: Array}
);

const isLoading = ref(true);
const members = ref([]);

async function loadMembers() {
  const request = "/api/institutes/allAddressesInClub?clubNo=" + props.clubId;

  const response = await ApiClient.getRequest(request);

  if(response && response.status === 200) {
    members.value = addInstituteNameToMembers(response.data);
    isLoading.value = false;
  }
}

const sortColumn = ref('surname');
const columns = [
  {field: 'instituteName', header: t('institutes.list.instituteName'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'instituteNo', header: t('institutes.functions.instituteNo'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'functionDescription', header: t('institutes.functions.functionDescription'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'step', header: t('institutes.functions.step'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'memberId', header: t('institutes.functions.memberId'), filterable: false, exportable: true, visible: true, sortable: false, type: 'String'},
  {field: 'languageCode', header: t('club.listMember.language'), filterable: false, exportable: true, visible: true, sortable: false, type: 'Language'},
  {field: 'salutation', header: t('club.listMember.salutation'), filterable: false, exportable: true, visible: true, sortable: false, type: 'Salutation'},
  {field: 'surname', header: t('personalData.person.surname'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'firstName', header: t('personalData.person.firstName'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'birthday', header: t('personalData.person.birthday'), filterable: true, exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'street', header: t('club.listMember.address'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'zipCode', header: t('club.listMember.zipCode'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'city', header: t('club.listMember.place'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'country', header: t('club.member.country'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'email', header: t('club.member.emailPrivate'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'emailClub', header: t('club.member.emailVerein'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'phone', header: t('club.listMember.phonePrivate'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'},
  {field: 'phoneMobile', header: t('club.listMember.phoneMobile'), filterable: false, exportable: true, visible: false, sortable: false, type: 'String'}
];

const filters = ref({
  instituteName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  instituteNo: {value: null, matchMode: FilterMatchMode.CONTAINS},
  functionDescription: {value: null, matchMode: FilterMatchMode.CONTAINS},
  surname: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  birthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  step: {value: null, matchMode: FilterMatchMode.CONTAINS},
})

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => (a && a[b] !== undefined ? a[b] : null), obj);
};

const dt = ref();
const exportCSV = () => {
  dt.value.exportCSV();
};

function getInstituteNameBasedOnCode(instituteCode) {
  if(props.institutes) {
    const matchingInstitute = props.institutes.find(institute => institute.Code === instituteCode);
    if(matchingInstitute) {
      return matchingInstitute.Description;
    }
  }
  return "";
}

function addInstituteNameToMembers(memberList) {
  if(props.institutes) {
    memberList.forEach(item => {
      item.instituteName = getInstituteNameBasedOnCode(item.instituteCode);
    })
  }

  return memberList;
}

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'birthday':
    case 'startDate':
    case 'endDate':
      return formatEntry('Date', record.data);
    case 'languageCode':
      return formatEntry('Language', record.data);
    case 'salutation':
      return formatEntry('Salutation', record.data);
    default:
      return String(record.data)
  }
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      if(value === "M") {
        return t('club.listMember.salutationMR')
      }
      return t('club.listMember.salutationMS')
    case 'Language':
      return t('languages.' + value.toLocaleLowerCase());
    default:
      return String(value);
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const onSort = (event) => {
  sortColumn.value = event.sortField;
};


watch(props, () => {
  loadMembers();
})

onBeforeMount(() => {
  isLoading.value = true;
  loadMembers();
})

</script>
