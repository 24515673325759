<template>
  <association-layout :title="$t('club.member.profile', {stvNr: currentMemberData?.memberId, firstName: currentMemberData?.stvMember?.firstName, surname: currentMemberData?.stvMember?.surname})" :isLoading="isLoading">
    <template #content v-if="clubData.status === 200 && currentMemberData?.stvMember">
      <div class="row">
        <div class="col">
          <Tabs value="profile" scrollable>
            <TabList>
              <Tab value="profile">{{ $t('club.member.titles.profile') }}</Tab>
              <Tab value="honors">{{ $t('club.member.titles.honors') }}</Tab>
              <Tab value="functions">{{ $t('club.member.titles.functions') }}</Tab>
              <Tab value="activities">{{ $t('club.member.titles.activities') }}</Tab>
              <Tab value="membership">{{ $t('club.member.titles.memberships') }}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel value="profile">
                <EditMemberTab :association-mode="true" @loading="loading" @loadingDone="loadingDone"></EditMemberTab>
              </TabPanel>
              <TabPanel value="honors">
                <ShowHonorsTab :association-mode="true"></ShowHonorsTab>
              </TabPanel>
              <TabPanel value="functions">
                <ShowFunctionsTab :association-mode="true"></ShowFunctionsTab>
              </TabPanel>
              <TabPanel value="activities">
                <ShowActionsTab :association-mode="true"></ShowActionsTab>
              </TabPanel>
              <TabPanel value="membership">
                <ShowAndEditMembershipTab @start-loader="isLoading = true" @stop-loader="isLoading = false" :association-mode="true"></ShowAndEditMembershipTab>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>

    </template>
    <template #content v-else>
      <NoMemberFoundAlert/>
    </template>
    <BootstrapToast/>
  </association-layout>

</template>

<script setup>
import {computed, ref} from 'vue'
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import EditMemberTab from "@/views/club/tabs/EditMemberTab.vue";
import ShowFunctionsTab from '@/views/club/tabs/ShowFunctionsTab.vue';
import ShowActionsTab from '@/views/club/tabs/ShowActionsTab.vue'
import ShowHonorsTab from '@/views/club/tabs/ShowHonorsTab.vue'
import ShowAndEditMembershipTab from "@/views/club/tabs/ShowAndEditMembershipTab.vue";

import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';

import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import NoMemberFoundAlert from "@/components/ui/alert/NoMemberFoundAlert.vue";

const memberStore = useMemberStore();
const clubStore = useClubStore();
const currentMemberData = computed(() => memberStore.getSingleMemberData);
const isLoading = ref(false);

const clubData = computed(() => {
  return clubStore.getClubData;
});

const loading = () => {
  isLoading.value = true;
}

const loadingDone = () => {
  isLoading.value = false;
}

</script>

<style scoped>

.p-inputmask {
  width: 100%;
  margin-bottom: .25rem;
}
</style>
