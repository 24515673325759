import {createApp} from "vue";
import App from "@/App.vue";

import BackNextButton from "@/components/ui/BackNextButton.vue";
import moment from "moment/moment";
import ConfirmationService from "primevue/confirmationservice";

const app = createApp(App);

app.use(ConfirmationService);

/* eslint-disable */
app.component("BackNextButton", BackNextButton);
/* eslint-enable */

app.mixin({
  methods: {
    formatDate: (value, format) => {
      if (value) {
        const dateValue = moment(String(value));
        if (dateValue && dateValue.isAfter('1900-01-01')) {
          return dateValue.format(format || 'DD.MM.YYYY');
        }
      }
      return '–';
    },
    dateValid: (value) => {
      const dateValue = moment(String(value));
      return dateValue.isValid();
    },
    replaceUmlauts: (value) => {
      const mapObj = {
        ' ': '_', 'ä': 'ae', 'ö': 'oe', 'ü': 'ue', 'Ä': 'Ae', 'Ö': 'Oe', 'Ü': 'Ue',
        'é': 'e', 'è': 'e', 'ë': 'e', 'à': 'a', 'á': 'a', 'â': 'a', 'ç': 'c',
        'É': 'E', 'È': 'E', 'Ë': 'E', 'À': 'A', 'Ç': 'C',
        'ß': 'ss', '’': '', '\'': '',
      }
      const regex = new RegExp(Object.keys(mapObj).join('|'), 'g');
      return value.replace(regex, function (matched) {
        return mapObj[matched];
      });
    },
    sortMethods: (type, column, direction) => {
      switch (type) {
        case 'Number':
          return direction === 1 ?
            (a, b) => Number(b[column]) - Number(a[column]) :
            (a, b) => Number(a[column]) - Number(b[column]);
        case 'Boolean':
          return direction === 1 ?
            (a, b) => (a === b) ? 0 : a ? -1 : 1 :
            (a, b) => (a === b) ? 0 : a ? 1 : -1;
        case 'String':
          return direction === 1 ?
            (a, b) => a[column] === null ? 1 : b[column] === null ? -1 : a[column].localeCompare(b[column]) :
            (a, b) => a[column] === null ? 1 : b[column] === null ? -1 : b[column].localeCompare(a[column]);
        default:
          return direction === 1 ?
            (a, b) => a[column] === null ? 1 : b[column] === null ? -1 : b[column] > a[column] ? -1 : a[column] > b[column] ? 1 : 0 :
            (a, b) => a[column] === null ? 1 : b[column] === null ? -1 : a[column] > b[column] ? -1 : b[column] > a[column] ? 1 : 0;
      }
    },
  }
});

export default app;
