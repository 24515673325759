<template>
  <membership-table :title="$t('section.education.brevets')"
                    :is-empty="sortedBrevets.length === 0"
                    :empty-message="$t('section.education.noBrevetEntries')" table-name="education">
    <template #membership-table>
      <table class="table">
        <thead>
        <tr>
          <th>{{ $t('section.education.education') }}</th>
          <th>{{ $t('section.education.validity') }}</th>
          <th>{{ $t('section.education.sport') }}</th>
          <th>{{ $t('section.education.level') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="edu in sortedBrevets" :key="edu.id">
          <td>{{ edu.curse }}</td>
          <td>{{ formatDate(edu.brevetValidUntil, 'DD.MM.YYYY') }}</td>
          <td>
            <svg width="25" height="25" v-if="edu.type">
              <use :xlink:href="require('@/assets/img/sport-icons.svg') + '#' + getSquadByKey(edu.type)"/>
            </svg>
          </td>
          <td>{{ edu.level ? edu.level : edu.type }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </membership-table>
  <hr>
  <membership-table :title="$t('section.education.title')"
                    :is-empty="sortedEvents.length === 0"
                    :empty-message="$t('section.education.noEntries')"
                    table-name="eventlines"
                    :show-info="true"
                    :info-text="$t('section.membership.info')">
    <template #membership-table>
      <table class="table">
        <thead>
        <tr>
          <th>{{ $t('section.education.education') }}</th>
          <th>{{ $t('section.education.description') }}</th>
          <th>{{ $t('section.education.startDate') }}</th>
          <th>{{ $t('section.education.endDate') }}</th>
          <th>{{ $t('section.education.status') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event, index) in sortedEvents" :key="index">
          <td>{{ event.description }}</td>
          <td>{{ event.secondDescription }}</td>
          <td>{{ formatDate(event.startDate) }}</td>
          <td>{{ formatDate(event.endDate) }}</td>
          <td>{{ event.status }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </membership-table>
</template>

<script>
import MembershipTable from "@/components/personal-data/MembershipTable";
import {getSquadByKey} from "@/helpers/functions";

export default {
  props: {
    memberData: {
      type: Object,
      required: true,
    }
  },
  components: {
    MembershipTable
  },
  computed: {
    sortedBrevets() {
      // eslint-disable-next-line
      return (this.memberData.educations && this.memberData.educations.length > 0) ? this.memberData.educations.sort(this.sortMethods('Date', 'brevetValidUntil', -1)) : [];
    },
    sortedEvents() {
      // eslint-disable-next-line
      return (this.memberData.eventLines && this.memberData.eventLines.length > 0) ? this.memberData.eventLines.sort(this.sortMethods('Date', 'endDate', -1)) : [];
    },
  },
  methods: {
    getSquadByKey
  },
}
</script>
