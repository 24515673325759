import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const CONTACT_BANK_ACCOUNT_BASE_PATH = "/api/contact/bankaccount";
const CLUB_INTERFACE_BASE_PATH = "/api/club/interface";

export const useContactStore = defineStore("contact", {
  state: () => {
    return {
      bankAccountData: null,
      useCache: true,
      clubInterfaceProviders: null,
      clubInterface: null,
    };
  },

  actions: {

    async loadContactBankAccount(contactNo, useCache, overwrite) {
      const bankAccount = await ApiClient.getRequest(CONTACT_BANK_ACCOUNT_BASE_PATH + '/byid?contactNo=' + contactNo + '&useCache=' + useCache + '&overwrite=' + overwrite);
      if (bankAccount?.data) {
        this.bankAccountData = bankAccount.data;
      } else {
        this.bankAccountData = {
          Contact_No: contactNo,
          Name: null,
          Post_Code: null,
          City: null,
          Country_Region_Code: null,
          IBAN: null

        }
      }
      this.useCache = useCache;
    },

    async saveOrUpdateContactBankAccount(payload) {

      if (payload?.new) {
        return await ApiClient.postRequestWithAuthorization(CONTACT_BANK_ACCOUNT_BASE_PATH + "/add", payload);
      } else if (payload?.Contact_No) {
        return await ApiClient.putRequest(CONTACT_BANK_ACCOUNT_BASE_PATH + "/update", payload);
      }

      return null
    },

    async deleteContactBankAccount(contactNo) {
      console.log(contactNo);
      let response = null;
      if (contactNo) {
        response = await ApiClient.postRequestWithAuthorization(CONTACT_BANK_ACCOUNT_BASE_PATH + "delete", contactNo);
      } else {
        // Handle error
      }

      if (response?.statusCode) {
        console.log(response);
      }
    },

    async loadClubInterfaceProviders() {
      const interfaceProviders = await ApiClient.getRequest(CLUB_INTERFACE_BASE_PATH + '/provider');
      if (interfaceProviders?.data) {
        this.clubInterfaceProviders = interfaceProviders.data;
      } else {
        this.clubInterfaceProviders = [];
      }
    },

    async loadClubInterface(clubNo) {
      const contactClubInterface = await ApiClient.getRequest(CLUB_INTERFACE_BASE_PATH + '/byid?clubNo=' + clubNo);
      if (contactClubInterface?.data) {
        this.clubInterface = contactClubInterface.data;
      }
    },

    async saveClubInterface(payload) {
      if (payload?.Contact_No) {
        return await ApiClient.putRequest(CLUB_INTERFACE_BASE_PATH + '/update', payload);
      }
      return null;
    },
  },
  getters: {
    getPaymentData: (state) => {
      if (state.bankAccountData) {
        return state.bankAccountData;
      }
      return null
    },
    getCountries: (state) => {
      return state.countries
    },
    getCountry: (state) => {
      return state.country
    },

    getClubInterfaceProviders: (state) => {
      if (state.clubInterfaceProviders) {
        return state.clubInterfaceProviders;
      }
      return [];
    },
    getClubInterface: (state) => {
      if (state.clubInterface) {
        return state.clubInterface;
      }
      return null;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
