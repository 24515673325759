import {defineStore} from "pinia";
import ApiClient from "@/api/ApiClient";

const PROFILE_PHOTO_BASE_PATH = '/api/profile';


export const useProfileStore = defineStore("profile", {
  state: () => {
    return {
        profileImage: null,
    };
  },

  actions: {
    async savePhoto(payload) {
      const response = await ApiClient.postRequestWithAuthorization(PROFILE_PHOTO_BASE_PATH + '/photo', payload);
      if (response) {
        return response;
      }
      return null;
    },
    async savePhotoForReleaseMember(payload) {
      const response = await ApiClient.postRequestWithoutAuthorization(PROFILE_PHOTO_BASE_PATH + '/photo?releaseMemberId=' + payload.releaseMemberId, payload.image);
      if (response) {
          return response;
      }
      return null;
    },
    async getPhotoAsBase64(payload) {
      const response = await ApiClient.getRequest(PROFILE_PHOTO_BASE_PATH + '/photo', payload);
      if(response?.status === 200) {
        this.profileImage = response.data;
      }
    },
    async deletePhoto(payload) {
      const response = await ApiClient.deleteRequest(PROFILE_PHOTO_BASE_PATH + '/photo?memberId=' + payload);
      if (response) {
        //this.profileImage = null
        return response;
      }
      return null;
    },
    async clearPhotoData() {
      this.profileImage = null
    },
    async clearLogoData() {
      this.vereinLogo = null
    },

  },

  getters: {
    getProfileImage: (state) => {
      if (state.profileImage) {
        return state.profileImage;
      }
      return null;
    },
  }
});
