import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const CLUB_BASE_PATH = "/api/club";
const ASSOCIATION_BASE_PATH = "/api/association";
const CLUB_LOGO_BASE_PATH = '/api/profile';

export const useClubStore = defineStore("club", {
  state: () => {
    return {
      clubData: null,
      associationClubs: null,
      useCache: true,
      clubAdmin: null,
      clubLogo: null,
      currentClubId: null,
      addressRelations: [],
      functions: [],
    };
  },

  actions: {

    async loadClubData(clubNumber) {
      const clubData = await ApiClient.getRequest(CLUB_BASE_PATH + '/byid?clubNo=' + clubNumber);
      if (clubData && clubData.status < 400) {
        this.clubData = clubData;
      } else {
        this.clubData = null;
      }
    },

    async loadAssociationClubs(associationId){
      const response = await ApiClient.getRequest(CLUB_BASE_PATH + '/byAssociation?associationId=' + associationId);
      if(response?.status < 400 && response?.data) {
        this.associationClubs = response.data;
      } else {
        this.associationClubs = null;
      }
    },

    async savePhoto(payload) {
      const response = await ApiClient.postRequestWithAuthorization(CLUB_LOGO_BASE_PATH + '/verein_logo', payload);
      if (response) {
        return response;
      }
      return null;
    },
    async saveVereinLogo(payload, clubid) {
      const response = await ApiClient.postRequestWithAuthorization(CLUB_LOGO_BASE_PATH + '/verein_logo?clubId=' + clubid, payload);
      if (response) {
        return response;
      }
      return null;
    },
    async getVereinLogoAsBase64(clubid) {
      const response = await ApiClient.getRequest(CLUB_LOGO_BASE_PATH + '/verein_logo?clubId=' + clubid, null);
      this.clubLogo = response.data;
    },
    async deleteVereinLogo(clubid) {
      const response = await ApiClient.deleteRequest(CLUB_LOGO_BASE_PATH + '/verein_logo?clubId=' + clubid);
      if (response) {
        this.clubLogo = null;
        return response;
      }
      return null;
    },
    async saveClubBaseData(payload) {
      const response = await ApiClient.putRequest(CLUB_BASE_PATH + "/update/", payload);
      if (response) {
        return response;
      }
      return null;
    },

    async loadClubMembers(clubId, clubMembershipsOnly) {
      let payload = await ApiClient.getRequest(CLUB_BASE_PATH + '/members?clubId=' + clubId + "&clubMembershipsOnly=" + clubMembershipsOnly);

      if (payload?.status < 400 && payload?.data) {
        this.clubData.clubMembers = payload.data.filter((m, i, s) =>
          i === s.findIndex(m2 => (m2.memberId === m.memberId))
        );
      } else {
        this.clubData.clubMembers = [];
      }
    },
    async loadClubListAssociationMembers(clubId, useCache, activeMembersOnly,  language) {
      let payload = await ApiClient.getRequest(ASSOCIATION_BASE_PATH + '/list-members?associationId=' + clubId +
        "&activeMembersOnly=" + activeMembersOnly +
        "&language=" + language);

      if (payload?.status < 400 && payload?.data) {
        this.clubData.clubMembers = payload.data.filter((m, i, s) =>
          i === s.findIndex(m2 => (m2.memberId === m.memberId))
        );
      } else {
        this.clubData.clubMembers = [];
      }
    },
    async loadClubListMembers(clubId, useCache, activeMembersOnly, clubMembershipsOnly, language) {
      let payload = await ApiClient.getRequest(CLUB_BASE_PATH + '/list-members?clubId=' + clubId +
        "&useCache=" + useCache +
        "&activeMembersOnly=" + activeMembersOnly +
        "&clubMembershipsOnly=" + clubMembershipsOnly +
        "&language=" + language);

      if (payload?.status < 400 && payload?.data) {
        this.clubData.clubMembers = payload.data.filter((m, i, s) =>
          i === s.findIndex(m2 => (m2.memberId === m.memberId))
        );
      } else {
        this.clubData.clubMembers = [];
      }
    },

    async loadClubAddressRelations(clubId) {
      const response = await ApiClient.getRequest(CLUB_BASE_PATH + '/address-relations?clubNo=' + clubId);
      if (response?.status < 400 && response?.data) {
        this.addressRelations = response.data;
      } else {
        this.addressRelations = [];
      }
    },
    // AddressRelations (Funktionen und Tätigkeiten)
    async deleteAddressRelations(clubId, entryNo) {
      const response = await ApiClient.deleteRequest(CLUB_BASE_PATH + '/address-relations?clubNo=' + clubId + '&entryNo=' + entryNo);
      if (response) {
        return response;
      }
      return null;
    },
    async loadFunctions(clubId) {
      const response = await ApiClient.getRequest(CLUB_BASE_PATH + '/address-relations/functions?clubNo=' + clubId)
      if (response?.status < 400 && response) {
        this.functions = response.data;
      } else {
        this.functions = [];
      }
    },
    async saveAddressRelation(payload) {
      if (payload?.Entry_No) {
        return await ApiClient.putRequest(CLUB_BASE_PATH + '/address-relations', payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(CLUB_BASE_PATH + '/address-relations', payload);
      }
      return null;
    },

    async loadClubListSponsorsAndPartners(clubId, useCache, activeMembersOnly, clubMembershipsOnly, language) {
      let payload = await ApiClient.getRequest(CLUB_BASE_PATH + '/list-sponsors?clubId=' + clubId +
        "&useCache=" + useCache +
        "&activeMembersOnly=" + activeMembersOnly +
        "&language=" + language);

      if (payload?.status < 400 && payload?.data) {
        this.clubData.clubSponsorsAndPartners = payload.data.filter((m, i, s) =>
          i === s.findIndex(m2 => (m2.memberId === m.memberId))
        );
      } else {
        this.clubData.clubSponsorsAndPartners = [];
      }
    },

    setCurrentClubId(clubId) {
      this.clubData.currentClubId = clubId;
    },
    setCurrentClubName(clubName) {
      this.clubData.currentClubName = clubName;
    },
    setClubMemberFilter(memberFilter) {
      this.clubData.memberFilter = memberFilter;
    },
    setClubMemberSortKey(memberSortKey) {
      this.clubData.memberSortKey = memberSortKey;
    },
    setClubMemberSortOrder(memberSortOrder) {
      this.clubData.memberSortOrder = memberSortOrder;
    },
    updateMemberSelection(selectedMembers) {
      this.clubData.selectedMembers = selectedMembers;
    },
    async clearClubData() {
      this.clubData = {};
    },
    loadClubDataFromCache(useCache) {
      this.useCache = useCache;
    },
  },


  getters: {
    getClubData: (state) => {
      if (state.clubData) {
        return state.clubData;
      }
      return null
    },
    getAssociationClubs: (state) => {
      if(state.associationClubs) {
        return state.associationClubs;
      }
      return null;
    },
    getClubLogo: (state) => {
      if (state.clubLogo) {
        return state.clubLogo
      }
      return null
    },
    getCurrentClubId: (state) => {
      return state.clubData.currentClubId;
    },
    getCurrentClubNo: (state) => {
      return state.clubData.data.No;
    },
    getCurrentClubName: (state) => {
      return state.clubData.currentClubName;
    },
    getClubMembers: (state) => {
      if (state.clubData.clubMembers) {
        return state.clubData.clubMembers;
      }
      return [];
    },
    getClubAddressRelations: (state) => {
      if (state.addressRelations) {
        return state.addressRelations;
      }
      return null;
    },
    getClubSponsorsAndPartners: (state) => {
      if (state.clubData.clubSponsorsAndPartners) {
        return state.clubData.clubSponsorsAndPartners;
      }
      return [];
    },
    getFunctions: (state) => {
      if (state.functions) {
        return state.functions;
      }
      return [];
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
