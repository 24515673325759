import ApiClient from "@/api/ApiClient";
import {defineStore} from "pinia";

const HONORS_BASE_PATH = "/api/club/honors";

export const useHonorsStore = defineStore('honors', {
  state: () => {
    return {
      honors: [],
      memberHonors: [],
    };
  },

  actions: {
    // Ehrungen
    async loadClubHonors(clubId) {
      const response = await ApiClient.getRequest(HONORS_BASE_PATH + '?clubNo=' + clubId);
      if (response) {
        this.honors = response.data;
      } else {
        this.honors = [];
      }
    },
    async saveOrUpdateClubHonor(payload) {
      if (payload?.Code) {
        return await ApiClient.putRequest(HONORS_BASE_PATH, payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(HONORS_BASE_PATH, payload);
      }
      return null;
    },
    async deleteClubHonors(clubId, honorId) {
      const response = await ApiClient.deleteRequest(HONORS_BASE_PATH + '?clubNo=' + clubId + '&honorId=' + honorId);
      if (response) {
        return response;
      }
      return null;
    },
    async loadMemberHonorsByClub(clubId) {
      const response = await ApiClient.getRequest(HONORS_BASE_PATH + '/member-honors?clubNo=' + clubId);
      if (response) {
        this.memberHonors = response.data;
      } else {
        this.memberHonors = [];
      }
    },
    async saveOrUpdateMemberHonor(payload) {
      if (payload?.Line_No) {
        return await ApiClient.putRequest(HONORS_BASE_PATH + '/member-honors', payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(HONORS_BASE_PATH + '/member-honors', payload);
      }
      return null;
    },
    async deleteMemberHonors(clubId, contactNo, lineNo) {
      const response = await ApiClient.deleteRequest(HONORS_BASE_PATH + '/member-honors?clubNo=' + clubId + '&contactNo=' + contactNo + '&lineNo=' + lineNo);
      if (response) {
        return response;
      }
      return null;
    },
  },

  getters: {
    getHonors: (state) => {
      if (state.honors) {
        return state.honors;
      }
      return [];
    },
    getMemberHonors: (state) => {
      if (state.memberHonors) {
        return state.memberHonors;
      }
      return [];
    },
  },

  persist: {
    storage: sessionStorage,
  }
});
